import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import COMPRA_SEGURA from '~/assets/images/compra-segura.png';
import Header from '~/components/Header';
import CardPrice from '~/components/PersonalCoverage/CardPrice';
import SidebarMesages from '~/components/PersonalCoverage/SidebarMesages';
import ModalCoverage from '~/modals/ModalCoverage';
import ModalHelpClub from '~/modals/ModalHelpClub';
import ModalHelpFinancialCharges from '~/modals/ModalHelpFinancialCharges';
import useStore, { storeState } from '~/store';
import { Product, Quote } from '~/store/slices/quote/types';
import searchNameEquipment from '~/utils/searchNameEquipment';
import * as styles from './styles';
import { parseParamToString } from '~/utils/parseParamToString';

export default function HelpZipcodePage() {
  const [contractLoading, setContractLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const planParam = searchParams.get('plan');
  const distribuitorParam = searchParams.get('dis');
  const [defaultSelectedProducts, setDefaultSelectedProducts] = useState<Product[]>([]);

  const navigate = useNavigate();
  const {
    equipment,
    equipmentValue,
    quoteResponse,
    quotes,
    quoteAsyncUpdate,
    setTotalValue,
    totalValue,
    setServiceCharge,
    serviceCharge,
    setProducts,
    products
  } = useStore((store) => ({
    equipment: store.quote.equipment,
    equipmentValue: store.quote.equipmentValue,
    quoteResponse: store.quoteResponse,
    quotes: store.quotes,
    quoteAsyncUpdate: store.quoteAsyncUpdate,
    setTotalValue: store.setTotalValue,
    totalValue: store.totalValue,
    setServiceCharge: store.setServiceCharge,
    serviceCharge: store.serviceCharge,
    setProducts: store.setProducts,
    products: store.products,
    quote: store.quote
  }));

  const handleDefaultSelectedProducts = (product: Product) => {
    setDefaultSelectedProducts((prevState) => [...prevState, product]);
  };

  const fetchProductsData = async (products: Product[], onError?: () => void) => {
    setProductsLoading(true);
    try {
      const { data } = await quoteAsyncUpdate(quoteResponse.quote_id, products);
      setProductsLoading(false);
      setServiceCharge(data.financial_charges);
      setTotalValue(data.total);
    } catch (error) {
      setProductsLoading(false);
      if (onError) onError();
      if (error instanceof Error) console.error(error.message);
      storeState.addToast({
        title: 'Erro ao tentar atualizar os produtos.',
        message: 'Não foi possível atualizar os produtos no momento. Por favor, tente novamente mais tarde.',
        type: 'error'
      });
    }
  };

  const handleContract = async () => {
    setContractLoading(true);
    try {
      await quoteAsyncUpdate(quoteResponse.quote_id, products);
      setContractLoading(false);
      navigate(`/coverageFinish/?plan=${planParam}${distribuitorParam ? `&dis=${distribuitorParam}` : ''}`);
    } catch (error: any) {
      setContractLoading(false);
      let errorMessage =
        'Não foi possível realizar a sua assinatura no momento. Por favor, tente novamente mais tarde.';
      if (error.isAxiosError) {
        const notifications = error.response?.data?.notifications;
        if (notifications) errorMessage = notifications[0]?.message;
      }
      storeState.addToast({
        title: 'Error',
        message: errorMessage,
        type: 'error'
      });
    }
  };

  const handleProduct = async (product: Product) => {
    const oldProducts = [...products];
    let newProducts = [...products, product];

    if (products.some(({ product_id }) => product_id === product.product_id)) {
      newProducts = products.filter(({ product_id }) => product_id !== product.product_id);
    }

    setProducts(newProducts);

    if (newProducts.length === 0) {
      setServiceCharge(0);
      setTotalValue(0);
    } else {
      fetchProductsData(newProducts, () => setProducts(oldProducts));
    }
  };

  const priceFormatter = (value: number) => {
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL'
    }).format(value);

    return formattedValue;
  };

  useEffect(() => {
    if (quotes.length === 0) {
      navigate(`/?modal=true&plan=${planParam}${distribuitorParam ? `&dis=${distribuitorParam}` : ''}`);
    }
  }, []);

  useEffect(() => {
    if (defaultSelectedProducts.length > 0) {
      setProducts(defaultSelectedProducts);
      fetchProductsData(defaultSelectedProducts);
    }
  }, [defaultSelectedProducts]);

  const { flat_rate, plan_club_name, plan_has_club, plan_club_description } = quoteResponse;

  return (
    <Container maxWidth="lg" sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent>
              <Header
                title="Vamos personalizar a sua cobertura?"
                subTitle="Escolha abaixo o que você precisa:"
                goBackTo={`/?modal=true&plan=${planParam}${distribuitorParam ? `&dis=${distribuitorParam}` : ''}`}
              />
              <Box>
                <Typography variant="h5" sx={styles.description} component="h3">
                  {searchNameEquipment(equipment)} de{' '}
                  {equipmentValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Typography>
                <Typography variant="h6" sx={styles.subTitle}>
                  <ModalCoverage />
                  Aqui explicamos o que significa cada cobertura!
                </Typography>
              </Box>
              <Box>
                {quotes.map((quote: Quote) => (
                  <Box key={quote.product_id}>
                    <CardPrice
                      isSelected={products.some(({ product_id }) => product_id === quote.product_id)}
                      selectProduct={handleProduct}
                      handleDefaultSelectedProducts={handleDefaultSelectedProducts}
                      {...quote}
                    />
                  </Box>
                ))}
              </Box>
              {products.length > 0 && (
                <>
                  <Divider sx={styles.dividerStyle} />
                  <Typography variant="h5" sx={styles.description} component="h3">
                    Resumo da assinatura
                  </Typography>
                  <Box sx={styles.containerAmount}>
                    <Box>
                      {plan_has_club ? (
                        <Box sx={styles.containerServiceCharge}>
                          <Box component="section" sx={styles.FinancialCharges}>
                            <Typography variant="body1">{plan_club_name}</Typography>
                            <ModalHelpClub clubDiscription={plan_club_description} />
                          </Box>
                          <Typography variant="body1">
                            {productsLoading ? <CircularProgress size={16} /> : priceFormatter(flat_rate)}
                          </Typography>
                        </Box>
                      ) : null}

                      <Box sx={styles.containerServiceCharge}>
                        <Box component="section" sx={styles.FinancialCharges}>
                          <Typography variant="body1">Encargos financeiros</Typography>
                          <ModalHelpFinancialCharges />
                        </Box>
                        <Typography variant="body1">
                          {productsLoading ? <CircularProgress size={20} /> : priceFormatter(serviceCharge)}
                        </Typography>
                      </Box>
                      <Divider sx={styles.amountDivider} />
                      <Box sx={styles.containerTotal}>
                        <Typography variant="body1" sx={styles.totalStyle}>
                          Valor Total ( x 12)
                        </Typography>
                        <Typography variant="h6" sx={styles.amountStyle}>
                          {productsLoading ? <CircularProgress size={20} /> : priceFormatter(totalValue)}
                        </Typography>
                      </Box>
                      <Typography variant="body2">(Valor a ser pago mensalmente no cartão de crédito)</Typography>
                    </Box>
                  </Box>
                </>
              )}
              <Box sx={styles.containerButton}>
                <img alt="Compra segura" src={COMPRA_SEGURA} />
                <LoadingButton
                  variant={products.length === 0 ? 'outlined' : 'contained'}
                  size="large"
                  sx={styles.butttonStyle}
                  onClick={handleContract}
                  loading={contractLoading}
                  disabled={products.length === 0}
                >
                  Quero contratar
                </LoadingButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: {
              xs: 'none',
              lg: 'block'
            }
          }}
        >
          <Card>
            <CardContent>
              <SidebarMesages loading={productsLoading} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
