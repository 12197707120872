import { SvgIcon } from '@mui/material';

function ScooterGreen() {
  return (
    <SvgIcon viewBox="0 0 19 20" fill="none">
      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.29301 13.6364H11.8203V12.7273C11.8203 10.7182 13.4476 9.09091 15.4567 9.09091H16.1294L14.4021 1.41818C14.31 1.01644 14.0847 0.657674 13.7629 0.400198C13.441 0.142722 13.0415 0.00167956 12.6294 0H9.09301V1.81818H12.6294L13.9021 7.5H13.893C12.9189 7.79359 12.0462 8.35341 11.3732 9.11636C10.7002 9.87932 10.2537 10.8151 10.0839 11.8182H5.29301C5.07456 11.1987 4.63959 10.6791 4.06826 10.355C3.49692 10.0309 2.82771 9.9242 2.18392 10.0545C1.1112 10.2636 0.247559 11.1455 0.0475594 12.2182C-0.0270508 12.6126 -0.0137413 13.0186 0.0865391 13.4073C0.186819 13.7959 0.371602 14.1577 0.627715 14.4668C0.883828 14.7758 1.20497 15.0246 1.56824 15.1953C1.93152 15.3661 2.32798 15.4546 2.72938 15.4545C3.9112 15.4545 4.9112 14.6909 5.29301 13.6364ZM2.72938 13.6364C2.22938 13.6364 1.82029 13.2273 1.82029 12.7273C1.82029 12.2273 2.22938 11.8182 2.72938 11.8182C3.22938 11.8182 3.63847 12.2273 3.63847 12.7273C3.63847 13.2273 3.22938 13.6364 2.72938 13.6364ZM15.4567 10C13.9476 10 12.7294 11.2182 12.7294 12.7273C12.7294 14.2364 13.9476 15.4545 15.4567 15.4545C16.9657 15.4545 18.1839 14.2364 18.1839 12.7273C18.1839 11.2182 16.9657 10 15.4567 10ZM15.4567 13.6364C14.9567 13.6364 14.5476 13.2273 14.5476 12.7273C14.5476 12.2273 14.9567 11.8182 15.4567 11.8182C15.9567 11.8182 16.3657 12.2273 16.3657 12.7273C16.3657 13.2273 15.9567 13.6364 15.4567 13.6364ZM8.18392 17.2727H4.54756L10.0021 20V18.1818H13.6385L8.18392 15.4545V17.2727Z"
          fill="#03DAC5"
        />
      </svg>
    </SvgIcon>
  );
}

export default ScooterGreen;
