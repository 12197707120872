export const headerContainer = {
  padding: 2,
  alignItems: 'flex-start'
};
export const container = {
  marginTop: 4,
  marginBottom: 4
};
export const switchContainer = {
  marginTop: 2
};
export const containerButton = {
  marginBottom: 2,
  marginRight: { xs: 0, sm: 2 },
  display: 'flex',
  justifyContent: { xs: 'center', sm: 'flex-end' },
  gap: 2
};
export const deleteIconStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  zIndex: 100
};
export const cardAdditionalDriver = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 2
};
export const descritionTitle = {
  fontSize: 16,
  textAlign: 'center',
  marginBottom: 1
};
export const containerForm = {
  width: '100%',
  paddingX: { xs: 0, sm: 2 },
  paddingY: 2
};
export const containerFormDriver = {
  marginTop: -4,
  width: '100%'
};
export const moreDriversStyles = {
  color: '#54C556',
  marginTop: 1,
  fontWeight: 'bold',
  cursor: 'pointer'
};
export const cardContent = {
  width: '-webkit-fill-available'
};
