import { SvgIcon } from '@mui/material';

function FileIcon() {
  return (
    <SvgIcon style={{ width: 50, height: 50 }} viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.0594 10.5281L22.9719 0.440625C22.6906 0.159375 22.3109 0 21.9125 0H2C1.17031 0 0.5 0.670312 0.5 1.5V40.5C0.5 41.3297 1.17031 42 2 42H32C32.8297 42 33.5 41.3297 33.5 40.5V11.5922C33.5 11.1937 33.3406 10.8094 33.0594 10.5281ZM30.0406 12.2812H21.2188V3.45938L30.0406 12.2812ZM30.125 38.625H3.875V3.375H18.0312V13.5C18.0312 14.0221 18.2387 14.5229 18.6079 14.8921C18.9771 15.2613 19.4779 15.4688 20 15.4688H30.125V38.625ZM18.5 19.125C18.5 18.9188 18.3312 18.75 18.125 18.75H15.875C15.6687 18.75 15.5 18.9188 15.5 19.125V24.1875H10.4375C10.2312 24.1875 10.0625 24.3563 10.0625 24.5625V26.8125C10.0625 27.0187 10.2312 27.1875 10.4375 27.1875H15.5V32.25C15.5 32.4562 15.6687 32.625 15.875 32.625H18.125C18.3312 32.625 18.5 32.4562 18.5 32.25V27.1875H23.5625C23.7687 27.1875 23.9375 27.0187 23.9375 26.8125V24.5625C23.9375 24.3563 23.7687 24.1875 23.5625 24.1875H18.5V19.125Z"
        fill="#A6A6A6"
      />
    </SvgIcon>
  );
}

export default FileIcon;
