import Colors from '~/styles/colors';
export const labelStyle = {
  color: Colors.primary?.main,
  fontWeight: 'bold',
  fontSize: 24,
  lineHeight: '1.2em'
  // marginBottom: 2
};
export const container = {
  marginTop: 2,
  width: '100%'
};
export const inputStyle = {
  // marginTop: 2.5,
  borderRadius: 3,
  backgroundColor: '#1E1E1E'
};
export const helperStyle = {
  color: '#E5636D',
  paddingTop: 0.5
};
