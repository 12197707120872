import * as React from 'react';
import { Stack, Box, InputLabel, TextFieldProps, FormHelperText } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import { InputProps } from './types';
import { labelStyle, container } from './styles';
import { helperStyle } from '../Input/styles';

export default function InputDateComponent({
  name,
  label,
  helperText,
  isDirty,
  setField,
  defaultValue,
  ...props
}: InputProps & TextFieldProps) {
  const [value, setValue] = React.useState<Date | null>(null);

  const isValidDate = (date: Date | null) => {
    if (date) {
      return !isNaN(date.getDate());
    } else {
      return false;
    }
  };

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);

    if (setField) {
      setField(isValidDate(newValue) && newValue ? newValue?.toISOString() : '');
    }
  };

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue(null);
    }
  }, [defaultValue]);

  return (
    <Box sx={container}>
      <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
        <InputLabel shrink htmlFor={`input-${name}`} sx={labelStyle}>
          {label}
        </InputLabel>
        <Stack>
          <DesktopDatePicker format="dd/MM/yyyy" value={value} onChange={handleChange} disableFuture />
          {helperText && props.error && (
            <>
              <FormHelperText sx={helperStyle}>{helperText}</FormHelperText>
            </>
          )}
        </Stack>
      </LocalizationProvider>
    </Box>
  );
}
