import { forwardRef } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: Event) => void;
  name: string;
}

interface Event {
  target: {
    name: string;
    value: string;
  };
}

const CurrencyInput = forwardRef<NumberFormatProps, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onChange={(e: Event) => {
        const floatValue = parseInt(e.target.value.replaceAll('.', '').replace(',', '')) || 0;
        const newValue = (floatValue / 100).toFixed(2);
        onChange({
          target: {
            name: props.name,
            value: newValue
          }
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      prefix=""
    />
  );
});

export default CurrencyInput;
