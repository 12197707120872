import * as yup from 'yup';
import { isValidCpf } from '~/utils/isValidCpf';

const schema = yup.object().shape({
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .test(`test-valid-cpf`, 'Digite um cpf válido.', (value) => {
      if (value) {
        return isValidCpf(value) ? true : false;
      }
      return false;
    })
});

export default schema;
