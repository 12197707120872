import { center } from '~/components/PersonalCoverage/SidebarMesages/TypesOfEquipment/styles';

export const headerContainer = {
  display: 'flex',
  padding: 2,
  alignItems: 'flex-start'
};
export const iconGoback = {
  marginRight: 1
};
export const container = {
  marginTop: 4,
  marginBottom: 4
};
export const description = {
  textAlign: 'center',
  color: '#A547FF',
  fontWeight: 'bold'
};
export const subTitle = {
  cursor: 'pointer',
  marginTop: 1,
  textAlign: 'center',
  color: '#03DAC5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
export const helpIcon = {
  marginRight: 1
};
export const containerButton = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 2
};
export const containerAmount = {
  marginTop: 2
};
export const amountStyle = {
  color: '#A547FF',
  fontWeight: 'bold'
};
export const totalStyle = {
  marginTop: 1,
  fontWeight: 'bold'
};
export const descritionTitle = {
  fontSize: 16,
  textAlign: 'center',
  marginBottom: 1
};
export const butttonStyle = {
  marginTop: 2
};
export const containerForm = {
  width: '100%',
  padding: { sm: 1, md: 2 }
};

export const containerTermOfContract = {
  display: 'flex',
  alingItems: center,
  paddingLeft: 1.5,
  paddingTop: { xs: 4 },
  fontSize: { xs: 12, sm: 16 }
};

export const termOfContract = {
  fontSize: { xs: 14, sm: 16 },
  display: 'flex',
  fontWeight: 'bold',
  cursor: 'pointer',
  alignContent: 'center',
  flexWrap: 'wrap'
};

export const termOfContractLabel = {
  fontSize: { xs: 14, sm: 16 }
};

import Colors from '~/styles/colors';

export const modalContainer = {
  position: 'absolute',
  top: 32,
  left: '50%',
  transform: 'translate(-50%, 0px)',
  background: '#121212',
  border: '1.2px solid #323232',
  borderRadius: 6,
  padding: { xs: 0.5, sm: 4 },
  paddingBlock: { xs: 2 },
  width: '95%',
  maxHeight: '90%',
  overflow: 'hidden',
  overflowY: 'auto'
};

export const titleStyle = {
  fontWeight: 'bold',
  marginBottom: 3,
  fontSize: { xs: 18, sm: 20, md: 24 }
};
export const dividerStyle = {
  marginX: 1
};
export const subtitleGreenStyle = {
  fontWeight: 'bold',
  fontSize: 18,
  color: Colors.info?.main || '#03DAC5',
  textAlign: 'center',
  marginBottom: 1
};

export const modalCardContainer = {
  display: 'grid',
  alignItems: 'flex-start',
  justifyItems: 'center',
  height: 'fit-content',
  gap: 6,
  gridTemplateColumns: {
    xs: '1fr',
    sm: '1fr',
    md: '1fr 1fr'
  }
};

export const modalCard = {
  borderRadius: 4,
  background: '#1e1e1e',
  width: '100%',
  padding: 1
};
export const modalContainerTitle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};

export const modalText = {
  fontSize: 16
};

export const modalCardHeader = {
  borderBottom: '1px solid #302f2f',
  width: '90%',
  marginInline: 'auto'
};

export const footerText = {};
