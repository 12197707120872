import { useState } from 'react';
import { Container, Typography, ImageListItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as styles from './styles';
import IMG from '~/assets/images/man_bike.png';
import useStore, { storeState } from '~/store';

export default function HelpZipcodePage() {
  const [loading, setLoading] = useState(false);
  const { contractAsyncDownload, contract_id } = useStore((store) => ({
    contractAsyncDownload: store.contractAsyncDownload,
    contract_id: store.contract_id
  }));

  const handleDownload = async () => {
    if (contract_id) {
      setLoading(true);
      try {
        await contractAsyncDownload(String(contract_id));
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        let errorMessage =
          'Não conseguimos realizar o download com os seus dados no momento. Tente novamente mais tarde.';
        if (error.isAxiosError) {
          const notifications = error.response?.data?.notifications;
          if (notifications) errorMessage = notifications[0]?.message;
        }
        storeState.addToast({
          title: 'Error',
          message: errorMessage,
          type: 'error'
        });
      }
    } else {
      storeState.addToast({
        title: 'Não foi possível realizar o download',
        message: 'Nenhum contrato foi encontrado para realizar o download',
        type: 'warning'
      });
    }
  };

  return (
    <Container maxWidth="lg" sx={styles.container}>
      <ImageListItem sx={styles.imgContainer}>
        <img src={IMG} alt="" style={styles.imgStyle} />
      </ImageListItem>
      <Typography variant="h3" sx={styles.title}>
        Quase lá!
      </Typography>
      <Typography variant="h6" sx={styles.description}>
        Vamos analisar a sua documentação e te avisaremos em breve!
      </Typography>
      <LoadingButton variant="contained" sx={styles.buttonDownload} onClick={handleDownload} loading={loading}>
        Baixar contrato
      </LoadingButton>
    </Container>
  );
}
