import Colors from '~/styles/colors';

export const headerContainer = {
  padding: 2,
  alignItems: 'flex-start'
};
export const container = {
  marginTop: 4,
  marginBottom: 4,
  height: '100%'
};
export const cardContainer = { width: { md: '48rem', sm: "fit-content" }, marginX: 'auto' }
export const buttonLeft = {
  marginRight: 2
};
export const cardHeader = {
  paddingY: 1,
  alignItems: 'flex-start'
}
export const cardItemsContainer = {
  padding: 2,
  display: 'flex',
  justifyContent: 'center',
  gap: 4,
  alignItems: 'center',
  flexDirection: { xs: "column", md: "row" }
}
export const cardListContainer = { width: "12rem" }
export const cardListItem = { padding: 0 }
export const cardListItemTextPrimary = {
  color: Colors.primary?.main || '',
  fontSize: '1.2rem',
  fontWeight: 'bold'
}
export const cardListItemTextSecondary = {
  color: Colors.text?.primary || '',
  fontSize: '1rem'
}
export const contractValueContainer = {
  padding: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 2
}
export const cardFormContainer = {
  padding: 3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 1,
  alignItems: 'center'
}
export const cardFormInputsContainer = { display: { md: 'flex' }, gap: 4, alignItems: 'center' }
export const inputValidtyFormContainer = { display: 'flex', paddingRight: { md: '22rem' } }
export const containerButton = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 2
};
export const descritionTitle = {
  fontSize: 16,
  textAlign: 'center',
  marginBottom: 1
};
export const containerForm = {
  padding: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
export const title = {
  color: Colors.primary?.main || '',
  textAlign: 'center'
};
