import { Message } from '../types';
import { Box, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { containerMessage, container, messageAfter } from './styles';
import useStore from '~/store';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  questionComponent?: Message['questionComponent'];
  Component?: Message['Component'];
}

export const InternationalCoverage = ({ content }: Props) => {
  const { setInternationalCoverage, quote, setStepChat } = useStore((store) => ({
    quote: store.quote,
    setInternationalCoverage: store.setInternationalCoverage,
    setStepChat: store.setStepChat
  }));

  const { internationalCoverage } = quote;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === 'false' ? false : true;

    if (value) setStepChat(10);

    setInternationalCoverage(value);
  };

  return (
    <Box sx={container}>
      <Box sx={() => containerMessage()}>
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-button-international-coverage"
            value={internationalCoverage}
            onChange={handleChange}
          >
            <FormControlLabel
              value={false}
              label="Não"
              control={<Radio inputProps={{ 'aria-label': 'Não' }} checkedIcon={<CheckCircleIcon color="success" />} />}
            />
            <FormControlLabel
              value={true}
              label="Sim"
              control={<Radio inputProps={{ 'aria-label': 'Sim' }} checkedIcon={<CheckCircleIcon color="success" />} />}
            />
          </RadioGroup>
        </FormControl>
        <Box sx={messageAfter} />
      </Box>
    </Box>
  );
};
