import { Message } from '~/components/Chat/types';
import {
  cpfMessages,
  emailMessages,
  equipmentMessages,
  genderMessages,
  nameMessages,
  nextAcessoriesValueMessages,
  nextCompetionsMessages,
  nextEquipmentMaterialMessages,
  nextEquipmentValueMessages,
  nextInternationalCoverageMessages
} from './handleMessages';

interface IHandleStepsProps {
  setDisableStepHandler: (value: boolean) => void;
  setMessages: (messages: any) => void;
  MESSAGES: Message[] | any[];
  setStepChat: (value: number) => void;
  removeMessage: (index: number) => void;
  internationalCoverage: boolean;
  equipment: string;
  name: string;
}

export interface IMessage extends Pick<IHandleStepsProps, 'MESSAGES' | 'setMessages' | 'setDisableStepHandler'> {}

interface IHandleNextStepProps {
  currentStep: number;
  steps?: number[];
  setStepChat: (value: number) => void;
}

export const handleNextStep = ({ setStepChat, currentStep, steps = [] }: IHandleNextStepProps) => {
  if (currentStep === steps[steps.length - 1]) {
    setStepChat(0);
  }

  const nextStepChat = steps.find((num: number) => num > currentStep) || 0;

  setStepChat(nextStepChat);
};

export const handlePreviousStep = (
  { setStepChat, currentStep, steps = [] }: IHandleNextStepProps,
  params: IMessage,
  removeMessage: (index: number) => void,
  equipment: string,
  name: string,
  internationalCoverage: boolean
) => {
  const actualStep = steps.find((num: number) => currentStep === num) || 0;

  const stepsHandlers: { [key: number]: () => void } = {
    1: () => nameMessages({ ...params }),
    2: () => {
      emailMessages({ ...params }, removeMessage, name);
      setStepChat(2);
    },
    3: () => {
      cpfMessages({ ...params }, removeMessage);
      setStepChat(3);
    },
    4: () => {
      genderMessages({ ...params }, removeMessage);
      setStepChat(4);
    },
    5: () => {
      equipmentMessages({ ...params }, removeMessage);
      setStepChat(5);
    },
    6: () => {
      nextEquipmentValueMessages({ ...params }, equipment);
      setStepChat(6);
    },
    7: () => {
      nextAcessoriesValueMessages({ ...params }, equipment);
      setStepChat(7);
    },
    8: () => {
      nextEquipmentMaterialMessages({ ...params });
      setStepChat(8);
    },
    9: () => {
      nextCompetionsMessages({ ...params }, name);
      setStepChat(9);
    },
    10: () => {
      nextInternationalCoverageMessages({ ...params }, name);
      setStepChat(10);
    }
  };

  let previousStep = steps.find((num: number) => num === actualStep - 1);

  if (previousStep === undefined) {
    previousStep = Math.max(...steps.filter((num: number) => num < actualStep));
  }

  if (previousStep !== undefined) {
    stepsHandlers[previousStep]();
  }
};

export const handleSteps = ({
  setDisableStepHandler,
  setMessages,
  MESSAGES,
  setStepChat,
  removeMessage,
  internationalCoverage,
  equipment,
  name
}: IHandleStepsProps) => {
  const handleName = async () => nameMessages({ MESSAGES, setDisableStepHandler, setMessages });

  const handleNextEmail = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    emailMessages({ MESSAGES, setDisableStepHandler, setMessages }, removeMessage, name);
  };

  const handleNextCpf = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    cpfMessages({ MESSAGES, setDisableStepHandler, setMessages }, removeMessage);
  };

  const handleNextGender = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    genderMessages({ MESSAGES, setDisableStepHandler, setMessages }, removeMessage);
  };

  const handleNextEquipment = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    equipmentMessages({ MESSAGES, setDisableStepHandler, setMessages }, removeMessage);
  };

  const handleNextEquipmentValue = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextEquipmentValueMessages({ MESSAGES, setDisableStepHandler, setMessages }, equipment);
  };

  const handleNextAccessoriesValue = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextAcessoriesValueMessages({ MESSAGES, setDisableStepHandler, setMessages }, equipment);
  };

  const handleNextEquipmentMaterial = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextEquipmentMaterialMessages({ MESSAGES, setDisableStepHandler, setMessages });
  };

  const handleNextCompetitions = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextCompetionsMessages({ MESSAGES, setDisableStepHandler, setMessages }, name);
  };

  const handleNextInternationalCoverage = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextInternationalCoverageMessages({ MESSAGES, setDisableStepHandler, setMessages }, name);
  };

  const handleNextSteps = ({ currentStep, steps = [] }: any) => {
    const stepsHandlers: { [key: number]: () => void } = {
      1: () => handleName(),
      2: () => handleNextEmail(currentStep, steps),
      3: () => handleNextCpf(currentStep, steps),
      4: () => handleNextGender(currentStep, steps),
      5: () => handleNextEquipment(currentStep, steps),
      6: () => handleNextEquipmentValue(currentStep, steps),
      7: () => handleNextAccessoriesValue(currentStep, steps),
      8: () => handleNextEquipmentMaterial(currentStep, steps),
      9: () => handleNextCompetitions(currentStep, steps),
      10: () => handleNextInternationalCoverage(currentStep, steps)
    };

    const nextStep = steps.find((num: number) => num > currentStep);

    if (nextStep !== undefined) {
      stepsHandlers[nextStep]();
    }

    if (!nextStep) {
      setStepChat(0);
    }
  };

  return { handleName, handleNextSteps };
};
