import Close from '@mui/icons-material/Close';
import HelpRounded from '@mui/icons-material/HelpRounded';
import { Backdrop, Card, CardContent, Fade, Grid, IconButton, Modal, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import useStore from '~/store';
import { cardContainer, container, containerTitle, titleStyle } from './styles';

export default function ModalHelpFinancialCharges() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { quote } = useStore();

  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpRounded color="info" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={container}>
            <Box sx={containerTitle}>
              <Typography variant="h5" color="primary" sx={titleStyle}>
                Ficou com alguma dúvida sobre encargos financeiros?
              </Typography>
              <IconButton color="default" aria-label="upload picture" component="span" onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={6} xs={12}>
                <Card sx={cardContainer}>
                  <CardContent>
                    <Typography variant="body1">
                      São os custos relacionados à contratação do pacote de serviços em nosso ambiente digital. Como por
                      exemplo: Taxas de processamento, Antifraude, Taxas de cartão de crédito, etc.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
