export const headerContainer = {
  padding: 2,
  alignItems: 'flex-start'
};
export const container = {
  marginTop: 4,
  marginBottom: 4
};

export const bodyContainer = {
  padding: 2,
  alignItems: 'flex-start'
};

export const totalContainer = {
  padding: '0 1rem'
};

export const purpleText = {
  color: '#a547ff',
  fontSize: '1rem',
  fontWeight: 700
};

export const purplePrice = {
  ...purpleText,
  fontSize: '1.25rem'
};

export const purpleLabel = {
  ...purpleText,
  fontSize: '1.5rem'
};

export const normalText = {
  fontSize: '.85rem',
  fontWeight: 300
};

export const tip = {
  ...normalText,
  fontSize: '.75rem'
};

export const internalCard = {
  background: '#1e1e1e',
  padding: '1rem 1.5rem'
};

export const changeAddressBtn = {
  height: '2rem',
  width: 'max-content',
  padding: '0 2rem',
  background: 'none',
  border: '1px solid #FAFAFA',
  fontSize: '.8rem',
  fontWeight: 500,
  marginTop: 2
};

export const inputStyle = () => ({
  '& .MuiFormHelperText-root': {
    fontSise: '100px !important',
    color: '#FFF'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 4,
    borderColor: '#A547FF',
    borderWidth: 2
  },
  '& .MuiOutlinedInput-input': {
    color: '#FFF',
    fontSize: 16
  },
  '& .MuiInputAdornment-root p': {
    color: '#FFF',
    fontSize: 16
  }
});
