/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import create from 'zustand';

interface LoadingStore {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

const useLoading = create<LoadingStore>((set) => ({
  loading: false,
  startLoading: () => {
    set({ loading: true });
  },
  stopLoading: () => {
    set({ loading: false });
  }
}));

export const storeStateLoading = useLoading.getState();
export default useLoading;
