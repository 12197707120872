export const containerMessage = () => ({
  backgroundColor: '#1E1E1E',
  right: 0,
  border: '2px solid #A547FF',
  borderRadius: '16px 16px 0px 16px',
  color: '#FFFFFF',
  padding: 1,
  marginTop: 1,
  width: '100%'
});
export const containerOption = (isSelected: boolean) => ({
  backgroundColor: isSelected ? '#3B3442' : '323232',
  borderRadius: 2,
  padding: 1,
  cursor: 'pointer',
  border: `1px solid ${isSelected ? '#A547FF' : '#323232'}`,
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  minWidth: 160
});
export const optionLabel = {
  cursor: 'pointer',
  width: '100%',
  // justifyContent: 'space-between',
  // alignItems: 'center',
  fontSize: 16
};
export const center = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};
