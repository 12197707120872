import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { ModalHeader } from '~/components';
import { Box, Fade, Container, Modal } from '@mui/material';
import { MessageList } from '~/components/Chat';
import { Message } from '~/components/Chat/types';
import MESSAGES from './messages';
import useStore, { storeState } from '~/store';
import { searchNameEquipmentId } from '~/utils/searchNameEquipment';
import { parseParamToString } from '~/utils/parseParamToString';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { container, containerMessageList } from './styles';
import { handlePreviousStep, handleSteps } from './utils/handleSteps';

const initialMessages: Message[] = [];

export default function BasicModal({ steps }: any) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { quote, quoteAsyncPost, setContinents, setStepChat } = useStore((store) => ({
    quote: store.quote,
    quoteAsyncPost: store.quoteAsyncPost,
    setContinents: store.setContinents,
    setStepChat: store.setStepChat
  }));

  const {
    equipment,
    stepChat,
    hasInvoice,
    name,
    email,
    cpf,
    gender,
    distributor_id,
    plan_id,
    accessoriesValue,
    continents,
    internationalCoverage,
    isNewEquipment,
    participatesInCompetitions,
    equipmentMaterial,
    equipmentCondition,
    yearOfManufacture,
    placeStore
  } = quote;

  const equipmentValue = quote.equipmentValue;
  const [open, setOpen] = useState(true);
  const [hasValidValue, setHasValidValue] = useState({
    name: false,
    email: false,
    cpf: false
  });
  const [loading, setLoading] = useState(false);
  const [disableStepHandler, setDisableStepHandler] = useState(false);
  const [messages, setMessages] = useState(initialMessages);
  const handleClose = () => setOpen(false);
  const removeMessage = (key: number) => {
    const newMessages = [...messages];
    newMessages.splice(
      newMessages.findIndex(({ id }) => id === key),
      1
    );
    setMessages(newMessages);
  };

  const handleValidateInputs = (key: 'email' | 'name' | 'cpf', isValid: boolean) => {
    setHasValidValue((prevState) => ({ ...prevState, [key]: isValid }));
  };

  const handleQuotation = async () => {
    const quoteBody = {
      customer: {
        name,
        email,
        document: cpf,
        document_type: 8,
        gender
      },
      equipment_type_id: searchNameEquipmentId(equipment),
      plan_id: searchParams.get('plan') ?? parseParamToString(plan_id),
      distributor_id: searchParams.get('dis') ?? distributor_id,
      quotation_value: equipmentValue,
      value_of_accessories: accessoriesValue,
      has_invoice: hasInvoice,
      is_new: isNewEquipment,
      participates_competition: participatesInCompetitions,
      equipment_material_type: equipmentMaterial,
      equipment_conditions: equipmentCondition,
      international_coverage: continents,
      place_store: placeStore,
      year_of_manufacture: yearOfManufacture
    };

    setLoading(true);
    try {
      await quoteAsyncPost(quoteBody);
      setLoading(false);
      const planParam = searchParams.get('plan') ?? parseParamToString(plan_id);
      const distribuitorParam = searchParams.get('dis');

      navigate(
        `/personalCoverage?plan=${planParam}${
          distribuitorParam ? `${distribuitorParam ? `&dis=${distribuitorParam}` : ''}` : ''
        }`
      );
    } catch (error: any) {
      setLoading(false);
      let errorMessage = 'Não foi possível processar a sua cotação no momento. Por favor, tente novamente mais tarde.';
      if (error.isAxiosError) {
        const notifications = error.response?.data?.notifications;
        if (notifications) errorMessage = notifications[0]?.message;
      }
      storeState.addToast({
        title: 'Error',
        message: errorMessage,
        type: 'error'
      });
    }
  };

  const { handleName, handleNextSteps } = handleSteps({
    setDisableStepHandler,
    setMessages,
    MESSAGES,
    setStepChat,
    removeMessage,
    internationalCoverage,
    equipment,
    name
  });

  useEffect(() => {
    if (stepChat === 10) {
      if (internationalCoverage) {
        if (messages.length === 0) {
          const message = {
            ...MESSAGES[26],
            content: MESSAGES[26].content?.replace('{name}', name)
          };
          setMessages(() => [message, MESSAGES[27], MESSAGES[28], MESSAGES[29], MESSAGES[32], MESSAGES[33]]);
        } else {
          setMessages((messages) => [...messages, MESSAGES[28], MESSAGES[29], MESSAGES[32], MESSAGES[33]]);
        }
      } else {
        const message = {
          ...MESSAGES[26],
          content: MESSAGES[26].content?.replace('{name}', name)
        };
        setMessages(() => [message, MESSAGES[27]]);
        setContinents(1);
      }
    }
  }, [internationalCoverage]);

  useEffect(() => {
    if (stepChat === 1) {
      setStepChat(1);
      handleName();
    }
  }, [stepChat]);

  return (
    <Container maxWidth="sm">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box sx={container}>
            <ModalHeader
              title="Cálculo simplificado de cobertura"
              visibleGoBack={stepChat !== 1}
              onClickClose={() => setOpen(false)}
              onClickBack={() =>
                handlePreviousStep(
                  { currentStep: stepChat, setStepChat, steps },
                  { MESSAGES, setDisableStepHandler, setMessages },
                  removeMessage,
                  equipment,
                  name,
                  internationalCoverage
                )
              }
              disableGoBack={disableStepHandler}
            />
            <Box
              sx={{
                padding: '1rem 0',
                display: 'flex',
                flexDirection: 'row',
                maxHeight: '420px',
                overflowY: 'auto',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                  width: '.5rem'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#FFF',
                  borderRadius: '4px'
                }
              }}
            >
              <Box sx={containerMessageList}>
                <MessageList handleValidateInputs={handleValidateInputs} messages={messages} steps={steps} />
              </Box>
            </Box>

            {stepChat === 1 && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={name.length <= 3 || disableStepHandler || !hasValidValue.name}
                  size="large"
                  variant={name.length <= 3 || disableStepHandler || !hasValidValue.name ? 'outlined' : 'contained'}
                  color="primary"
                  onClick={() => handleNextSteps({ currentStep: stepChat, steps })}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}

            {stepChat === 2 && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={disableStepHandler || !hasValidValue.email}
                  size="large"
                  variant={disableStepHandler || !hasValidValue.email ? 'outlined' : 'contained'}
                  color="primary"
                  onClick={() => handleNextSteps({ currentStep: stepChat, steps })}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}

            {stepChat === 3 && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={disableStepHandler || !hasValidValue.cpf}
                  size="large"
                  variant={disableStepHandler || !hasValidValue.cpf ? 'outlined' : 'contained'}
                  color="primary"
                  onClick={() => handleNextSteps({ currentStep: stepChat, steps })}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}

            {stepChat === 4 && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={!gender || disableStepHandler}
                  size="large"
                  variant={!gender || disableStepHandler ? 'outlined' : 'contained'}
                  color="primary"
                  onClick={() => handleNextSteps({ currentStep: stepChat, steps })}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}

            {stepChat === 5 && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={equipment === 'false'}
                  size="large"
                  variant={equipment === 'false' ? 'outlined' : 'contained'}
                  color="primary"
                  onClick={() => handleNextSteps({ currentStep: stepChat, steps })}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}

            {stepChat === 6 && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={
                    equipmentValue < 1000 || equipmentValue > 100000 || !yearOfManufacture || disableStepHandler
                  }
                  size="large"
                  variant={
                    equipmentValue < 1000 || equipmentValue > 100000 || !yearOfManufacture || disableStepHandler
                      ? 'outlined'
                      : 'contained'
                  }
                  color="primary"
                  onClick={() => handleNextSteps({ currentStep: stepChat, steps })}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}

            {stepChat === 7 && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={Number(accessoriesValue) > Number(equipmentValue) * 0.1 || disableStepHandler}
                  size="large"
                  variant={
                    Number(accessoriesValue) > Number(equipmentValue) * 0.1 || disableStepHandler
                      ? 'outlined'
                      : 'contained'
                  }
                  color="primary"
                  onClick={() => handleNextSteps({ currentStep: stepChat, steps })}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}

            {stepChat === 8 && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={disableStepHandler}
                  size="large"
                  variant={disableStepHandler ? 'outlined' : 'contained'}
                  color="primary"
                  onClick={() => handleNextSteps({ currentStep: stepChat, steps })}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}

            {stepChat === 9 && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={disableStepHandler}
                  size="large"
                  variant={disableStepHandler ? 'outlined' : 'contained'}
                  color="primary"
                  onClick={() => handleNextSteps({ currentStep: stepChat, steps })}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}

            {(stepChat === 0 || stepChat === 10) && (
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  disabled={(internationalCoverage && (continents === 1 || placeStore === 1)) || disableStepHandler}
                  size="large"
                  variant={
                    (internationalCoverage && continents === 1 && placeStore === 1) || disableStepHandler
                      ? 'outlined'
                      : 'contained'
                  }
                  color="primary"
                  onClick={() => handleQuotation()}
                  loading={loading}
                >
                  Próximo passo
                </LoadingButton>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
}
