import { Card, Grid, CardContent, Typography, Box, Button, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { priceFormatter } from '~/utils/priceFormatter';

library.add(far, fas);

interface Upsell {
  product_id: number;
  product_name: string;
  product_description: string;
  product_price: number;
  cover: {
    cover_name: string;
    cover_description: string;
    cover_icon: string;
  }[];
}

interface Option {
  isChecked: boolean;
  isDetailsOpen: boolean;
}

type UpsellWithOption = Upsell & Option;

interface Props {
  upsell: Upsell[] | undefined;
  products: number[];
  handleProductChange: (productId: number) => Promise<void>;
}

export function UpsellCard({ upsell, products, handleProductChange }: Props) {
  if (!upsell) return null;

  const [upsells, setUpsells] = useState([] as UpsellWithOption[]);

  function toggleDetails(index: number) {
    setUpsells((prev) => {
      const newState = [...prev];
      newState[index].isDetailsOpen = !newState[index].isDetailsOpen;

      return newState;
    });
  }

  function toggleCheckbox(index: number) {
    setUpsells((prev) => {
      const newState = [...prev];
      newState[index].isChecked = !newState[index].isChecked;

      return newState;
    });
  }

  function mergeOptions(contractItems: Upsell[]): UpsellWithOption[] {
    return contractItems.map((contractItem) => ({
      ...contractItem,
      isChecked: true,
      isDetailsOpen: false
    }));
  }

  useEffect(() => {
    setUpsells(mergeOptions(upsell));
  }, [upsell]);

  return (
    <Box
      sx={{
        margin: { xs: '0rem 0.5rem', sm: '0rem 3rem', md: '0rem 6rem' }
      }}
    >
      <Typography
        variant="h3"
        textAlign="center"
        fontWeight="bold"
        color="primary"
        sx={{
          fontSize: { xs: 22, md: 26 }
        }}
      >
        Quero aproveitar a oportunidade e adicionar na assinatura
      </Typography>
      {upsells.map((upsell, index) => {
        return (
          <Card key={upsell.product_id} sx={{ border: '3px dotted #54c556', marginY: 1 }}>
            <CardContent sx={{ background: '#1e1e1e', padding: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: '1rem'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={products.includes(upsell.product_id)}
                    onChange={() => handleProductChange(upsell.product_id)}
                    color="success"
                  />
                  <Typography variant="h6" fontWeight="bold" color="primary">
                    {upsell.product_name}
                  </Typography>
                </Box>
                <Typography variant="h6" fontWeight="600" color="primary">
                  12x R${priceFormatter.format(upsell.product_price)}
                </Typography>
              </Box>
              <Typography variant="h6" fontSize={14} fontWeight="600">
                Sem franquia, sempre que necessitar!
              </Typography>
              <Typography variant="h6" fontSize={14} fontWeight="400">
                Corbeturas inclusas:
              </Typography>
              <Button
                variant="text"
                sx={{
                  paddingY: 0,
                  color: '#54c556',
                  fontSize: '0.9rem',
                  fontWeight: 700,
                  marginBottom: 1
                }}
                onClick={() => toggleDetails(index)}
              >
                {upsell.isDetailsOpen ? '-' : '+'} detalhes
              </Button>
              {upsell.isDetailsOpen && <Typography>{upsell.product_description}</Typography>}
              <Grid container spacing={1}>
                {upsell.cover?.map((cover) => (
                  <Grid key={cover.cover_name} item sm={12 / upsell.cover.length} xs={6}>
                    <Box
                      sx={{
                        borderRadius: '50%',
                        display: 'flex',
                        width: '2rem',
                        height: '2rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0rem auto 0.5rem'
                      }}
                    >
                      <FontAwesomeIcon icon={cover.cover_icon as IconProp} />
                    </Box>
                    <Typography variant="h6" fontSize={13} textAlign={'center'} fontWeight="300" lineHeight={1.2}>
                      {cover.cover_name}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
}
