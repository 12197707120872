import {
  ForgotPasswordInitialRequestParams,
  SignInRequestParams,
  SignInResponse,
  SignUpRequestParams,
  UserDetailsResponse
} from './types';
import api from '~/services/api';

class AuthService {
  async signIn(payload: SignInRequestParams): Promise<SignInResponse> {
    const { data } = await api.post<SignInResponse>('/api/autenticacao', {
      username: payload.username,
      senha: payload.password,
      serial: '0123456789',
      lat: 1,
      long: 1,
      rememberMe: true
    });
    return data;
  }

  async signUp(payload: SignUpRequestParams) {
    const { data } = await api.post('/api/Usuario/SolicitaCadastro', payload);
    return data;
  }

  async forgotPasswordInitial(payload: ForgotPasswordInitialRequestParams) {
    const { data } = await api.post('/api/Usuario/Esqueci-Senha-Init', payload);
    return data;
  }

  async authUserDetails(token: string): Promise<UserDetailsResponse> {
    const { data } = await api.get<UserDetailsResponse>('/api/autenticacao', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    return data;
  }
}

export default new AuthService();
