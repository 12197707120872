import create, { GetState, SetState } from 'zustand';
import createAuthSlice from './slices/auth';
import createToastSlice from './slices/toast';
import createQuoteSlice from './slices/quote';
import createContractSlice from './slices/contract';
// import createUserSlice from './slices/users'
import { AuthStore } from './slices/auth/types';
import { ToastStore } from './slices/toast/types';
import { QuoteStore } from './slices/quote/types';
import { ContractStore } from './slices/contract/types';

const useStore = create((set, get) => ({
  ...createToastSlice(set as SetState<ToastStore>, get as GetState<ToastStore>),
  ...createAuthSlice(set as SetState<AuthStore>, get as GetState<AuthStore>),
  ...createQuoteSlice(set as SetState<QuoteStore>, get as GetState<QuoteStore>),
  ...createContractSlice(set as SetState<ContractStore>, get as GetState<ContractStore>)
  // ...createUserSlice(set as SetState<UsersStore>, get as GetState<UsersStore>),
}));

export const storeState = useStore.getState();
export default useStore;
