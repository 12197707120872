interface Props {
  size: number;
  color?: string;
}

function TruckIcon({ size, color = '#FFF' }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width={size} height={size}>
      <path d="M3.75 3a.779.779 0 00-.531.219.785.785 0 00-.063 1.031L4 5.313V9.5a.5.5 0 01-1 0V9H2v.5c0 .827.673 1.5 1.5 1.5S5 10.327 5 9.5V6.594L11 13H3a1 1 0 00-1 1v3a1 1 0 001 1h1a3 3 0 116 0h4a3 3 0 116 0h1a1 1 0 001-1v-4.25l-1.844-3.656A1.977 1.977 0 0018.375 8H15a1 1 0 00-1 1v1L4.281 3.187A.749.749 0 003.75 3zM16 10h2.375l1.5 3H16v-3zm-9 6a2 2 0 100 4 2 2 0 000-4zm10 0a2 2 0 100 4 2 2 0 000-4z"></path>
    </svg>
  );
}

export default TruckIcon;
