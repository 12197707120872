import { GetState, SetState } from 'zustand';
import { QuoteStore, Product, QuoteResponse, Quote } from './types';
import { QuoteRequestParams, CustomerRequestParams, PaymentRequestParams } from '~/services/modules/quotes/types';
import QuoteService from '~/services/modules/quotes';
const localQuote = QuoteService.localQuote();

const createQuoteSlice = (set: SetState<QuoteStore>, get: GetState<QuoteStore>) => ({
  contractResponse: localQuote?.contractResponse || null,
  quote: {
    equipment: 'false',
    zipcode: '',
    city: '',
    state: '',
    neighborhood: '',
    street: '',
    number: '',
    additionalDetails: '',
    name: '',
    email: '',
    phoneNumber: '',
    cpf: '',
    gender: '',
    birthDate: '',
    equipmentValue: 0,
    yearOfManufacture: 0,
    accessoriesValue: 0,
    hasInvoice: false,
    isNewEquipment: false,
    equipmentMaterial: 1,
    equipmentCondition: 1,
    participatesInCompetitions: false,
    internationalCoverage: false,
    placeStore: 1,
    continents: 1,
    isValideZipcode: false,
    stepChat: 1,
    plan_id: process.env.REACT_APP_ARIDE_DEFAULT_PLAN,
    distributor_id: null
  },
  customer: {
    name: '',
    email: '',
    mobile_phone: '',
    document: '',
    document_type: '',
    birthdate: ''
  },
  quoteResponse: (undefined as unknown) as QuoteResponse,
  quotes: (localQuote?.quotes as Quote[]) || [],
  products: [] as Product[],
  error: null,
  totalValue: 0,
  serviceCharge: 0,
  setTotalValue: (totalValue: number) => {
    set((state) => ({ totalValue }));
  },
  setProducts: (products: Product[]) => {
    set((state) => ({ products }));
  },
  setServiceCharge: (serviceCharge: number) => {
    set((state) => ({ serviceCharge }));
  },
  setEquipment: (equipment: string) => {
    set((state) => ({ quote: { ...state.quote, equipment } }));
  },
  setZipcode: (zipcode: string) => {
    set((state) => ({ quote: { ...state.quote, zipcode } }));
  },
  setState: (newState: string) => {
    set((state) => ({ quote: { ...state.quote, state: newState } }));
  },
  setCity: (city: string) => {
    set((state) => ({ quote: { ...state.quote, city } }));
  },
  setNeighborhood: (neighborhood: string) => {
    set((state) => ({ quote: { ...state.quote, neighborhood } }));
  },
  setStreet: (street: string) => {
    set((state) => ({ quote: { ...state.quote, street } }));
  },
  setNumber: (number: string) => {
    set((state) => ({ quote: { ...state.quote, number } }));
  },
  setAdditionalDetails: (additionalDetails: string) => {
    set((state) => ({ quote: { ...state.quote, additionalDetails } }));
  },
  SetName: (name: string) => {
    set((state) => ({ quote: { ...state.quote, name } }));
  },
  SetPhoneNumber: (phoneNumber: string) => {
    set((state) => ({ quote: { ...state.quote, phoneNumber } }));
  },
  SetCpf: (cpf: string) => {
    set((state) => ({ quote: { ...state.quote, cpf } }));
  },
  setGender: (gender: 'm' | 'f') => {
    set((state) => ({ quote: { ...state.quote, gender } }));
  },
  SetBirthDate: (birthDate: string) => {
    set((state) => ({ quote: { ...state.quote, birthDate } }));
  },
  SetEmail: (email: string) => {
    set((state) => ({ quote: { ...state.quote, email } }));
  },
  setStepChat: (step: number) => {
    set((state) => ({
      quote: { ...state.quote, stepChat: step }
    }));
  },
  setEquipmentValue: (equipmentValue: number) => {
    set((state) => ({ quote: { ...state.quote, equipmentValue } }));
  },
  setYearOfManufacture: (yearOfManufacture: number) => {
    set((state) => ({ quote: { ...state.quote, yearOfManufacture } }));
  },
  setAccessoriesValue: (accessoriesValue: number) => {
    set((state) => ({ quote: { ...state.quote, accessoriesValue } }));
  },
  setHasInvoice: (hasInvoice: boolean) => {
    set((state) => ({ quote: { ...state.quote, hasInvoice } }));
  },
  setIsNewEquipment: (isNewEquipment: boolean) => {
    set((state) => ({ quote: { ...state.quote, isNewEquipment } }));
  },
  setEquipmentMaterial: (equipmentMaterial: number) => {
    set((state) => ({ quote: { ...state.quote, equipmentMaterial } }));
  },
  setEquipmentConditions: (equipmentCondition: number) => {
    set((state) => ({ quote: { ...state.quote, equipmentCondition } }));
  },
  setParticipatesInCompetitions: (participatesInCompetitions: boolean) => {
    set((state) => ({ quote: { ...state.quote, participatesInCompetitions } }));
  },
  setInternationalCoverage: (internationalCoverage: boolean) => {
    set((state) => ({ quote: { ...state.quote, internationalCoverage } }));
  },
  setPlaceStore: (placeStore: number) => {
    set((state) => ({ quote: { ...state.quote, placeStore } }));
  },
  setContinents: (continents: number) => {
    set((state) => ({ quote: { ...state.quote, continents } }));
  },
  setIsValideZipcode: (isValid: boolean) => {
    set((state) => ({ quote: { ...state.quote, isValideZipcode: isValid } }));
  },
  quoteAsyncPost: async (payload: QuoteRequestParams, callback?: () => void) => {
    console.log('payload', payload);
    const quote = await QuoteService.postQuote(payload);
    const { quotes, ...rest } = quote;
    console.log('quote', quote);
    set({ quoteResponse: rest, quotes });
    callback && callback();
  },
  quoteAsyncUpdate: async (quote_id: string, quoteProducts: Product[]) => {
    // try {
    const payload = {
      products: quoteProducts.map((p) => ({ product_id: p.product_id }))
    };
    const response = await QuoteService.updateQuote(quote_id, payload);
    set({ products: quoteProducts });
    return response;
    // } catch (error) {
    //   // set({ quote: null })
    // }
  },
  quoteAsyncPostCustomer: async (quoteId: string, customer: CustomerRequestParams) => {
    // try {
    await QuoteService.postQuoteCustomer(quoteId, customer).then(() => {
      set({ customer });
    });
    // } catch (error) {
    //   // set({ quote: null })
    // }
  },
  quoteAsyncPostPayment: async (payment: PaymentRequestParams) => {
    const response = await QuoteService.postQuotePayment(payment);
    set((state) => ({ ...state, contractResponse: response }));
  }
});

export default createQuoteSlice;
