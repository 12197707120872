import Signup from '../components/modals/signup/pt.json';
import Forgot from '../components/modals/forgot/pt.json';
import ScheduleDetails from '../components/modals/scheduleDetails/pt.json';

import Menu from '../components/shared/menu/pt.json';
import Calendar from '../components/shared/calendar/pt.json';
import Pagination from '../components/shared/pagination/pt.json';
import Select from '../components/shared/select/pt.json';
import Mobile from '../components/shared/mobile/pt.json';
import Cep from '../components/shared/cep/pt.json';
import UploadImage from '../components/shared/uploadImage/pt.json';

export default {
  pt: {
    translations: {
      ...Signup,
      ...Forgot,
      ...Menu,
      ...Calendar,
      ...Pagination,
      ...Select,
      ...Mobile,
      ...Cep,
      ...UploadImage
    }
  }
};
