import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colors from '~/styles/colors';

export const container = {
  position: 'absolute',
  top: 32,
  left: '50%',
  transform: 'translate(-50%, 0px)',
  background: '#121212',
  border: '1.2px solid #323232',
  borderRadius: 6,
  padding: 2,
  width: '80%'
};

export const containerMessageList = {
  width: '100%',
  minHeight: 300,
  maxWidth: 'calc(100hv - 300px)'
};

export const titleStyle = {
  fontWeight: 'bold'
};
export const iconStyle = {
  color: '#03DAC5'
};
export const subtitleStyle = {
  fontWeight: 'bold'
};
export const subtitleGreenStyle = {
  fontWeight: 'bold',
  color: Colors.info?.main || '#03DAC5',
  textAlign: 'center'
};
export const cardContainer = {
  borderRadius: 4
};
export const descriontionStyle = {
  marginTop: 2,
  marginBottom: 2
};
export const containerCards = {
  overflow: 'auto',
  maxHeight: '70vh'
};
export const containerTitle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};
export const containerIcon = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 1,
  margin: 0.5,
  borderRadius: '50%',
  backgroundColor: '#323232'
};
export const containerIcons = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
};
export const paragraphStyle = {
  fontSize: { xs: 14, md: 16 },
  marginBottom: 1
};
export const StyledLink = styled(Link)`
  color: ${Colors?.primary?.light};
`;
