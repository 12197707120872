import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '~/pages/Home';
import PersonalCoverage from '~/pages/PersonalCoverage';
import CoverageFinish from '~/pages/CoverageFinish';
import Payment from '~/pages/Payment';
import PaymentConfirm from '~/pages/PaymentConfirm';
import Contract from '~/pages/contract';
import ContractStep1 from '~/pages/contract/step1';
import ContractStep2 from '~/pages/contract/step2';
import ContractStep3 from '~/pages/contract/step3';
import ContractStep4 from '~/pages/contract/step4';
import ContractStep5 from '~/pages/contract/step5';
import ContractStep6 from '~/pages/contract/step6';
import DashFrame from '~/layout/DashFrame';
import useStore from '~/store';
import ContractRenewal from '~/pages/contract/renewal';
import { RenewalSuccess } from '~/pages/contract/renewal/success';
import ConfigContract from '~/pages/contract/config';

// import PublicRoute from './PublicRoute'
interface Props {
  element: React.FC;
}

const Template: React.FC<Props> = ({ element }) => <DashFrame>{element}</DashFrame>;

const RoutesApp: React.FC = () => {
  const [loading, setLoading] = useState(true);
  // const location = useLocation()
  // const state = location.state as { backgroundLocation?: Location }
  const { getClientCredencial, clientCredencial } = useStore((store) => ({
    getClientCredencial: store?.getClientCredencial,
    clientCredencial: store?.clientCredencial
  }));
  useEffect(() => {
    (async () => {
      getClientCredencial(null);
    })();
  }, []);

  return (
    <>
      {clientCredencial && (
        <>
          <Routes>
            <Route path="">
              <Route index element={<HomePage />} />
              {/* <Route path="personalCoverage" element={<PersonalCoverage />} /> */}
            </Route>
            <Route path="personalCoverage" element={<DashFrame />}>
              <Route index element={<PersonalCoverage />} />
            </Route>
            <Route path="coverageFinish" element={<DashFrame />}>
              <Route index element={<CoverageFinish />} />
            </Route>
            <Route path="payment" element={<DashFrame />}>
              <Route index element={<Payment />} />
            </Route>
            <Route path="paymentConfirm" element={<DashFrame />}>
              <Route index element={<PaymentConfirm />} />
            </Route>
            <Route path="contract" element={<DashFrame />}>
              <Route index element={<Contract />} />
              <Route path="user" element={<ContractStep1 />} />
              <Route path="address" element={<ContractStep2 />} />
              <Route path="equipment" element={<ContractStep3 />} />
              <Route path="file" element={<ContractStep4 />} />
              <Route path="emergency" element={<ContractStep5 />} />
              <Route path="download" element={<ContractStep6 />} />
              <Route path="renewal/:contractId" element={<ContractRenewal />} />
              <Route path="renewal/success/:contractId" element={<RenewalSuccess />} />
              <Route path=":contractId" element={<ConfigContract />} />
            </Route>
          </Routes>
        </>
      )}
    </>
  );
};

export default RoutesApp;
