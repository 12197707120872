import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Modal,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  Divider,
  Fade,
  Backdrop,
  CardHeader
} from '@mui/material';
import HelpRounded from '@mui/icons-material/HelpRounded';
import Close from '@mui/icons-material/Close';
import { container, cardContainer, containerTitle, titleStyle, subtitleGreenStyle, dividerStyle } from './styles';
import useStore from '~/store';

export default function ModalHelpCPF() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { quote } = useStore();

  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpRounded color="info" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={container}>
            <Box sx={containerTitle}>
              <Typography variant="h5" color="primary" sx={titleStyle}>
                Ficou com alguma dúvida sobre porque solicitamos o CPF?
              </Typography>
              <IconButton color="default" aria-label="upload picture" component="span" onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={6} xs={12}>
                <Card sx={cardContainer}>
                  <CardContent>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>{quote.name.split(' ')[0]},</span> o CPF é exigido na cotação
                      de seguros principalmente para identificar o cliente de forma única, avaliar o risco de segurá-lo,
                      prevenir fraudes e cumprir regulamentações legais. Essa informação é essencial para garantir
                      transações seguras e justas para ambas as partes envolvidas. Mas fique tranquilo, todos os dados
                      são armazenados de forma segura, respeitando todas as normativas da LGPD.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
