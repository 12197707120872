import Signup from '../components/modals/signup/en.json';
import Forgot from '../components/modals/forgot/en.json';
import ScheduleDetails from '../components/modals/scheduleDetails/en.json';

import Calendar from '../components/shared/calendar/en.json';
import Cep from '../components/shared/cep/en.json';
import Menu from '../components/shared/menu/en.json';
import Mobile from '../components/shared/mobile/en.json';
import Pagination from '../components/shared/pagination/en.json';
import Select from '../components/shared/select/en.json';
import UploadImage from '../components/shared/uploadImage/en.json';

export default {
  en: {
    translations: {
      ...Menu,
      ...Calendar,
      ...Pagination,
      ...Cep,
      ...UploadImage
    }
  }
};
