import TokenService from '~/services/token';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import useStore from '~/store';
import { storeStateLoading } from '~/store/slices/loading';
// import mock from './useMock'

const api = axios.create({
  baseURL: process.env.REACT_APP_ARIDE_API_URL
});

// mock(api)

api.interceptors.request.use(
  (config) => {
    const authData = TokenService.getClientCredencialtoken();
    if (config && authData) {
      config.headers = {
        Authorization: 'Bearer ' + authData
      };
    }
    storeStateLoading.startLoading();
    return config;
  },
  (error) => {
    storeStateLoading.stopLoading();
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (config) => {
    storeStateLoading.stopLoading();
    return config;
  },
  (error) => {
    storeStateLoading.stopLoading();
    return Promise.reject(error);
  }
);
export default api;
