import { Message } from '../types';
import { Box, Typography } from '@mui/material';
import { containerMessage, messageStyle, messageAfter } from './styles';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  bold?: boolean;
  QuestionComponent?: Message['questionComponent'];
  Component?: Message['Component'];
}

export const MessageBubble = ({ content, QuestionComponent, bold }: Props) => {
  return (
    <>
      <Box sx={() => containerMessage}>
        <Box sx={messageAfter} />
        <Typography variant="body1" sx={() => messageStyle(bold || false)}>
          {content} {QuestionComponent && <QuestionComponent />}
        </Typography>
      </Box>
    </>
  );
};
