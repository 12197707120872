import { Card, Grid, CardContent, Typography, Box, Divider, Button, Checkbox, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { FinancialData } from '../../interfaces/financial-data';
import { priceFormatter } from '~/utils/priceFormatter';
import { purpleText } from '../../styles';

interface ContractItem {
  product_id: number;
  product_name: string;
  product_description: string;
  product_price: number;
  cover: {
    cover_name: string;
    cover_description: string;
    cover_icon: string;
  }[];
}

interface Option {
  isDetailsOpen: boolean;
}

type ContractItemWithOption = ContractItem & Option;

interface Props {
  contract_item: ContractItem[] | undefined;
  products: number[];
  handleProductChange: (productId: number) => Promise<void>;
  isLoading: boolean;
  financialData: FinancialData | undefined;
}

export function ContractCard({ contract_item, products, handleProductChange, isLoading, financialData }: Props) {
  if (!contract_item) return null;

  const [contractItems, setContractItems] = useState([] as ContractItemWithOption[]);

  function toggleDetails(index: number) {
    setContractItems((prev) => {
      const newState = [...prev];
      newState[index].isDetailsOpen = !newState[index].isDetailsOpen;

      return newState;
    });
  }

  function mergeOptions(contractItems: ContractItem[]): ContractItemWithOption[] {
    return contractItems.map((contractItem) => ({
      ...contractItem,
      isDetailsOpen: false
    }));
  }

  useEffect(() => {
    setContractItems(mergeOptions(contract_item));
  }, [contract_item]);

  return (
    <Card
      sx={{
        margin: { xs: '2rem 0.5rem', sm: '2rem 1rem', md: '2rem' },
        borderRadius: 2
      }}
    >
      <CardContent sx={{ padding: { xs: '1rem', sm: '1rem 1.5rem' } }}>
        <Typography variant="subtitle1" sx={{ paddingBottom: 1, marginLeft: 4 }}>
          Detalhes da contratação
        </Typography>
        <Divider />
        {contractItems.map((contractItem, index) => {
          return (
            <Box
              key={contractItem.product_id}
              sx={{
                marginY: 2,
                borderRadius: 5,
                border: '2px solid #a547ff',
                padding: 1,
                background: '#1e1e1e'
              }}
            >
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={12} sm={6}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Checkbox
                      checked={products.includes(contractItem.product_id)}
                      onChange={() => handleProductChange(contractItem.product_id)}
                      color="success"
                    />
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="primary"
                      sx={{
                        fontSize: { xs: '1rem', sm: '1.25rem' }
                      }}
                    >
                      {contractItem.product_name}
                    </Typography>
                  </Box>
                  <Button
                    variant="text"
                    sx={{
                      paddingY: 0,
                      color: '#54c556',
                      fontSize: '0.9rem',
                      fontWeight: 700
                    }}
                    onClick={() => toggleDetails(index)}
                  >
                    {contractItem.isDetailsOpen ? '-' : '+'} detalhes
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="primary"
                    align="right"
                    sx={{
                      fontSize: { xs: '1rem', sm: '1.25rem' }
                    }}
                  >
                    + 12x R${priceFormatter.format(contractItem.product_price)}
                  </Typography>
                </Grid>
              </Grid>
              {contractItem.isDetailsOpen && <Typography>{contractItem.product_description}</Typography>}
            </Box>
          );
        })}

        <Divider />
        <Box
          sx={{
            display: 'grid',
            width: '100%',
            placeItems: 'center'
          }}
        >
          <Typography variant="h4" align="center" sx={{ ...purpleText, fontSize: '1.5rem', margin: 1 }}>
            Resumo da assinatura
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="60%">
            <Typography variant="subtitle2">Encargos financeiros</Typography>
            <Typography variant="subtitle2">
              {isLoading ? (
                <CircularProgress size={16} />
              ) : (
                `R$${priceFormatter.format(financialData?.financial_charges ?? 0)}`
              )}
            </Typography>
          </Box>
          <Divider sx={{ width: '70%', margin: '.25rem' }} />
          <Box display="flex" alignItems="center" justifyContent="space-between" width="60%">
            <Typography variant="subtitle2" sx={{ fontWeight: 700, fontSize: '1.15rem' }}>
              Valor total (12x)
            </Typography>
            <Typography variant="subtitle2" sx={{ ...purpleText, fontSize: '1.15rem' }}>
              {isLoading ? <CircularProgress size={20} /> : `R$${priceFormatter.format(financialData?.total ?? 0)}`}
            </Typography>
          </Box>
          <Typography variant="subtitle2" sx={{ fontSize: '.8rem', fontWeight: '400' }}>
            (Valor a ser pago mensalmente no cartão de crédito)
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
