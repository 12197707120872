import React, { useCallback } from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineExclamationCircle, AiOutlineAlert } from 'react-icons/ai';
import ErrorIcon from '@mui/icons-material/Error';
import useStore from '~/store';
import { ToastProps } from '~/store/slices/toast/types';
import Toast from '../Toast';
import { ToastContainerProps } from './types';
import * as S from './styles';
import { IoAlertCircle, IoCheckmarkCircle, IoWarning } from 'react-icons/io5';

const ToastContainer: React.FC<ToastContainerProps> = ({ position = 'bottom-center', autoClose = 5000 }) => {
  const { toasts, removeToast } = useStore((store) => ({
    toasts: store.toasts as ToastProps[],
    removeToast: store.removeToast
  }));

  const toastTypes = {
    success: {
      color: '#53c41a',
      icon: IoCheckmarkCircle
    },
    error: {
      color: '#eb5662',
      icon: IoAlertCircle
    },
    warning: {
      color: '#faad34',
      icon: IoWarning
    }
  };

  const handleDismissToast = useCallback(
    (id: string) => {
      removeToast(id);
    },
    [removeToast]
  );

  return (
    <S.ListContainer position={position}>
      {toasts.map((toast) => (
        <Toast
          title={toast.title}
          message={toast.message}
          icon={toastTypes[toast.type].icon}
          color={toastTypes[toast.type].color}
          dismiss={() => handleDismissToast(toast.id)}
          key={toast.id}
          autoClose={autoClose}
        />
      ))}
    </S.ListContainer>
  );
};

export default ToastContainer;
