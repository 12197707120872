import Colors from '~/styles/colors';
export const labelStyle = {
  color: Colors.primary?.main,
  fontWeight: 'bold',
  fontSize: 24,
  marginBottom: 2,
  marginLeft: -1
};
export const container = {
  marginTop: 2,
  width: '100%'
};
export const inputStyle = {
  marginTop: 2.5,
  borderRadius: 3
};
export const helperStyle = {
  color: '#E5636D',
  paddingTop: 0.5
};
