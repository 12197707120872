import Colors from '~/styles/colors';
export const labelStyle = {
  color: Colors.primary?.main,
  fontWeight: 'bold',
  fontSize: 24
};
export const container = {
  marginTop: 2,
  width: '100%'
};
export const helperStyle = {
  color: '#E5636D',
  paddingTop: 0.5
};
