import Colors from '~/styles/colors';
export const labelStyle = {
  color: Colors.primary?.main,
  fontWeight: 'bold',
  fontSize: 18,
  marginLeft: 1
};
export const inputText = {
  textAlign: 'center',
  width: '100%',
  marginTop: 1
};
export const icon = {
  textAlign: 'center',
  marginTop: 1
};
export const container = {
  width: '100%',
  padding: 1
};
export const input = (existFile: boolean, error: boolean) => ({
  cursor: 'pointer',
  width: '100%',
  backgroundColor: '#1E1E1E',
  borderColor: error ? 'red' : existFile ? '#54C556' : '#323232',
  borderWidth: 2,
  borderStyle: 'dashed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 1
});
