import {
  ContractRequestParams,
  ContractPostCustomer,
  ContractPostAddress,
  ContractPostEquipment,
  Manufacturer,
  ContractPostEmergency,
  ContractPostFiles
} from './types';
import api from '~/services/api';

class ContractService {
  async getContext(contract_id: number) {
    const {
      data: { data }
    } = await api.get(`/contracts/${contract_id}/context`);
    return data;
  }
  async getContractById(contract_id: number) {
    const {
      data: { data }
    } = await api.get(`/Contracts/${contract_id}`);
    return data;
  }
  async postCustomer(contract_id: string, body: ContractPostCustomer) {
    const { data } = await api.put(`/Contracts/${contract_id}/customer`, body);
    return data;
  }
  async postInsuredItemAddress(contract_id: string, body: ContractPostAddress) {
    const { data } = await api.post(`/contracts/${contract_id}/insured_items/address`, body);
    return data;
  }
  async putInsuredItem(contract_id: number, body: ContractPostEquipment) {
    const { data } = await api.put(`/contracts/${contract_id}/insured_items/`, body);
    return data;
  }
  async getManufacturers() {
    const {
      data: { data }
    } = await api.get('/manufacturers');
    return data?.length > 0
      ? data
      : [
        {
          id: '1',
          name: 'Sem Marca'
        }
      ];
  }
  async postEmergency(contract_id: number, body: ContractPostEmergency) {
    const { data } = await api.post(`/contracts/${contract_id}/emergency_data`, body);
    return data;
  }
  async getDownload(contract_id: string) {
    const { data } = await api.get(`/Contracts/${contract_id}/download`);
    return data;
  }
  async postFiles(contract_id: string, body: ContractPostFiles) {
    const { data } = await api.post(`/contracts/${contract_id}/insured_items/documents`, body);
    return data;
  }
  async getVoucher(contract_id: number) {
    const { data } = await api.get(`/Contracts/${contract_id}/contract_items/voucher`);
    return data;
  }
  async getStepPlans(contract_id: string) {
    const { data } = await api.get(`/Plans/${contract_id}/flow`);

    return data;
  }
}

export default new ContractService();
