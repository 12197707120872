import { useState } from 'react';
import SidebarMesages from '~/components/PersonalCoverage/SidebarMesages';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as styles from './styles';
// import IOSSwitch from '~/components/IOSSwitch'
import Input from '~/components/Input';
import InputSelect from '~/components/InputSelect';
import IOSSwitch from '~/components/IOSSwitch';
import { Message } from '~/components/PersonalCoverage/SidebarMesages/types';
import { useNavigate } from 'react-router-dom';
import schema from './validation';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStore from '~/store';
import { ContractPostEmergency } from '~/services/modules/contract/types';

const itemsQuerstionSecurity = [
  {
    value: 1,
    label: 'Nome do primeiro cachorro'
  },
  {
    value: 2,
    label: 'time que torce'
  },
  {
    value: 3,
    label: 'Nome da primeira professora'
  }
];

export default function HelpZipcodePage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [callThePolice, setcallThePolice] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm<ContractPostEmergency>({
    resolver: yupResolver(schema)
  });
  const { contractAsyncPostEmergency, contract_id } = useStore((store) => ({
    contractAsyncPostEmergency: store.contractAsyncPostEmergency,
    contract_id: store.contract_id
  }));
  const onSubmit: SubmitHandler<ContractPostEmergency> = (data) => {
    setLoading(true);
    if (contract_id) {
      contractAsyncPostEmergency(contract_id, {
        ...data,
        security_question: +data.security_question
      })
        .then((res) => {
          setLoading(false);
          navigate('/contract/download');
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  return (
    <Container maxWidth="lg" sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent>
              <Box sx={styles.headerContainer}>
                <Typography variant="h6" align="center">
                  Dados para emergência
                </Typography>
              </Box>
              <Divider variant="middle" />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={styles.containerForm}>
                  <Typography variant="h6" fontWeight="bold">
                    5 - Dados para emergência
                  </Typography>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Contato (em caso de emergência)"
                          placeholder="Nome Completo"
                          error={errors?.name ? true : false}
                          isDirty={dirtyFields.name}
                          helperText={errors?.name?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="mobile_phone"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Celular"
                          placeholder="(XX) X XXXX-XXXX"
                          mask="phone"
                          error={errors?.mobile_phone ? true : false}
                          isDirty={dirtyFields.mobile_phone}
                          helperText={errors?.mobile_phone?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <FormGroup sx={styles.switchStyle}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={() => {
                            setcallThePolice(!callThePolice);
                            setValue('call_the_police', !callThePolice);
                          }}
                        />
                      }
                      label="Dependendo da localização da ocorrência, podemos acionar a polícia para assistência."
                    />
                  </FormGroup>
                  <Typography variant="h6" fontWeight="bold">
                    6 - Dados para atendimento
                  </Typography>
                  <InputSelect
                    name="security_question"
                    label="Pergunta de segurança"
                    placeholder="Escolha uma pergunta"
                    items={itemsQuerstionSecurity}
                    setValue={(value) => {
                      setValue('security_question', +value as number);
                    }}
                  />
                  <Controller
                    name="security_answer"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Resposta da pergunta"
                          placeholder="Descreva o modelo"
                          error={errors?.security_answer ? true : false}
                          isDirty={dirtyFields.security_answer}
                          helperText={errors?.security_answer?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                </Box>
                <Box sx={styles.containerButton}>
                  <LoadingButton variant="contained" loading={loading} type="submit">
                    Finalizar preenchimento
                  </LoadingButton>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            display: {
              xs: 'none',
              lg: 'block'
            }
          }}
        >
          <Card>
            <CardContent>
              <SidebarMesages showProduct={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
