import { Container, Card, Grid, CardContent, Typography, InputAdornment, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { equipmentSchema } from '../../validations';
import { purpleText, internalCard, normalText, inputStyle } from '../../styles';
import CurrencyInput from '../currency-input';
import useDebounce from '~/utils/useDebounce';
import { useEffect } from 'react';

interface UpdateQuoteParams {
  value_of_acessories: number;
  equipament_price: number;
}

interface Props {
  insured_item:
    | {
        equipment_type: string;
        manufacturer: string;
        year_of_manufacture: number;
        model: string;
        serial_number: string;
        equipment_value: number;
        value_of_accessories: number | null;
      }[]
    | undefined;
  updateQuoteData: ({ value_of_acessories, equipament_price }: UpdateQuoteParams) => Promise<void>;
}

type EquipmentInputs = {
  equipment_value: string;
  value_of_accessories: string;
};

export function EquipmentCard({ insured_item: insuredItems, updateQuoteData }: Props) {
  if (!insuredItems) return null;

  const [insured_item] = insuredItems;

  const { control, setValue, watch } = useForm<EquipmentInputs>({
    defaultValues: {
      equipment_value: String(insured_item.equipment_value) || '0',
      value_of_accessories: String(insured_item.value_of_accessories) || '0'
    },
    resolver: yupResolver(equipmentSchema)
  });

  const equipmentValueState = watch('equipment_value');
  const accessoriesValueState = watch('value_of_accessories');

  const debouncedEquipmentValue = useDebounce<string>(equipmentValueState, 1000);
  const debouncedAccessoriesValue = useDebounce<string>(accessoriesValueState, 1000);

  const updateQuoteBasedOnNewValues = async () => {
    if (
      insured_item &&
      (debouncedEquipmentValue != String(insured_item.equipment_value) ||
        debouncedAccessoriesValue != String(insured_item.value_of_accessories))
    ) {
      await updateQuoteData({
        equipament_price: Number(debouncedEquipmentValue),
        value_of_acessories: Number(debouncedAccessoriesValue)
      });
    }
  };

  useEffect(() => {
    updateQuoteBasedOnNewValues();
  }, [debouncedEquipmentValue, debouncedAccessoriesValue]);

  return (
    <Card
      sx={{
        margin: { xs: '2rem 0.5rem', sm: '2rem 1rem', md: '2rem 2rem' },
        marginTop: 0
      }}
    >
      <CardContent sx={internalCard}>
        <Typography variant="h6" align="left" sx={{ paddingBottom: 1 }}>
          1- Dados do equipamento
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={3}>
            <Typography variant="subtitle1" sx={purpleText}>
              Tipo:
            </Typography>
            <Typography variant="subtitle2" sx={normalText}>
              {insured_item.equipment_type}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2.5}>
            <Typography variant="subtitle1" sx={purpleText}>
              Marca:
            </Typography>
            <Typography variant="subtitle2" sx={normalText}>
              {insured_item.manufacturer}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2.5}>
            <Typography variant="subtitle1" sx={purpleText}>
              Modelo:
            </Typography>
            <Typography variant="subtitle2" sx={normalText}>
              {insured_item.model}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="subtitle1" sx={purpleText}>
              Ano de fabricação:
            </Typography>
            <Typography variant="subtitle2" sx={normalText}>
              {insured_item.year_of_manufacture}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ paddingTop: 2 }} rowSpacing={2} columnSpacing={8}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" sx={purpleText}>
              Valor do equipamento
            </Typography>
            <Controller
              name="equipment_value"
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    value={field.value}
                    onChange={(ev) => setValue('equipment_value', ev.target.value)}
                    name={field.name}
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">R$</InputAdornment>
                        </>
                      )
                    }}
                    fullWidth
                    variant="outlined"
                    helperText="*Valor mínimo de R$ 1.000"
                    sx={inputStyle}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" sx={purpleText}>
              Valor dos acessórios
            </Typography>
            <Controller
              name="value_of_accessories"
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    value={field.value}
                    onChange={(ev) => setValue('value_of_accessories', ev.target.value)}
                    name={field.name}
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">R$</InputAdornment>
                        </>
                      )
                    }}
                    fullWidth
                    variant="outlined"
                    sx={inputStyle}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
