import React, { useEffect } from 'react';
import { IconType } from 'react-icons';

import * as S from './styles';

interface ToastProps {
  icon: IconType;
  title: string;
  message: string;
  color: string;
  autoClose: number;
  dismiss: () => void;
}

const Toast: React.FC<ToastProps> = ({ icon: IconComponent, title, message, color, autoClose, dismiss }) => {
  useEffect(() => {
    setTimeout(() => {
      dismiss();
    }, autoClose);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container color={color} onClick={dismiss}>
      <S.Body>
        <S.Header>
          <S.IconWrapper>{<IconComponent size={30} color={color} />}</S.IconWrapper>
          <S.Title color={color}>{title}</S.Title>
        </S.Header>
        <S.Message>{message}</S.Message>
      </S.Body>
    </S.Container>
  );
};

export default Toast;
