import { Props } from './types';
import * as styles from './styles';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useNavigate } from 'react-router-dom';

export default function HeaderComponent({ title, subTitle, divider, goBackTo }: Props) {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid sx={styles.headerContainer} item xs={12} md={1}>
        {goBackTo && (
          <>
            <IconButton onClick={() => navigate(goBackTo)} sx={styles.iconGoback}>
              <ArrowBackIosNewRoundedIcon />
            </IconButton>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={11} sx={styles.headerContainer}>
        <Box>
          <Typography sx={{ fontSize: { xs: 20, sm: 24 } }} variant="h5" component="h1" fontWeight="bold">
            {title}
          </Typography>
          {subTitle && (
            <>
              <Typography sx={{ fontSize: { xs: 20, sm: 24 } }} variant="h5" component="h1" fontWeight="bold">
                {subTitle}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
