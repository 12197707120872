export const headerContainer = {
  padding: 2,
  alignItems: 'flex-start'
};
export const container = {
  marginTop: 4,
  marginBottom: 4
};
export const containerButton = {
  marginBottom: 2,
  marginRight: { xs: 0, sm: 2 },
  display: 'flex',
  justifyContent: { xs: 'center', sm: 'flex-end' },
  gap: 2
};
export const descritionTitle = {
  fontSize: 16,
  textAlign: 'center',
  marginBottom: 1
};
export const containerForm = {
  width: '100%',
  paddingX: { xs: 0, sm: 2 },
  paddingY: 2
};
export const switchStyle = {
  marginTop: 1
};
