import { forwardRef, ChangeEvent } from 'react';
import { Message } from '../types';
import { Box, TextField, InputAdornment, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import NumberFormat from 'react-number-format';
import { containerMessage, inputStyle, container, messageAfter } from './styles';
import useStore from '~/store';
import { YearOfManufacture } from '../YearOfManufacture';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  questionComponent?: Message['questionComponent'];
  Component?: Message['Component'];
}
interface Event {
  target: {
    name: string;
    value: string;
  };
}
interface CustomProps {
  onChange: (event: Event) => void;
  name: string;
}
interface NumberFormatProps {
  onChange: (event: Event) => void;
  name: string;
}

const NumberFormatCustom = forwardRef<NumberFormatProps, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalScale={2}
      allowNegative={false}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      fixedDecimalScale
      prefix=""
    />
  );
});

export const EquipmentValue = ({ content, questionComponent }: Props) => {
  const { setEquipmentValue, setHasInvoice, setIsNewEquipment, quote } = useStore((store) => ({
    quote: store.quote,
    setEquipmentValue: store.setEquipmentValue,
    setHasInvoice: store.setHasInvoice,
    setIsNewEquipment: store.setIsNewEquipment
  }));
  const { equipmentValue, hasInvoice, isNewEquipment } = quote;
  const handleChangeHasInvoice = (event: ChangeEvent<HTMLInputElement>) => {
    setHasInvoice(event.target.checked);
  };
  const handleChangeIsNewEquipment = (event: ChangeEvent<HTMLInputElement>) => {
    setIsNewEquipment(event.target.checked);
  };

  const handleChangeEquipmentValue = (event: ChangeEvent<HTMLInputElement>) => {
    const equipmentValue = Number(event.target.value);
    setEquipmentValue(equipmentValue);
  };

  return (
    <Box sx={container}>
      <Box sx={() => containerMessage()}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasInvoice}
                onChange={handleChangeHasInvoice}
                inputProps={{
                  'aria-label': 'Eu tenho a nota fiscal.'
                }}
                color="success"
              />
            }
            label="Eu tenho a nota fiscal."
          />
        </FormGroup>
        <Box sx={messageAfter} />
      </Box>
      <Box sx={() => containerMessage()}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isNewEquipment}
                onChange={handleChangeIsNewEquipment}
                inputProps={{
                  'aria-label': 'É um equipamento novo (máximo 30 dias da data da compra para bikes e bike elétrica e 60 dias da data da compra para autopropelido, ciclomotores e moto elétrica).'
                }}
                color="success"
              />
            }
            label="É um equipamento novo (máximo 30 dias da data da compra)."
          />
        </FormGroup>
        <Box sx={messageAfter} />
      </Box>
      <YearOfManufacture />
      <Box sx={() => containerMessage()}>
        <TextField
          value={equipmentValue ? equipmentValue.toString() : ''}
          onChange={handleChangeEquipmentValue}
          name="equipmentValue"
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            startAdornment: (
              <>
                <InputAdornment position="start">R$</InputAdornment>
              </>
            )
          }}
          variant="outlined"
          helperText="*Valor mínimo de R$ 1.000"
          sx={inputStyle}
        />
        <Box sx={messageAfter} />
      </Box>
    </Box>
  );
};
