import { Message } from '../types';
import { Box, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  containerMessage,
  formControlContainer,
  container,
  messageAfter,
  selectStyle,
  helperTextStyle
} from './styles';
import useStore from '~/store';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  questionComponent?: Message['questionComponent'];
  Component?: Message['Component'];
}

export const YearOfManufacture = ({ content }: Props) => {
  const { setYearOfManufacture, quote } = useStore((store) => ({
    quote: store.quote,
    setYearOfManufacture: store.setYearOfManufacture
  }));

  const { yearOfManufacture } = quote;

  const handleChangeYearOfManufacture = (event: SelectChangeEvent) => {
    const yearOfManufacture = Number(event.target.value);
    setYearOfManufacture(yearOfManufacture);
  };

  const currentYear = new Date().getFullYear();

  const items = Array.from({ length: 11 }, (_, index) => {
    const value = currentYear - index;

    return {
      value,
      label: value
    };
  });

  return (
    <Box sx={() => containerMessage()}>
      <FormControl variant="outlined" sx={formControlContainer}>
        <Select
          name="yearOfManufacture"
          displayEmpty
          value={yearOfManufacture ? yearOfManufacture.toString() : ''}
          onChange={handleChangeYearOfManufacture}
          sx={selectStyle}
          renderValue={(selected) => {
            if (selected === '') {
              return <em>Informe o ano de fabricação</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Informe o ano de fabricação' }}
        >
          <MenuItem disabled value="">
            <em>Informe o ano de fabricação</em>
          </MenuItem>
          {items.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={helperTextStyle}>
          *Aceitamos apenas equipamentos com no máximo 10 anos de uso.
        </FormHelperText>
      </FormControl>
      <Box sx={messageAfter} />
    </Box>
  );
};
