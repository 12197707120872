import { useWatch, Control } from 'react-hook-form';

import { Inputs } from './index';
import CardCredit from '~/components/CardCredit';
import { Focused } from 'react-credit-cards-2';

function CardComponent({ control, focused }: { control: Control<Inputs>; focused: Focused }) {
  const number = useWatch({
    control,
    name: 'number',
    defaultValue: ''
  });
  const cvv = useWatch({
    control,
    name: 'cvv',
    defaultValue: ''
  });
  const expiry = useWatch({
    control,
    name: 'expiry',
    defaultValue: ''
  });
  const name = useWatch({
    control,
    name: 'name',
    defaultValue: ''
  });

  return (
    <>
      <CardCredit number={number} cvc={cvv} expiry={expiry} focused={focused} name={name} />
    </>
  );
}

export default CardComponent;
