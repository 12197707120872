import { useEffect, useState } from 'react';
import { Modal, Box, Typography, IconButton, Grid, Backdrop, Fade } from '@mui/material';
import HelpRounded from '@mui/icons-material/HelpRounded';
import Close from '@mui/icons-material/Close';
import CardCoverage from './CardCoverage';
import { container, containerTitle, titleStyle, descriontionStyle, containerCards, iconStyle } from './styles';

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpRounded sx={iconStyle} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={container}>
            <Box sx={containerTitle}>
              <Typography variant="h5" color="primary" sx={titleStyle}>
                Coberturas
              </Typography>
              <IconButton color="default" aria-label="upload picture" component="span" onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Box sx={containerCards}>
              <Grid container spacing={2} sx={containerCards}>
                <Grid item md={4} xs={12}>
                  <CardCoverage />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
