export const containerMessage = (isError: boolean) => ({
  backgroundColor: '#1E1E1E',
  right: 0,
  border: `2px solid ${isError ? '#E5636D' : '#A547FF'}`,
  borderRadius: '16px 16px 0px 16px',
  color: '#FFFFFF',
  padding: 2,
  marginTop: 1,
  maxWidth: '100%'
});
export const containerMessageBubble = {
  backgroundColor: '#1E1E1E',
  right: 0,
  border: '1px solid #323232',
  borderRadius: '16px 16px 16px 0px',
  color: '#FFFFFF',
  padding: 2,
  marginTop: 1,
  maxWidth: '100%'
};
export const containerInput = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
};
export const iconStyle = {
  margin: 1
};
export const linkStyle = {
  color: '#03DAC5',
  fontWeight: '500',
  textDecoration: 'none'
};
export const inputStyle = () => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 3
  }
});
