import { Card, Grid, CardContent, Typography, Box } from '@mui/material';
import { Control, Controller, FieldErrors, useForm, useWatch } from 'react-hook-form';
import { Focused } from 'react-credit-cards-2';
import { internalCard } from '../../styles';
import { Input } from '~/components';
import CardCredit from '~/components/CardCredit';
import { useState } from 'react';
import { useMediaQuery as useMuiMediaQuery } from '@mui/material';

export interface Props {
  control: Control<CardData, object>;
  errors: FieldErrors<CardData>;
}
export interface CardData {
  card_number: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_cvv: string;
}

export function PaymentCard({ control, errors }: Props) {
  const [focusedAt, setFocusedAt] = useState<Focused>('number');

  const showCreditCard = useMuiMediaQuery('(min-width: 1200px)');

  const number = useWatch({
    control,
    name: 'card_number',
    defaultValue: ''
  });

  const name = useWatch({
    control,
    name: 'card_holder_name',
    defaultValue: ''
  });

  const expiry = useWatch({
    control,
    name: 'card_expiration_date',
    defaultValue: ''
  });

  const cvv = useWatch({
    control,
    name: 'card_cvv',
    defaultValue: ''
  });

  return (
    <Card
      sx={{
        margin: { xs: '2rem 0.5rem', sm: '2rem 1rem', md: '2rem 2rem' }
      }}
      id="payment-card"
    >
      <CardContent sx={internalCard}>
        <Typography variant="h6" align="left" sx={{ paddingBottom: 1 }}>
          3- Dados para o pagamento
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  name="card_number"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        label="Número do cartão"
                        placeholder="XXXX XXXX XXXX XXXX"
                        mask="cardNumber"
                        error={!!errors?.card_number}
                        helperText={errors?.card_number?.message}
                        {...field}
                        onFocus={() => setFocusedAt('number')}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="card_holder_name"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        label="Nome no cartão"
                        placeholder="João da Silva"
                        error={!!errors?.card_holder_name}
                        helperText={errors?.card_holder_name?.message}
                        {...field}
                        onFocus={() => setFocusedAt('name')}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="card_expiration_date"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Expiração"
                          placeholder="01/31"
                          error={!!errors?.card_expiration_date}
                          helperText={errors?.card_expiration_date?.message}
                          {...field}
                          mask="expiredDate"
                          onFocus={() => setFocusedAt('expiry')}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="card_cvv"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="CVV"
                          placeholder="123"
                          error={!!errors?.card_cvv}
                          helperText={errors?.card_cvv?.message}
                          {...field}
                          mask="cvv"
                          onFocus={() => setFocusedAt('cvc')}
                        />
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showCreditCard && (
            <Grid item xs={6} sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                <CardCredit number={number} cvc={cvv} expiry={expiry} focused={focusedAt} name={name} />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
