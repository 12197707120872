import { Box } from '@mui/material';
import { MessageBubble } from './Message';
import { TypesOfEquipment } from './TypesOfEquipment';
import { InputZipCode } from './InputZipCode';
import { EquipmentValue } from './EquipmentValue';
import { InputName } from './InputName';
import { InputEmail } from './InputEmail';
import { Message } from './types';
import { MessageEquipment } from './MessageEquipment';
import { YearOfManufacture } from './YearOfManufacture';
import { AccessoriesValue } from './AccessoriesValue';
import { EquipmentMaterial } from './EquipmentMaterial';
import { ParticipatesInCompetitions } from './ParticipatesInCompetitions';
import { InternationalCoverage } from './InternationalCoverage';
import { WhichContinents } from './WhichContinents';
import { motion, AnimatePresence } from 'framer-motion';
import { messageBubbleContainer, container, containerMessage, NoIcon } from './styles';
import { Ari } from '~/assets/icons';
import { InputCpf } from './InputCpf';
import { Gender } from './Gender';
import { EquipmentConditions } from './EquipmentConditions';
import { PlaceStore } from './PlaceStore';

interface Props {
  messages: Message[];
  handleValidateInputs: (key: 'email' | 'name' | 'cpf', isValid: boolean) => void;
  steps?: number[];
}

export const MessageList = ({ messages, handleValidateInputs, steps }: Props) => {
  const switchComponent = (message: Message) => {
    switch (message.type) {
      case 'equipment':
        return <TypesOfEquipment {...message} steps={steps} />;
      case 'zipcode':
        return <InputZipCode {...message} />;
      case 'equipmentValue':
        return <EquipmentValue {...message} />;
      case 'equipmentView':
        return <MessageEquipment {...message} />;
      case 'equipmentMaterial':
        return <EquipmentMaterial {...message} />;
      case 'equipmentConditions':
        return <EquipmentConditions {...message} />;
      case 'yearOfManufacture':
        return <YearOfManufacture {...message} />;
      case 'accessoriesValue':
        return <AccessoriesValue {...message} />;
      case 'participatesInCompetitions':
        return <ParticipatesInCompetitions {...message} />;
      case 'internationalCoverage':
        return <InternationalCoverage {...message} />;
      case 'whichContinents':
        return <WhichContinents {...message} />;
      case 'placeStore':
        return <PlaceStore {...message} />;
      case 'inputEmail':
        return <InputEmail handleValidateInputs={handleValidateInputs} {...message} />;
      case 'inputCpf':
        return <InputCpf handleValidateInputs={handleValidateInputs} {...message} />;
      case 'selectGender':
        return <Gender {...message} />;
      case 'inputName':
        return <InputName handleValidateInputs={handleValidateInputs} {...message} />;
      default:
        return <MessageBubble key={message.id} {...message} />;
    }
  };
  return (
    <AnimatePresence initial={false}>
      <Box sx={container}>
        {messages.map((message, index) => (
          <Box key={message.id} sx={containerMessage(message?.author || 'us')}>
            {message.author === 'us' && index === 0 && <Ari />}
            {message.author === 'us' && index > 0 && <Box sx={NoIcon(96)} />}
            <motion.div
              style={messageBubbleContainer(message?.author || 'us')}
              // positionTransition
              className={`message-bubble-container ${message.author}`}
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, transition: { duration: 0.15 } }}
            >
              {switchComponent(message)}
            </motion.div>
          </Box>
        ))}
      </Box>
    </AnimatePresence>
  );
};
