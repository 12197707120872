import { SvgIcon } from '@mui/material';

function EBikeIcon() {
  return (
    <SvgIcon
      width="48px"
      height="32px"
      viewBox="0 0 48 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fontSize: '3rem' }}
    >
      <path
        d="M38 12H36.36L32.96 2.64C32.6785 1.86821 32.1671 1.2013 31.4948 0.729232C30.8225 0.257164 30.0215 0.00264325 29.2 0H24V4H29.2L32.12 12H22.5L21.78 10H24V6H14V10H17.5L21.14 20H19.8C18.92 15.54 15.18 12.24 10.5 12.02C4.9 11.74 0 16.4 0 22C0 27.6 4.4 32 10 32C14.92 32 18.9 28.62 19.8 24H28.2C29.08 28.46 32.82 31.76 37.5 31.98C43.1 32.24 48 27.6 48 21.98C48 16.4 43.6 12 38 12ZM15.64 24C14.84 26.34 12.66 28 10 28C6.64 28 4 25.36 4 22C4 18.64 6.64 16 10 16C12.66 16 14.84 17.66 15.64 20H10V24H15.64ZM28.2 20H25.4L23.94 16H30C29.12 17.16 28.48 18.5 28.2 20ZM38 28C34.64 28 32 25.36 32 22C32 20.14 32.82 18.54 34.1 17.44L36.02 22.72L39.78 21.36L37.84 16.02C37.88 16.02 37.94 16 38 16C41.36 16 44 18.64 44 22C44 25.36 41.36 28 38 28ZM22 38H14L26 44V40H34L22 34V38Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default EBikeIcon;
