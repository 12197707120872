import { useEffect, useState } from 'react';
import SidebarMesages from '~/components/PersonalCoverage/SidebarMesages';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as styles from './styles';
import Input from '~/components/Input';
import InputDate from '~/components/InputDate';
import { useNavigate } from 'react-router-dom';
import schema from './validation';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStore, { storeState } from '~/store';
import FormAdditionalDriver, { Additional_drivers } from './FormAdditionalDriver';

type Inputs = {
  name: string;
  email: string;
  mobile_phone: string;
  document: string;
  document_type?: string;
  birthDate: string;
  password: string;
  confirm_password: string;
};

export default function ContractCustomerPage() {
  const navigate = useNavigate();
  const [showFormConductor, setShowFormConductor] = useState(false);
  const [loading, setLoading] = useState(false);
  const [useBuyerData, setUseBuyerData] = useState(false);
  const [additional_drivers, setAdditional_drivers] = useState<Array<Additional_drivers>>([]);

  const { contractAsyncPostCustomer, contract_id, quote, insured, cameFromEmail } = useStore((store) => ({
    contractAsyncPostCustomer: store.contractAsyncPostCustomer,
    contract_id: store.contract_id,
    quote: store.quote,
    insured: store.insured,
    cameFromEmail: store.cameFromEmail
  }));

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors, dirtyFields },
    setValue,
    reset
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      mobile_phone: '',
      document: '',
      birthDate: '',
      email: ''
    }
  });

  const handleChange = () => {
    const isShow = !showFormConductor;
    setShowFormConductor(isShow);
    if (isShow) {
      setAdditional_drivers([
        {
          name: '',
          document: '',
          document_type: 'CPF',
          birthdate: new Date(),
          degree_of_kinship: ''
        }
      ]);
    } else {
      setAdditional_drivers([]);
    }
  };
  const setDriverAdditional = (index: number, driver: Additional_drivers) => {
    setAdditional_drivers((drivers) => {
      drivers[index] = driver;
      return [...drivers];
    });
  };
  const handleMoreDriver = () => {
    setAdditional_drivers((drivers) => {
      const driver_temp = [...drivers];
      driver_temp.push({
        name: '',
        document: '',
        document_type: 'CPF',
        birthdate: new Date(),
        degree_of_kinship: ''
      });
      return driver_temp;
    });
  };

  const handleChangeUseBuyerData = () => {
    const newState = !useBuyerData;
    setUseBuyerData(newState);

    if (newState && cameFromEmail) {
      setValue('name', insured.name);
      setValue('mobile_phone', insured.mobile_phone);
      setValue('document', insured.document);
      setValue('birthDate', insured.birthdate);
      setValue('email', insured.email);
      return;
    }

    if (newState) {
      setValue('name', quote.name);
      setValue('mobile_phone', quote.phoneNumber);
      setValue('document', quote.cpf);
      setValue('birthDate', quote.birthDate);
      setValue('email', quote.email);
      return;
    }

    reset();
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (contract_id) {
      setLoading(true);
      try {
        await contractAsyncPostCustomer(contract_id, {
          name: data.name,
          birthDate: data.birthDate,
          email: data.email,
          mobile_phone: data.mobile_phone,
          document: data.document,
          document_type: 1,
          password: data.password
          // confirm_password: data.confirm_password,
          // additional_drivers
        });
        setLoading(false);
        navigate('/contract/address');
      } catch (error: any) {
        setLoading(false);
        let errorMessage =
          'Não foi possível prosseguir a sua assinatura no momento. Por favor, tente novamente mais tarde.';
        if (error.isAxiosError) {
          const notifications = error.response?.data?.notifications;
          if (notifications) errorMessage = notifications[0]?.message;
        }
        storeState.addToast({
          title: 'Error',
          message: errorMessage,
          type: 'error'
        });
      }
    } else {
      storeState.addToast({
        title: 'Não foi possível recuperar o contrato.',
        message: '',
        type: 'warning'
      });
    }
  };
  const removeDriverAdditional = (index: number) => {
    setAdditional_drivers((drivers) => {
      const driver_temp = [...drivers];
      driver_temp.splice(index, 1);
      return driver_temp;
    });
  };

  useEffect(() => {
    watch((_data, { name, type }) => {
      if (name === 'document' && type === 'change') {
        trigger('document');
      }
    });
  }, [watch]);

  return (
    <Container maxWidth="lg" sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent>
              <Box sx={styles.headerContainer}>
                <Typography variant="h6" align="center">
                  Dados para contrato
                </Typography>
              </Box>
              <Divider variant="middle" />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={styles.containerForm}>
                  <Typography sx={{ fontSize: { xs: 16, sm: 20 } }} variant="h6" fontWeight="bold">
                    1 - Informações do segurado
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      sx={{
                        marginTop: 1
                      }}
                      control={<Checkbox checked={useBuyerData} onChange={handleChangeUseBuyerData} color="success" />}
                      label={
                        <Typography component={'span'} sx={{ fontSize: { xs: 14, sm: 16 } }}>
                          Utilizar as mesmas informações do “Dados de Comprador”
                        </Typography>
                      }
                    />
                  </FormGroup>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Nome Completo"
                          placeholder="Seu Nome Completo"
                          error={errors?.name ? true : false}
                          isDirty={dirtyFields.name}
                          helperText={errors?.name?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="mobile_phone"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Celular"
                          mask="phone"
                          placeholder="(XX) X XXXX-XXXX"
                          error={errors?.mobile_phone ? true : false}
                          isDirty={dirtyFields.mobile_phone}
                          helperText={errors?.mobile_phone?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="document"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="CPF"
                          placeholder="XXX.XXX.XXX-XX"
                          mask="cpf"
                          error={errors?.document ? true : false}
                          isDirty={dirtyFields.document}
                          helperText={errors?.document?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="birthDate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputDate
                          label="Data de nascimento"
                          placeholder="XX/XX/XXXX"
                          error={errors?.birthDate ? true : false}
                          isDirty={dirtyFields.birthDate}
                          helperText={errors?.birthDate?.message}
                          defaultValue={useBuyerData ? new Date(insured?.birthdate) : undefined}
                          setField={(value) => {
                            setValue('birthDate', value);
                          }}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="E-mail"
                          placeholder="seuemail@email.com"
                          error={errors?.email ? true : false}
                          isDirty={dirtyFields.email}
                          helperText={errors?.email?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Senha (para seu acesso)"
                          placeholder="Aride@123"
                          error={errors?.password ? true : false}
                          isDirty={dirtyFields.password}
                          helperText={errors?.password?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="confirm_password"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Confirmação da senha"
                          placeholder="Aride@123"
                          error={errors?.confirm_password ? true : false}
                          isDirty={dirtyFields.confirm_password}
                          helperText={errors?.confirm_password?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  {/* <FormGroup sx={styles.switchContainer}>
                    <FormControlLabel
                      control={
                        <IOSSwitch sx={{ m: 1 }} onChange={handleChange} />
                      }
                      label="Quero incluir um condutor adicional."
                    />
                  </FormGroup> */}
                  {showFormConductor && (
                    <>
                      {additional_drivers.map((driver, index) => (
                        <span key={index}>
                          <FormAdditionalDriver
                            index={index}
                            driver={driver}
                            setDriver={setDriverAdditional}
                            removeDriver={removeDriverAdditional}
                          />
                        </span>
                      ))}
                      <Typography sx={styles.moreDriversStyles} onClick={handleMoreDriver}>
                        + Clique para adicionar mais condutor adicional
                      </Typography>
                    </>
                  )}
                </Box>
                <Box sx={styles.containerButton}>
                  <LoadingButton sx={{ fontSize: { xs: 16, sm: 20 } }} loading={loading} type="submit">
                    Salvar e sair
                  </LoadingButton>
                  <LoadingButton
                    sx={{ fontSize: { xs: 16, sm: 20 } }}
                    loading={loading}
                    variant="contained"
                    type="submit"
                  >
                    Próximo passo
                  </LoadingButton>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            display: {
              xs: 'none',
              lg: 'block'
            }
          }}
        >
          <Card>
            <CardContent>
              <SidebarMesages showProduct={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
