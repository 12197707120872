export const headerContainer = (isSelected: boolean) => ({
  padding: 1,
  backgroundColor: '#1E1E1E',
  border: `1.2px solid ${isSelected ? '#A547FF' : '#323232'}`,
  borderRadius: 4,
  width: '100%',
  marginTop: 2
});
export const checkbox = {
  marginRight: 0,
  '& .MuiSvgIcon-root': { fontSize: 28 }
};
export const container = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: { xs: 'column', sm: 'row' }
  // gap: 1
};
export const headerTitle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};
export const productName = {
  color: '#A547FF',
  fontWeight: 'bold',
  fontSize: 18
};
export const cardPrice = {
  color: '#A547FF',
  fontWeight: 'bold',
  fontSize: { xs: 16, sm: 18 }
};
export const containerIcon = (isSelected: boolean) => ({
  width: 40,
  height: 40,
  backgroundColor: isSelected ? '#A547FF15' : '#323232',
  border: `1.2px solid ${isSelected ? '#A547FF' : '#323232'}`,
  borderRadius: 20,
  marginTop: 1,
  marginBottom: 0.5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});
export const containerCoverage = {
  textAlign: 'center',
  maxWidth: 85,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};
export const cardDetailContainer = {
  // display: 'flex',
  // justifyContent: 'space-between'
};
export const textDetail = {
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: 0.7,
  marginBottom: 0.7
};
export const iconDetails = {
  marginRight: 1
};
export const moreDetails = {
  cursor: 'pointer'
};
