import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PalleteColors from './colors';
export interface ThemeProps {
  children?: any;
}
const theme = createTheme({
  spacing: (factor: number) => `${12 * factor}px`,
  palette: PalleteColors,
  typography: {
    h1: { color: '#FFF' },
    h2: { color: '#FFF' },
    h3: { color: '#FFF' },
    h4: { color: '#FFF' },
    h5: { color: '#FFF' },
    h6: { color: '#FFF' },
    body1: {
      fontFamily: 'Poppins',
      color: '#FFF',
      fontSize: 16
    },
    body2: {
      fontFamily: 'Poppins',
      color: '#FFF9',
      fontSize: 12
    },
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: 20
    },
    fontFamily: 'Poppins'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'Poppins Regular'
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 26
        },
        contained: {
          fontSize: 20
        },
        outlined: {
          fontSize: 20
        },
        text: {
          color: '#fff',
          fontSize: 20
        },
        textPrimary: {
          color: '#fff',
          fontSize: 20
        },
        textSecondary: {
          color: '#fff',
          fontSize: 20
        },
        textInherit: {
          color: '#fff',
          fontSize: 20
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          padding: 3
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          borderRadius: 16,
          borderColor: '#323232',
          borderWidth: 1.2,
          borderStyle: 'solid'
        }
      }
    }
  }
});

const Theme: React.FC<ThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>{children}</>
    </ThemeProvider>
  );
};
export default Theme;
