import React from 'react';
import { Box } from '@mui/material';
import Cards, { ReactCreditCardsProps } from 'react-credit-cards-2';
import { cardContainer } from './styles';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

const ButtonComponent: React.FC<ReactCreditCardsProps> = ({ cvc, expiry, focused = 'name', name, number }) => {
  return (
    <Box sx={cardContainer}>
      <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focused}
        name={name}
        number={number}
        locale={{ valid: 'VÁLIDO ATÉ' }}
        placeholders={{ name: 'SEU NOME AQUI' }}
      />
    </Box>
  );
};

export default ButtonComponent;
