export const containerMessage = () => ({
  backgroundColor: '#1E1E1E',
  border: '1px solid #A547FF',
  borderRadius: '16px 16px 0px 16px',
  color: '#FFFFFF',
  padding: 1,
  marginTop: 1,
  minWidth: 180,
  width: '100%'
  // transform: 'matrix(1, 0, 0, -1, 0, 0)'
});
export const boxContainer = () => ({
  width: '100%'
});
export const inputStyle = () => ({
  width: '100%',
  height: 48,
  backgroundColor: '#3B3442',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: '#A547FF',
  borderRadius: 2,
  maxWidth: 210,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: 2,
  paddingTop: 2.5,
  paddingBottom: 2.5,
  cursor: 'not-allowed'
});
