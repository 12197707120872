import TokenService from '~/services/token';
import axios from 'axios';
import { storeStateLoading } from '~/store/slices/loading';

const baseUrl = axios.create({
  baseURL: process.env.REACT_APP_ARIDE_API_BASE_URL
});


baseUrl.interceptors.request.use(
  (config) => {
    const authData = TokenService.getClientCredencialtoken();
    if (config && authData) {
      config.headers = {
        Authorization: 'Bearer ' + authData
      };
    }
    storeStateLoading.startLoading();
    return config;
  },
  (error) => {
    storeStateLoading.stopLoading();
    return Promise.reject(error);
  }
);

baseUrl.interceptors.response.use(
  (config) => {
    storeStateLoading.stopLoading();
    return config;
  },
  (error) => {
    storeStateLoading.stopLoading();
    return Promise.reject(error);
  }
);
export default baseUrl;
