import * as yup from 'yup';
import { isValidCpf } from '~/utils/isValidCpf';
export default yup
  .object({
    fullName: yup
      .string()
      .min(4, 'Insira no mínimo 4 caracteres')
      .max(64, "Insira no máximo 64 caracteres")
      .matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/, 'Insira apenas letras')
      .matches(/^\S.*\S$/, 'Insira um nome válido')
      .required()
      .test(`test-name-complete-length`, 'Digite o nome completo, este parece incompleto.', (value) => {
        if (value && value.split(' ').length > 1) return true;
        return false;
      }),
    email: yup.string().email('Email inválido').required('Email é obrigatório'),
    phone: yup
      .string()
      .min(10, 'Número inválido')
      .required('Celular é obrigatório')
      .test(`test-phone-complete-length`, 'Digite o celular completo, este parece incompleto.', (value) => {
        if (value) {
          const newValue = value.replace(/\s/g, '');
          if (newValue.length > 13) return true;
        }
        return false;
      }),
    documentNumber: yup
      .string()
      .required('CPF é obrigatório')
      .test(`test-valid-cpf`, 'Digite um cpf válido.', (value) => {
        if (value) {
          return isValidCpf(value) ? true : false;
        }
        return false;
      }),
    birthDate: yup
      .string()
      .required('Insira uma data de nascimento válida')
      .test(`test-valid-date`, 'Insira uma data de nascimento válida', (value) => {
        const cleanDate = value?.split('T')[0];

        function isUnderAge(date: Date) {
          var diff_ms = Date.now() - date.getTime();
          var age_dt = new Date(diff_ms);
          return Math.abs(age_dt.getUTCFullYear() - 1970) < 18;
        }

        if (cleanDate) {
          const year = new Date(cleanDate).getFullYear();
          const actualYear = new Date().getFullYear();
          if (year < 1900 || year > actualYear || isUnderAge(new Date(cleanDate))) return false;
        }

        return true;
      })
  })
  .required();
