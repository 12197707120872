import { MotionStyle } from 'framer-motion';

export const messageBubbleContainer = (type: string): MotionStyle => ({
  margin: 1,
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: type !== 'us' ? 'flex-end' : 'flex-start',
  alignItems: 'flex-end'
});

export const container = {
  minWidth: '100%',
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column'
};
export const containerMessage = (author: string) => ({
  minWidth: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: author === 'us' ? 'flex-start' : 'flex-end'
});
export const NoIcon = (size?: number | 96) => ({
  minWidth: size
});
