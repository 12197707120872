import * as React from 'react';
import { useRadioGroup } from '@mui/material/RadioGroup';
import { Typography, Box } from '@mui/material';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import * as styles from './styles';
interface IEQUIPMENTcustom extends FormControlLabelProps {
  description: string;
  Icon: React.FC;
  equipment?: string;
  handleChange?: (event: string | React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MyFormControlLabel({ equipment, Icon, handleChange, ...props }: IEQUIPMENTcustom) {
  const radioGroup = useRadioGroup();

  let checked = true;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }
  return (
    <Box
      sx={() => styles.containerOption(true)}
      onClick={() => {
        if (radioGroup && handleChange && typeof props.value === 'string') {
          handleChange(props.value);
        }
      }}
    >
      <Box sx={styles.center}>
        <FormControlLabel
          labelPlacement="start"
          checked
          componentsProps={{
            typography: {
              fontSize: 13,
              fontWeight: 'bold'
            }
          }}
          sx={styles.optionLabel}
          {...props}
        />
        <Icon />
      </Box>
      <Typography variant="body2"> {props.description} </Typography>
    </Box>
  );
}
