export const containerMessage = () => ({
  backgroundColor: '#1E1E1E',
  right: 0,
  border: '2px solid #A547FF',
  borderRadius: '16px 16px 0px 16px',
  color: '#FFFFFF',
  padding: 2,
  marginTop: 1,
  maxWidth: '90%',
  position: 'relative'
});
export const containerOption = (isSelected: boolean) => ({
  backgroundColor: isSelected ? '#3B3442' : '323232',
  borderRadius: 2,
  padding: 1,
  paddingTop: 0,
  cursor: 'pointer',
  border: `1px solid ${isSelected ? '#A547FF' : '#323232'}`,
  display: 'flex',
  flexDirection: 'column'
});
export const containerCenter = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
  // minWidth: 160
};
export const optionLabel = {
  cursor: 'pointer',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: 16
};
export const messageAfter = {
  position: 'absolute',
  clip: 'rect(auto, 40px, auto, 20px)',
  transform: 'rotate(-320deg)',
  bottom: -0,
  right: -11,
  width: '32px',
  height: '32px',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-15px',
    bottom: '-40px',
    left: '-15px',
    right: '3px',
    background: '#1E1E1E',
    transform: 'rotate(-45deg)',
    border: '2px solid #A547FF'
  }
};
