import { Card, Box, CardContent, IconButton, SelectChangeEvent } from '@mui/material';
import * as styles from './styles';
import Input from '~/components/Input';
import InputDate from '~/components/InputDate';
import InputSelect from '~/components/InputSelect';
import { DeleteRounded } from '@mui/icons-material';
import { error } from 'console';
export type Additional_drivers = {
  name: string;
  document: string;
  document_type: string;
  birthdate: Date;
  degree_of_kinship: string;
};
type PropsForm = {
  index: number;
  driver: Additional_drivers;
  setDriver: (index: number, driver: Additional_drivers) => void;
  removeDriver: (index: number) => void;
};

export default function FormAdditionalDriver({ index, driver, setDriver, removeDriver }: PropsForm) {
  const handleSelectDegree = (value: string | number) => {
    setDriver(index, {
      ...driver,
      degree_of_kinship: value as string
    });
  };
  return (
    <Card sx={styles.cardAdditionalDriver}>
      <CardContent sx={styles.cardContent}>
        <Box sx={styles.deleteIconStyle}>
          <IconButton onClick={() => removeDriver(index)} sx={{ zIndex: 9999 }}>
            <DeleteRounded color="error" />
          </IconButton>
        </Box>
        <Box sx={styles.containerFormDriver}>
          <Input
            name="fullName"
            label="Nome Completo (Adicional)"
            placeholder="Seu Nome Completo"
            value={driver.name}
            onChange={(e) => {
              setDriver(index, {
                ...driver,
                name: e.target.value
              });
            }}
          />
          <Input
            name="documentNumber"
            label="CPF (adicional)"
            placeholder="XXX.XXX.XXX-XX"
            onChange={(e) => {
              setDriver(index, {
                ...driver,
                document: e.target.value
              });
            }}
          />
          <InputDate
            name="birthdate"
            label="Data de nascimento (adicional)"
            placeholder="XX/XX/XXXX"
            onChange={(e) => {
              setDriver(index, {
                ...driver,
                birthdate: new Date(e.target.value)
              });
            }}
          />
          <InputSelect
            name="Degree_of_kinship"
            label="Grau de parentesco"
            placeholder="select"
            value={driver.degree_of_kinship}
            items={[
              {
                value: 'parent',
                label: 'Pai ou mãe'
              },
              {
                value: 'son',
                label: 'Filho(a)'
              }
            ]}
            setValue={handleSelectDegree}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
