import * as yup from 'yup';

export default yup
  .object({
    street: yup.string().required('Rua é obrigatório'),
    number: yup.number().positive().typeError('Apenas números').required('Número é obrigatório'),
    neighborhood: yup.string().required('Bairro é obrigatório'),
    city: yup.string().required('Cidade é obrigatório'),
    state: yup.string().max(2, 'O estado deve ter no máximo 2 caracteres').required('Estado  é obrigatório'),
    country: yup.string(),
    additional_details: yup.string().nullable(),
    zipcode: yup
      .string()
      .required('Este é um CEP inválido, digite um válido. Em caso de dúvidas, consulte o site dos Correios.')
      .test(
        `test-zipcode-length`,
        `Este é um CEP inválido, digite um válido. Em caso de dúvidas, 
      consulte o site dos Correios.`,
        (value) => {
          if (value) {
            const newValue = value.replace(/[ -]/g, '');
            if (newValue.length === 8) return true;
          }
          return false;
        }
      )
  })
  .required();
