import cep, { CEP } from 'cep-promise';
export const searchAddressByZipcode = async (
  zipcode: string | number,
  success?: (params: CEP) => void,
  error?: (message: string) => void
) => {
  const cleanedZipcode = zipcode.toString().replace(/[-\s]/gi, '');

  cep(cleanedZipcode)
    .then((result: CEP) => {
      success && success(result);
    })
    .catch(() => {
      error && error('cep.error');
    });
};
