import { Message } from '../types';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { containerMessage, formControlContainer, container, messageAfter, selectStyle } from './styles';
import useStore from '~/store';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  questionComponent?: Message['questionComponent'];
  Component?: Message['Component'];
}

export const Gender = ({}: Props) => {
  const { setGender, quote } = useStore((store) => ({
    quote: store.quote,
    setGender: store.setGender
  }));

  const { gender } = quote;

  const handleChangeGender = (event: SelectChangeEvent) => {
    const newGenderValue = event.target.value as 'm' | 'f';
    setGender(newGenderValue);
  };

  const items = [
    {
      label: 'Masculino',
      value: 'm'
    },
    {
      label: 'Feminino',
      value: 'f'
    }
  ];

  return (
    <Box sx={container}>
      <Box sx={() => containerMessage()}>
        <FormControl variant="outlined" sx={formControlContainer}>
          <Select
            name="gender"
            displayEmpty
            value={gender}
            onChange={handleChangeGender}
            sx={selectStyle}
            inputProps={{ 'aria-label': 'Informe o seu gênero' }}
          >
            <MenuItem disabled value="">
              <em>Informe o seu gênero</em>
            </MenuItem>
            {items.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={messageAfter} />
      </Box>
    </Box>
  );
};
