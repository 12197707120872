import { Message } from '../types';
import { Box, Typography } from '@mui/material';
import { containerMessage, messageStyle, messageAfter } from './styles';

interface Props {
  content?: Message['content'];
  Component?: React.FC;
  author?: Message['author'];
  bold?: boolean;
  equipment?: string;
  QuestionComponent?: Message['questionComponent'];
}

export const MessageEquipment = ({ content, Component, QuestionComponent }: Props) => {
  return (
    <Box sx={() => containerMessage}>
      {content && (
        <>
          <Typography variant="body1" sx={messageStyle(true)}>
            {content} {QuestionComponent && <QuestionComponent />}
          </Typography>
        </>
      )}
      {Component && (
        <>
          <Component />
        </>
      )}
      <Box sx={messageAfter} />
    </Box>
  );
};
