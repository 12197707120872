import { IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { InternalIcon } from './svgs';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);

export function Icon(props: FontAwesomeIconProps) {
  const { icon } = props;

  if (icon.toString().startsWith('in-')) return <InternalIcon {...props} />;

  return <FontAwesomeIcon {...props} />;
}
