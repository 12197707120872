import { SvgIcon } from '@mui/material';

function AriIcon() {
  return (
    <SvgIcon viewBox="0 0 64 96" fill="none" style={{ fontSize: '6rem' }}>
      <path
        d="M0 64C0 46.3269 14.3269 32 32 32V32C49.6731 32 64 46.3269 64 64V64C64 81.6731 49.6731 96 32 96V96C14.3269 96 0 81.6731 0 64V64Z"
        fill="#A547FF"
      />
      <mask id="mask0_14_4488" mask="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="96">
        <path
          d="M0 16C0 7.16345 7.16344 0 16 0H48C56.8366 0 64 7.16344 64 16V64C64 81.6731 49.6731 96 32 96V96C14.3269 96 0 81.6731 0 64V16Z"
          fill="#ffff"
        />
      </mask>
      <g mask="url(#mask0_14_4488)">
        <path
          d="M37.4174 64.2297C37.9424 64.5458 38.5386 64.7245 39.1509 64.7492C39.7632 64.7738 40.3719 64.6437 40.9206 64.3707C41.4693 64.0978 41.9402 63.6909 42.29 63.1877C42.6397 62.6845 42.8569 62.1012 42.9215 61.4918C43.0159 62.181 45.395 62.5869 46.4335 61.8789C47.7552 60.9348 48.4916 59.4809 48.4727 57.2245C49.6245 57.9515 51.5316 58.1686 52.6173 55.8084C53.4103 54.0807 52.9855 52.4002 52.7967 50.0022C52.9521 50.1667 53.1417 50.2952 53.3521 50.3785C53.5625 50.4618 53.7886 50.498 54.0146 50.4845C54.2405 50.471 54.4607 50.4082 54.6597 50.3004C54.8587 50.1926 55.0316 50.0424 55.1664 49.8606C55.4172 49.4705 55.5704 49.0259 55.6132 48.5641C55.6559 48.1024 55.5868 47.6371 55.4118 47.2077C55.1097 46.3297 54.5999 45.5461 54.2317 44.6964C53.5869 42.8544 53.3016 40.9058 53.3915 38.9563C53.3915 37.0115 53.3915 35.3593 53.3915 32.3477C53.3915 30.4028 50.0777 29.893 48.1329 29.9025C45.8387 29.9025 44.1016 31.9228 42.9309 33.896C39.2867 40.0515 38.0877 47.3021 36.9548 54.3733C36.7188 55.8461 36.4828 57.3189 36.2373 58.7917"
          fill="#873348"
        />
        <path
          d="M13.2864 29.0317L12.2384 37.5285C12.0615 39.2776 11.765 41.0125 11.351 42.721C10.7373 45.0246 9.69882 47.2055 9.13237 49.5279C8.78305 50.9252 8.66031 52.5302 9.51944 53.682C10.3786 54.8337 12.55 54.9376 13.0598 53.5875C12.9525 54.2547 12.9897 54.937 13.1689 55.5885C13.3481 56.24 13.665 56.8454 14.0983 57.3639C15.0424 58.308 16.9306 58.4402 17.667 57.3639C17.839 58.3163 18.1999 59.2247 18.7285 60.0354C19.2571 60.8461 19.9426 61.5428 20.7447 62.0844C21.5661 62.6107 22.5416 62.843 23.5121 62.7436C24.4825 62.6441 25.3907 62.2187 26.0883 61.5368C26.2796 61.3559 26.4216 61.1292 26.5009 60.878C26.5802 60.6269 26.5941 60.3598 26.5414 60.1018C26.4527 59.8922 26.3317 59.6979 26.1827 59.5259C21.5283 53.6064 16.7417 47.1488 16.2319 39.6339C15.9487 35.3949 17.0627 31.2031 18.1201 27.0774C18.3294 26.429 18.3907 25.7418 18.2995 25.0665C18.2461 24.7308 18.0844 24.4217 17.8389 24.1864C17.5934 23.9512 17.2777 23.8027 16.94 23.7637"
          fill="#873348"
        />
        <path
          d="M30.6956 62.1501C28.3121 61.888 26.0035 61.159 23.9017 60.0046C21.7999 58.8503 19.9461 57.2932 18.4461 55.4224C16.9461 53.5516 15.8293 51.4036 15.1594 49.1011C14.4896 46.7986 14.2799 44.3867 14.5422 42.0032L15.4863 33.2892C15.7461 30.9074 16.4727 28.6 17.6245 26.499C18.7763 24.398 20.3307 22.5446 22.199 21.0445C24.0673 19.5444 26.2129 18.4271 28.5131 17.7564C30.8133 17.0858 33.2232 16.8749 35.6049 17.1358V17.1358C37.9915 17.3933 40.3038 18.119 42.4094 19.2715C44.515 20.424 46.3726 21.9805 47.8757 23.852C49.3789 25.7235 50.4981 27.8731 51.1692 30.1778C51.8403 32.4824 52.0501 34.8969 51.7867 37.2827L50.8426 46.0062C50.3085 50.8172 47.8868 55.2197 44.1093 58.2466C40.3319 61.2736 35.5075 62.6775 30.6956 62.1501Z"
          fill="#FFB69C"
        />
        <path d="M42.2742 51.6068L25.3301 47.1426L17.8882 75.3889L34.8323 79.8531L42.2742 51.6068Z" fill="#FFB69C" />
        <path
          d="M60.0002 115.549C39.5889 100.245 37.5119 75.2458 37.493 74.9531L23.8604 75.8972C23.9453 77.1717 26.2678 107.288 51.796 126.444"
          fill="#FFB69C"
        />
        <path
          d="M42.9402 104.55C45.697 93.117 49.4356 74.8205 37.2191 67.9475C34.1875 68.592 31.0461 68.5051 28.0549 67.6941C25.0636 66.883 22.3085 65.3712 20.0177 63.2837C4.91223 63.0383 -0.81841 73.0079 -4 88.2644"
          fill="#121212"
        />
        <path
          d="M47.6228 36.6975C48.3025 34.9509 45.9801 33.3365 44.5262 33.0439C43.7331 32.8834 42.468 33.11 42.1093 33.988C41.7505 34.866 42.5247 35.4041 43.2233 35.7534L42.6569 35.3474C43.2236 35.8088 43.8612 36.1754 44.5451 36.4332C45.1493 36.7919 45.7535 37.4528 46.4899 37.4433C46.7302 37.4583 46.9692 37.3982 47.1739 37.2714C47.3786 37.1445 47.5387 36.9572 47.6322 36.7353L47.6228 36.6975Z"
          fill="#662736"
        />
        <path
          d="M43.0065 40.2969C42.9121 41.241 43.2142 42.0152 43.6768 42.0624C44.1394 42.1096 44.6209 41.3921 44.6776 40.4574C44.7342 39.5228 44.4699 38.7486 44.0073 38.7014C43.5447 38.6542 43.0915 39.3717 43.0065 40.2969Z"
          fill="#852D4C"
        />
        <path
          d="M30.2612 35.4714C31.1392 35.6885 31.7528 35.2636 32.5742 35.0182C33.3312 34.9157 34.0653 34.6859 34.7456 34.3384L34.1131 34.6311C34.8778 34.4517 35.7369 34.1024 35.5386 33.13C35.3404 32.1576 34.2264 31.7611 33.4428 31.7327C31.9133 31.6761 29.2982 32.6768 29.5625 34.6217C29.5918 34.813 29.6721 34.993 29.7951 35.1425C29.9181 35.292 30.0791 35.4056 30.2612 35.4714Z"
          fill="#662736"
        />
        <path
          d="M33.2348 39.3509C33.1404 40.295 32.6967 40.9936 32.2341 40.9464C31.7715 40.8992 31.4694 40.1156 31.5543 39.1904C31.6393 38.2652 32.0925 37.5476 32.5551 37.5854C33.0177 37.6232 33.3292 38.4257 33.2348 39.3509Z"
          fill="#852D4C"
        />
        <path
          d="M39.1449 38.229C38.8296 39.0878 38.7706 40.0197 38.9751 40.9113C39.1795 41.803 39.6387 42.616 40.2967 43.2516C40.5328 43.5065 40.9198 43.752 40.9765 44.1201C40.9867 44.3951 40.8883 44.6629 40.7027 44.866C40.4723 45.1258 40.1681 45.3093 39.8308 45.392C39.4935 45.4746 39.139 45.4525 38.8145 45.3286C38.4558 45.187 38.2953 45.7629 38.654 45.9045C38.9337 46.012 39.2323 46.062 39.5318 46.0514C39.8313 46.0409 40.1256 45.97 40.397 45.8431C40.6685 45.7162 40.9116 45.5358 41.1117 45.3128C41.3119 45.0897 41.4649 44.8286 41.5618 44.545C41.6162 44.2632 41.5881 43.9716 41.4809 43.7054C41.3738 43.4391 41.1921 43.2094 40.9576 43.0439C40.3715 42.4833 39.9224 41.7952 39.6453 41.033C39.353 40.1691 39.39 39.2278 39.7492 38.3895C39.9002 38.0308 39.3149 37.8797 39.1733 38.229H39.1449Z"
          fill="#852D4C"
        />
        <path
          opacity="0.35"
          d="M43.2335 45.6111C43.4318 46.6874 44.8857 47.32 46.4812 47.0273C48.0767 46.7346 49.2002 45.6112 49.002 44.5349C48.8037 43.4586 47.3498 42.8261 45.7543 43.1282C44.1588 43.4303 43.0258 44.5349 43.2335 45.6111Z"
          fill="#E5636D"
        />
        <path
          opacity="0.35"
          d="M25.0586 42.4201C24.7281 43.468 25.7005 44.7142 27.2488 45.2524C28.7972 45.7905 30.3077 45.309 30.6476 44.3083C30.9875 43.3075 30.0056 42.0141 28.4667 41.476C26.9279 40.9379 25.3984 41.3816 25.0586 42.4201Z"
          fill="#E5636D"
        />
        <path
          d="M38.3334 48.1789C37.3413 48.3159 36.3324 48.1035 35.4798 47.5781C34.6271 47.0528 33.9838 46.2472 33.6601 45.2994C33.5468 44.9218 32.9615 45.0823 33.0842 45.4599C33.4532 46.559 34.1978 47.4929 35.1873 48.0972C36.1767 48.7016 37.3475 48.9378 38.4938 48.7642C38.8715 48.6981 38.711 48.1128 38.3334 48.1789Z"
          fill="#852D4C"
        />
        <path
          d="M19.1211 80.7129C19.1211 80.7129 21.0093 108.762 44.3378 126.256"
          stroke="#FFB69C"
          strokeWidth="14.47"
          strokeMiterlimit="10"
        />
        <path
          d="M37.5971 100.755L29.544 81.3914C28.9679 79.9641 28.1162 78.6643 27.0375 77.5662C25.9589 76.4682 24.6745 75.5935 23.2577 74.992C21.8408 74.3906 20.3194 74.0742 18.7802 74.061C17.2411 74.0477 15.7144 74.3379 14.2875 74.9149V74.9149C11.4078 76.0768 9.10717 78.3343 7.89102 81.1914C6.67487 84.0486 6.6427 87.2717 7.80157 90.1526"
          fill="#121212"
        />
        <path
          d="M37.7668 100.537L36.3979 97.252L33.4523 90.1524L30.6956 83.5438C30.1525 82.0236 29.4632 80.5597 28.6374 79.1727C27.5636 77.5829 26.1267 76.2717 24.4456 75.3474C22.7646 74.4232 20.8876 73.9124 18.9699 73.8574C18.4979 73.8574 18.0258 73.8574 17.5443 73.8574C17.2139 73.8574 17.2045 74.4144 17.5443 74.3861C19.4495 74.2094 21.3687 74.5065 23.1312 75.251C24.8937 75.9956 26.4447 77.1644 27.6461 78.6534C28.6539 80.0698 29.458 81.6207 30.0347 83.2606L32.6687 89.586L35.6237 96.695C36.1619 97.9884 36.6905 99.2818 37.2381 100.575V100.632C37.3703 100.943 37.8801 100.811 37.7479 100.5L37.7668 100.537Z"
          fill="#121212"
        />
        <path
          d="M18.4133 41.2116C18.5283 40.1797 18.3347 39.1369 17.857 38.215C17.3794 37.2932 16.6391 36.5337 15.7297 36.0326C14.8204 35.5316 13.7829 35.3114 12.7484 35.4C11.7139 35.4886 10.729 35.882 9.91811 36.5305C9.10724 37.1789 8.50688 38.0532 8.19295 39.0429C7.87903 40.0325 7.86565 41.0931 8.15449 42.0903C8.44334 43.0876 9.02144 43.9768 9.81568 44.6455C10.6099 45.3142 11.5846 45.7323 12.6165 45.847C13.9989 45.9957 15.384 45.5918 16.4699 44.7235C17.5557 43.8552 18.2543 42.5928 18.4133 41.2116V41.2116Z"
          fill="#FFB69C"
        />
        <path
          d="M34.2075 25.2773C33.9336 26.3817 33.5867 27.4667 33.1689 28.525L33.8676 28.7894L34.2075 25.2773Z"
          fill="#873348"
        />
        <path
          d="M31.6111 31.7988C32.2145 30.7489 32.7353 29.6536 33.1689 28.5228C29.3419 27.0733 25.2567 26.4304 21.1695 26.6347C20.7342 26.5723 20.2919 26.684 19.9384 26.9456C19.585 27.2071 19.3489 27.5974 19.2813 28.0319C17.1476 33.8853 15.8448 35.6885 18.054 41.5136C21.3762 40.9839 24.4816 39.5277 27.0134 37.3123C27.1928 37.9732 27.7592 38.3508 29.0432 38.0393C30.6009 37.6617 35.0759 34.565 35.4347 30.0617L31.6111 31.7988Z"
          fill="#873348"
        />
        <path
          d="M14.8916 42.7027C15.051 42.1327 15.0521 41.5301 14.8947 40.9595C14.7373 40.3889 14.4275 39.872 13.9984 39.4643C13.5694 39.0566 13.0373 38.7735 12.4595 38.6455C11.8816 38.5174 11.2798 38.5493 10.7187 38.7376C10.3788 38.8603 10.5204 39.4079 10.8697 39.2946C11.3332 39.1306 11.8327 39.0973 12.3138 39.1981C12.7949 39.2989 13.2391 39.5301 13.5977 39.8663C13.9563 40.2025 14.2156 40.6308 14.3472 41.1044C14.4788 41.578 14.4777 42.0787 14.344 42.5517C14.324 42.6243 14.3336 42.7019 14.3708 42.7674C14.408 42.8329 14.4696 42.881 14.5423 42.901C14.6149 42.921 14.6925 42.9114 14.758 42.8742C14.8235 42.837 14.8715 42.7754 14.8916 42.7027V42.7027Z"
          fill="#852D4C"
        />
        <path
          d="M13.4366 39.6826C12.5506 39.9389 11.7657 40.4633 11.1897 41.1837C11.1393 41.2393 11.1113 41.3117 11.1113 41.3867C11.1113 41.4617 11.1393 41.5341 11.1897 41.5897C11.2442 41.6422 11.317 41.6715 11.3927 41.6715C11.4684 41.6715 11.5411 41.6422 11.5956 41.5897C12.0971 40.9328 12.8002 40.4585 13.5971 40.2396C13.6616 40.2109 13.7135 40.1598 13.7432 40.0958C13.773 40.0318 13.7786 39.9591 13.7591 39.8913C13.7395 39.8235 13.6961 39.765 13.6369 39.7267C13.5776 39.6883 13.5065 39.6727 13.4366 39.6826V39.6826Z"
          fill="#852D4C"
        />
        <path
          d="M15.6747 29.2988C15.6747 29.2988 14.7306 35.9924 14.3246 41.6287C13.9187 47.2649 15.0705 58.6884 29.043 61.4546C41.8071 63.9565 45.829 57.4233 47.8399 54.1473C47.8399 54.1473 42.6285 62.4931 29.4018 58.188C16.6376 54.0246 20.9049 38.702 21.849 30.1768L15.6747 29.2988Z"
          fill="#121212"
        />
        <path
          d="M12.8145 30.2604L13.2676 26.1347C14.4383 15.4853 24.4929 7.85705 35.7087 9.14102C46.9339 10.3778 55.0815 20.0075 53.9108 30.6474L53.4576 34.7826"
          fill="#54C556"
        />
        <path
          d="M26.7306 20.215C27.4522 20.844 28.0286 21.6223 28.4198 22.4961C28.8111 23.3698 29.0078 24.3181 28.9964 25.2753C28.9964 25.3505 29.0263 25.4225 29.0794 25.4756C29.1325 25.5287 29.2045 25.5586 29.2796 25.5586C29.3548 25.5586 29.4268 25.5287 29.4799 25.4756C29.533 25.4225 29.5629 25.3505 29.5629 25.2753C29.5736 24.2422 29.3597 23.2191 28.9359 22.2769C28.512 21.3346 27.8884 20.4958 27.1082 19.8185C26.8344 19.5825 26.4285 19.979 26.7023 20.215H26.7306Z"
          fill="white"
        />
        <path
          d="M21.7549 18.5628C22.9155 18.553 24.0657 18.7812 25.1348 19.2331C25.1999 19.2719 25.2777 19.2833 25.3512 19.2647C25.4247 19.2461 25.4877 19.1991 25.5266 19.134C25.5654 19.0689 25.5767 18.991 25.5581 18.9175C25.5395 18.8441 25.4925 18.781 25.4274 18.7422C24.2683 18.2412 23.0176 17.9872 21.7549 17.9963C21.6798 17.9963 21.6078 18.0262 21.5546 18.0793C21.5015 18.1324 21.4717 18.2044 21.4717 18.2796C21.4717 18.3547 21.5015 18.4267 21.5546 18.4798C21.6078 18.5329 21.6798 18.5628 21.7549 18.5628V18.5628Z"
          fill="white"
        />
        <path
          d="M41.127 24.586C41.2201 25.5278 41.2201 26.4764 41.127 27.4182C41.127 27.4934 41.1568 27.5654 41.2099 27.6185C41.263 27.6716 41.3351 27.7015 41.4102 27.7015C41.4845 27.6991 41.5552 27.6685 41.6078 27.6159C41.6604 27.5633 41.691 27.4926 41.6934 27.4182C41.7959 26.4769 41.7959 25.5273 41.6934 24.586C41.6934 24.5108 41.6636 24.4388 41.6105 24.3857C41.5573 24.3326 41.4853 24.3027 41.4102 24.3027C41.3351 24.3027 41.263 24.3326 41.2099 24.3857C41.1568 24.4388 41.127 24.5108 41.127 24.586V24.586Z"
          fill="white"
        />
        <path
          d="M32.772 12.862C35.927 14.2903 38.4517 16.8217 39.8716 19.9805C40.2802 20.8906 40.5965 21.8394 40.8157 22.8127C40.9006 23.1715 41.4482 23.0204 41.3632 22.6617C40.5232 19.1414 38.4878 16.0216 35.6043 13.8344C34.8176 13.2494 33.9653 12.7583 33.0647 12.3711C32.7248 12.2295 32.4416 12.7204 32.772 12.862Z"
          fill="white"
        />
        <path
          d="M29.0808 12.0485C29.8888 12.0758 30.6907 12.1994 31.4694 12.4167C31.542 12.4368 31.6196 12.4271 31.6851 12.39C31.7506 12.3528 31.7987 12.2911 31.8187 12.2185C31.8387 12.1459 31.8291 12.0683 31.7919 12.0028C31.7547 11.9373 31.6931 11.8892 31.6204 11.8692C30.7914 11.6423 29.9395 11.5093 29.0808 11.4727C28.7126 11.4727 28.7126 12.0297 29.0808 12.0485Z"
          fill="white"
        />
        <path
          d="M49.7656 24.5196C49.8034 25.1673 49.8034 25.8167 49.7656 26.4644C49.7779 26.5318 49.8134 26.5928 49.866 26.6367C49.9187 26.6806 49.985 26.7047 50.0536 26.7047C50.1221 26.7047 50.1885 26.6806 50.2411 26.6367C50.2937 26.5928 50.3292 26.5318 50.3415 26.4644C50.384 25.8168 50.384 25.1671 50.3415 24.5196C50.3391 24.4452 50.3085 24.3745 50.2559 24.3219C50.2033 24.2693 50.1327 24.2387 50.0583 24.2363C50.0211 24.2363 49.9843 24.2437 49.9499 24.2579C49.9155 24.2721 49.8843 24.293 49.858 24.3193C49.8317 24.3456 49.8109 24.3768 49.7966 24.4112C49.7824 24.4455 49.7751 24.4824 49.7751 24.5196H49.7656Z"
          fill="white"
        />
        <path
          d="M44.97 16.5157C46.9505 17.7306 48.4562 19.5861 49.2373 21.7743C49.3506 22.1142 49.9076 21.9726 49.7848 21.6233C48.9669 19.2893 47.3655 17.311 45.2532 16.0248C44.9511 15.8265 44.6679 16.3174 44.97 16.5157V16.5157Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}

export default AriIcon;
