import { useState, ChangeEvent } from 'react';
import { Message } from '../types';
import { Box, InputAdornment, FormControl, TextField, Typography, Link } from '@mui/material';
import { containerMessage, containerInput, iconStyle, containerMessageBubble, linkStyle } from './styles';
import MaskedInput from 'react-text-mask';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import useStore from '~/store';
import { searchAddressByZipcode } from '~/utils/searchAddressByZipcode';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  Component?: Message['Component'];
}
interface TextMaskCustomProps {
  placeholder: string;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  return (
    <MaskedInput
      {...props}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'} // showMask
    />
  );
}
export const InputZipCode = ({ content }: Props) => {
  const { quote, setZipcode, setIsValideZipcode } = useStore((store) => ({
    quote: store.quote,
    setZipcode: store.setZipcode,
    setIsValideZipcode: store.setIsValideZipcode
  }));
  const { zipcode, isValideZipcode } = quote;
  const [isError, setIsError] = useState(false);
  const handleChangeZipcode = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event) {
      const eventValue = event.target.value.replace('-', '').replaceAll(' ', '');
      setZipcode(eventValue);
      if (eventValue.length >= 8) {
        const zipcode = parseInt(eventValue);
        searchAddressByZipcode(
          zipcode,
          () => {
            setIsValideZipcode(true);
            setIsError(false);
          },
          () => {
            setIsValideZipcode(false);
            setIsError(true);
          }
        );
      }
    }
  };

  return (
    <Box>
      <Box sx={() => containerMessage(isError)}>
        <FormControl variant="outlined" sx={containerInput}>
          <TextField
            value={zipcode}
            onChange={(e) => handleChangeZipcode(e)}
            error={isError}
            InputProps={{
              inputComponent: TextMaskCustom as any,
              startAdornment: (
                <>
                  <InputAdornment position="start">CEP</InputAdornment>
                </>
              )
            }}
            variant="outlined"
            placeholder="XXXXX-XXX"
          />
          <Box sx={{ margin: isValideZipcode || isError ? 0 : 2 }}>
            {isValideZipcode && (
              <>
                <CheckCircleIcon color="success" sx={iconStyle} />
              </>
            )}
            {isError && (
              <>
                <ErrorIcon color="error" sx={iconStyle} />
              </>
            )}
          </Box>
        </FormControl>
      </Box>
      {isError && (
        <>
          <Box sx={() => containerMessageBubble}>
            <Typography variant="body1">
              {'Desculpe-me, mas parece que esse CEP é inválido. Consulte o '}
              <Link
                href="https://buscacepinter.correios.com.br/app/endereco/index.php?t"
                target="_blank"
                rel="noreferrer"
                sx={linkStyle}
              >
                site dos correios
              </Link>
              {' para confirmar.'}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
