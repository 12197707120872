import { Palette } from '@mui/material/styles';
// import { TypeBackground } from '@mui/material/styles/createPalette'

const colors: Partial<Palette> = {
  mode: 'dark',
  primary: {
    // main: '#A547FF',
    light: '#A547FF',
    main: '#A547FF',
    dark: '#A547FF',
    contrastText: '#FFF'
  },
  success: {
    // main: '#52C41A'
    light: '#52C41A',
    main: '#52C41A',
    dark: '#52C41A',
    contrastText: '#52C41A'
  },
  error: {
    // main: '#E5636D'
    light: '#E5636D',
    main: '#E5636D',
    dark: '#E5636D',
    contrastText: '#E5636D'
  },
  text: {
    // primary: '#FFF',
    // secondary: '#FFF9'
    primary: '#fff',
    secondary: '#fff8',
    disabled: '#fff5'
  },
  background: {
    default: '#000',
    paper: '#121212'
  }
};

export default colors;
