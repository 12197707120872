import styled, { css } from 'styled-components';
import { PositionType } from './types';

const handleToastPosition = (position: PositionType) => {
  switch (position) {
    case 'top-right':
      return css`
        top: 12px;
        right: 12px;
        transition: transform 0.6s ease-in-out;
        animation: toast-in-right 0.7s;
      `;
    case 'bottom-right':
      return css`
        bottom: 12px;
        right: 12px;
        transition: transform 0.6s ease-in-out;
        animation: toast-in-right 0.7s;
      `;

    case 'top-left':
      return css`
        top: 12px;
        left: 12px;
        transition: transform 0.6s ease-in;
        animation: toast-in-left 0.7s;
      `;

    case 'bottom-left':
      return css`
        bottom: 12px;
        left: 12px;
        transition: transform 0.6s ease-in;
        animation: toast-in-left 0.7s;
      `;

    case 'bottom-center':
      return css`
        bottom: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.6s ease-in;
        animation: toast-in-bottom 0.7s;
      `;

    case 'top-center':
      return css`
        top: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.6s ease-in;
        animation: toast-in-top 0.7s;
      `;
    default:
      return css`
        top: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.6s ease-in;
        animation: toast-in-top 0.7s;
      `;
  }
};

export const ListContainer = styled.div<{ position: PositionType }>`
  position: fixed;
  z-index: 9999;
  ${({ position }) => handleToastPosition(position)}
`;
