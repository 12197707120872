/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { GetState, SetState } from 'zustand';
import AuthService from '~/services/modules/auth';
import TokenService from '~/services/token';
import {
  SignInRequestParams,
  SignUpRequestParams,
  ForgotPasswordInitialRequestParams
} from '~/services/modules/auth/types';
import { i18n } from '~/i18n/i18n';
import { storeState } from '~/store';
import { AuthStore } from './types';

const createAuthSlice = (set: SetState<AuthStore>, get: GetState<AuthStore>) => ({
  user: null,
  userDetails: null,
  userLoading: false,
  userAuthenticated: false,
  clientCredencial: false,
  authSignIn: async (payload: SignInRequestParams, callback?: () => void) => {
    try {
      set({
        user: null,
        userDetails: null,
        userAuthenticated: false,
        userLoading: true
      });

      const user = await AuthService.signIn(payload);
      const userDetails = await AuthService.authUserDetails(user.token);

      set({
        user: user,
        userDetails: userDetails,
        userAuthenticated: true,
        userLoading: false
      });

      TokenService.setAuthData(user, userDetails);

      storeState.addToast({
        type: 'success',
        title: i18n.t('login.notifications.success.title'),
        message: i18n.t('login.notifications.success.message')
      });

      callback && callback();
    } catch (error) {
      set({
        user: null,
        userDetails: null,
        userAuthenticated: false,
        userLoading: false
      });
      storeState.addToast({
        type: 'error',
        title: i18n.t('login.notifications.error.title'),
        message: i18n.t('login.notifications.error.message')
      });
    }
  },
  getClientCredencial: async (payload: null, callback?: () => void) => {
    try {
      set({
        user: null,
        userDetails: null,
        userAuthenticated: false,
        userLoading: true
      });

      const clientCredencial = await TokenService.getClientCredencial();

      set((state) => ({
        ...state,
        clientCredencial: clientCredencial
      }));

      callback && callback();
    } catch (error) {
      set({
        user: null,
        userDetails: null,
        userAuthenticated: false,
        userLoading: false
      });
      storeState.addToast({
        type: 'error',
        title: 'Não autorizado',
        message: 'Não conseguimos identificar cliente'
      });
    }
  },
  authSingUp: async (payload: SignUpRequestParams, callback?: () => void) => {
    try {
      await AuthService.signUp(payload);
      storeState.addToast({
        type: 'success',
        title: i18n.t('signup.notifications.success.title'),
        message: i18n.t('signup.notifications.success.message')
      });
      callback && callback();
    } catch (error) {
      storeState.addToast({
        type: 'error',
        title: i18n.t('signup.notifications.error.title'),
        message: i18n.t('signup.notifications.error.message')
      });
    }
  },
  authForgotPasswordInitial: async (payload: ForgotPasswordInitialRequestParams): Promise<boolean> => {
    try {
      await AuthService.forgotPasswordInitial(payload);
      storeState.addToast({
        type: 'success',
        title: i18n.t('forgot.notifications.success-step1.title'),
        message: i18n.t('forgot.notifications.success-step1.message')
      });
      return true;
    } catch (error) {
      storeState.addToast({
        type: 'error',
        title: i18n.t('forgot.notifications.error-step1.title'),
        message: i18n.t('forgot.notifications.error-step1.message')
      });
      return false;
    }
  },
  authLogout: () => {
    TokenService.removeAuthData();
    set({
      user: null,
      userAuthenticated: false,
      userLoading: false
    });
  },
  isAuthenticatedStore: () => {
    if (get().userAuthenticated) {
      return true;
    }
    const { authData, userData } = TokenService.getAuthData();

    if (authData?.token && userData?.token) {
      set({ userAuthenticated: true, user: authData, userDetails: userData });
      return true;
    }
    return false;
  }
});

export default createAuthSlice;
