import { SvgIcon } from '@mui/material';

function BikeGreen() {
  return (
    <SvgIcon viewBox="0 0 20 14" fill="none">
      <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.15 5L13.7333 1.1C13.6161 0.77842 13.403 0.500542 13.1228 0.303847C12.8427 0.107152 12.509 0.00110135 12.1667 0H10V1.66667H12.1667L13.3833 5H9.375L9.075 4.16667H10V2.5H5.83333V4.16667H7.29167L8.80833 8.33333H8.25C7.88333 6.475 6.325 5.1 4.375 5.00833C2.04167 4.89167 0 6.83333 0 9.16667C0 11.5 1.83333 13.3333 4.16667 13.3333C6.21667 13.3333 7.875 11.925 8.25 10H11.75C12.1167 11.8583 13.675 13.2333 15.625 13.325C17.9583 13.4333 20 11.5 20 9.15833C20 6.825 18.1667 4.99167 15.8333 4.99167H15.15V5ZM6.51667 10C6.18333 10.975 5.275 11.6667 4.16667 11.6667C2.76667 11.6667 1.66667 10.5667 1.66667 9.16667C1.66667 7.76667 2.76667 6.66667 4.16667 6.66667C5.275 6.66667 6.18333 7.35833 6.51667 8.33333H4.16667V10H6.51667ZM11.75 8.33333H10.5833L9.975 6.66667H12.5C12.1333 7.15 11.8667 7.70833 11.75 8.33333ZM15.8333 11.6667C14.4333 11.6667 13.3333 10.5667 13.3333 9.16667C13.3333 8.39167 13.675 7.725 14.2083 7.26667L15.0083 9.46667L16.575 8.9L15.7667 6.675C15.7917 6.675 15.8167 6.66667 15.8417 6.66667C17.2417 6.66667 18.3417 7.76667 18.3417 9.16667C18.3417 10.5667 17.2333 11.6667 15.8333 11.6667Z"
          fill="#03DAC5"
        />
      </svg>
    </SvgIcon>
  );
}

export default BikeGreen;
