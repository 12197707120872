import { Container } from '@mui/material';

export default function ErrorPage() {
  return (
    <Container
      maxWidth="xl"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh' }}
    >
      <span style={{ fontSize: '88px' }}>404</span>

      <span> Ops.. Alguma coisa deu errado </span>
    </Container>
  );
}
