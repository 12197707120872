export const headerContainer = {
  display: 'flex',
  alignItems: 'flex-start'
};
export const container = {
  marginTop: 4,
  marginBottom: 4
};
export const iconGoback = {
  marginTop: -0.5,
  marginRight: 1
};
