import { Box, Checkbox, FormGroup, FormControlLabel, Typography } from '@mui/material';
import * as styles from './styles';
import useStore from '~/store';

export const EquipmentValue = () => {
  const { equipmentvalue, insuredAmount, hasInvoice, quoteHasInvoice } = useStore((store) => ({
    equipmentvalue: store.quote.equipmentValue,
    insuredAmount: store.insured_item.insured_amount,
    hasInvoice: store.insured_item.has_invoice,
    quoteHasInvoice: store.quote.hasInvoice
  }));

  const value = equipmentvalue || insuredAmount;
  const formattedValue = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  return (
    <Box>
      <Box sx={() => styles.containerMessage()}>
        <Box sx={styles.inputStyle}>
          <Typography fontWeight="bold" fontSize={20}>
            {formattedValue}
          </Typography>
        </Box>
      </Box>
      <Box sx={() => styles.containerMessage()}>
        <FormGroup
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Checkbox
            sx={{ cursor: 'not-allowed' }}
            onChange={() => {}}
            disabled
            checked={hasInvoice || quoteHasInvoice}
            inputProps={{
              'aria-label': 'Eu tenho a nota fiscal.'
            }}
            color="success"
          />

          <p>Eu tenho a nota fiscal</p>
        </FormGroup>
      </Box>
    </Box>
  );
};
