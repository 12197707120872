import { Card, Grid, CardContent, Typography, Button, TextField } from '@mui/material';
import { purpleText, internalCard, normalText, changeAddressBtn } from '../../styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { addressSchema } from '../../validations';
import { useEffect, useState } from 'react';
import api from '~/services/api';
import { storeState } from '~/store';
import useDebounce from '~/utils/useDebounce';
import { AiOutlineConsoleSql } from 'react-icons/ai';

interface Props {
  address:
    | {
        address_id: number;
        street: string;
        additional_details: string;
        zipcode: string;
        neighborhood: string;
        city: string;
        state: string;
        country: string;
        number: string;
      }
    | undefined;
  updateAddress: (data: AddressInputs) => Promise<void>;
}

export type AddressInputs = {
  street: string;
  number: string;
  additional_details: string;
  neighborhood: string;
  city: string;
  state: string;
  zipcode: string;
};

export function AddressCard({ address, updateAddress }: Props) {
  if (!address) return null;

  const [allowChangeAddress, setAllowChangeAddress] = useState(false);
  const [changedAddress, setChangedAddress] = useState({} as AddressInputs);

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors }
  } = useForm<AddressInputs>({
    defaultValues: address,
    resolver: yupResolver(addressSchema)
  });

  const zipcodeState = watch('zipcode');
  const debouncedZipCode = useDebounce(zipcodeState, 1000);

  const handleZipCodeSearch = async () => {
    const zipcode = getValues('zipcode');
    const cleanZipcode = zipcode.replace(/[^\d]+/g, '');

    if (cleanZipcode.length === 8) {
      const {
        data: { data }
      } = await api.get(`ZipCodes?zipcode=${cleanZipcode}`);

      Object.entries(data).forEach(([key, value]) => {
        setValue(key as keyof AddressInputs, value as string);
      });
    }
  };

  const onSubmit: SubmitHandler<AddressInputs> = async (data) => {
    try {
      await updateAddress(data);

      setChangedAddress(data);

      setAllowChangeAddress(false);
    } catch (e) {
      Object.entries(address).forEach(([key, value]) => {
        setValue(key as keyof AddressInputs, value as string);
      });

      storeState.addToast({
        type: 'error',
        title: 'Aconteceu um erro',
        message: 'Não foi possível atualizar o endereço. Tente novamente mais tarde'
      });
    }
  };

  const handleChangeAddress = () => {
    setAllowChangeAddress(true);
  };

  useEffect(() => {
    if (debouncedZipCode !== address.zipcode) {
      handleZipCodeSearch();
    }
  }, [debouncedZipCode]);

  return (
    <Card
      sx={{
        margin: { xs: '2rem 0.5rem', sm: '2rem 1rem', md: '2rem 2rem' }
      }}
    >
      <CardContent sx={internalCard}>
        <Typography variant="h6" align="left" sx={{ paddingBottom: 1 }}>
          2- Endereço completo (pernoite)
        </Typography>
        {!allowChangeAddress ? (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  CEP:
                </Typography>
                <Typography variant="subtitle2" sx={normalText}>
                  {changedAddress.zipcode ?? address.zipcode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Endereço:
                </Typography>
                <Typography variant="subtitle2" sx={normalText}>
                  {changedAddress.street ?? address.street}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Número:
                </Typography>
                <Typography variant="subtitle2" sx={normalText}>
                  {changedAddress.number ?? address.number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Complemento:
                </Typography>
                <Typography variant="subtitle2" sx={normalText}>
                  {(changedAddress.additional_details ?? address.additional_details) || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Bairro:
                </Typography>
                <Typography variant="subtitle2" sx={normalText}>
                  {changedAddress.neighborhood ?? address.neighborhood}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Cidade:
                </Typography>
                <Typography variant="subtitle2" sx={normalText}>
                  {changedAddress.city ?? address.city}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Estado:
                </Typography>
                <Typography variant="subtitle2" sx={normalText}>
                  {changedAddress.state ?? address.state}
                </Typography>
              </Grid>
            </Grid>
            <Button type="button" sx={changeAddressBtn} onClick={handleChangeAddress}>
              Alterar
            </Button>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  CEP:
                </Typography>
                <Controller
                  name="zipcode"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        helperText={errors?.zipcode?.message}
                        error={!!errors?.zipcode ? true : false}
                        fullWidth
                        inputProps={{
                          style: {
                            padding: '8px 12px',
                            fontSize: '14px'
                          }
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Endereço:
                </Typography>
                <Controller
                  name="street"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        helperText={errors?.street?.message}
                        error={!!errors?.street}
                        fullWidth
                        inputProps={{
                          style: {
                            padding: '8px 12px',
                            fontSize: '14px'
                          }
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Número:
                </Typography>
                <Controller
                  name="number"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        helperText={errors?.number?.message}
                        error={!!errors?.number ? true : false}
                        fullWidth
                        inputProps={{
                          style: {
                            padding: '8px 12px',
                            fontSize: '14px'
                          }
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Complemento:
                </Typography>
                <Controller
                  name="additional_details"
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <TextField
                          {...field}
                          helperText={errors?.additional_details?.message}
                          error={!!errors?.additional_details ? true : false}
                          fullWidth
                          inputProps={{
                            style: {
                              padding: '8px 12px',
                              fontSize: '14px'
                            }
                          }}
                        />
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Bairro:
                </Typography>
                <Controller
                  name="neighborhood"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        helperText={errors?.neighborhood?.message}
                        error={!!errors?.neighborhood ? true : false}
                        fullWidth
                        inputProps={{
                          style: {
                            padding: '8px 12px',
                            fontSize: '14px'
                          }
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Cidade:
                </Typography>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        helperText={errors?.city?.message}
                        error={!!errors?.city ? true : false}
                        fullWidth
                        inputProps={{
                          style: {
                            padding: '8px 12px',
                            fontSize: '14px'
                          }
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <Typography variant="subtitle1" sx={purpleText}>
                  Estado:
                </Typography>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        helperText={errors?.state?.message}
                        error={!!errors?.state ? true : false}
                        fullWidth
                        inputProps={{
                          style: {
                            padding: '8px 12px',
                            fontSize: '14px'
                          }
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Button type="submit" sx={changeAddressBtn} onClick={handleChangeAddress}>
              Confirmar
            </Button>
          </form>
        )}
      </CardContent>
    </Card>
  );
}
