import { SvgIcon } from '@mui/material';

function AriOkIcon() {
  return (
    <SvgIcon viewBox="0 0 94 95" fill="none" style={{ fontSize: 80 }}>
      <path d="M17.5 94.5H0L30 22L38.5 45L17.5 94.5Z" fill="#A547FF" />
      <path d="M92 95H72.5L38.5 0H57L92 95Z" fill="#A547FF" />
      <path
        d="M83.4259 13.037C85.7092 10.4675 89.5654 10.3073 92.039 12.6791C94.5126 15.051 94.6668 19.0567 92.3835 21.6261L55.8121 62.7812C53.464 65.4236 49.4713 65.5065 47.0233 62.9637L31.7853 47.1348C29.4049 44.6622 29.4049 40.6533 31.7853 38.1807C34.1656 35.7081 38.0249 35.7081 40.4052 38.1807L51.1575 49.3498L83.4259 13.037Z"
        fill="#54C556"
      />
    </SvgIcon>
  );
}

export default AriOkIcon;
