import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Modal,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  Divider,
  Fade,
  Backdrop,
  CardHeader
} from '@mui/material';
import HelpRounded from '@mui/icons-material/HelpRounded';
import Close from '@mui/icons-material/Close';
import { container, cardContainer, containerTitle, titleStyle, subtitleGreenStyle, dividerStyle } from './styles';

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpRounded color="info" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={container}>
            <Box sx={containerTitle}>
              <Typography variant="h5" color="primary" sx={titleStyle}>
                Ficou com alguma dúvida sobre como saber o valor(es) do(s) meu(s) acessório(s)?
              </Typography>
              <IconButton color="default" aria-label="upload picture" component="span" onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={6} xs={12}>
                <Card sx={cardContainer}>
                  <Typography variant="h6" sx={subtitleGreenStyle}>
                    Valor(es) do(s) meu(s) acessório(s)
                  </Typography>
                  <Divider sx={dividerStyle} />
                  <CardContent>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>Bicicleta e Bicicleta Elétrica:</span> a seguradora aceita
                      apenas 4 tipos de acessórios: GPS, ciclocomputador, velocímetro e odômetro. Outros acessórios não
                      serão cobertos. A indenização máxima será de até 10% do valor total da bike e a cobertura é válida
                      somente em caso de sinitro total (Roubo de acessórios + bike).
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
