import { useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Box, Button, FormControl, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import * as styles from './styles';
import { InputProps } from './types';
import { useDropzone } from 'react-dropzone';
import FileIcon from '~/assets/icons/File';
import FileImage from '~/assets/icons/FileImage';
import { fileToBase64 } from '~/utils/fileToBase64';
import { IFile } from '~/services/modules/contract/types';

export default function CustomizedInputs({ name, label, onChangeFile, error, ...props }: InputProps) {
  const [existFile, setExistFile] = useState(false);
  const {
    getRootProps,
    getInputProps,
    rootRef, // Ref to the `<div>`
    inputRef // Ref to the `<input>`
  } = useDropzone({
    onDrop: async (files) => {
      setExistFile(true);
      if (onChangeFile) {
        await fileToBase64(files[0]).then((res) => {
          const file = res?.split(',')[1];
          onChangeFile({
            Name: files[0].name,
            File: file as string
          });
        });
      }
    }
  });
  return (
    <Box>
      <Typography sx={styles.labelStyle} textAlign="center">
        {label}
      </Typography>
      <FormControl variant="standard" sx={styles.container}>
        <Box sx={styles.input(existFile, error)} {...getRootProps()}>
          <input {...getInputProps()} />
          <Box sx={styles.icon}>
            {!existFile && <FileIcon />}
            {existFile && <FileImage />}
          </Box>
          <Typography variant="body1" sx={styles.inputText}>
            {!existFile && 'Clique para fazer o carregamento do arquivo'}
            {existFile && 'Clique para substituir esse arquivo'}
          </Typography>
        </Box>
      </FormControl>
    </Box>
  );
}
