import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div<{ color: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.25rem;
  width: 100%;
  max-width: fit-content;
  height: auto;
  background-color: #1e1e1e;
  border: ${({ color }) => `2px solid ${color}`};
  border-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ color }) => color};
`;

export const Message = styled.div`
  font-size: 1rem;
  font-weight: normal;
  margin-left: 2.5rem;
`;
