import Colors from '~/styles/colors';

export const container = {
  position: 'absolute',
  top: 32,
  left: '50%',
  transform: 'translate(-50%, 0px)',
  background: '#121212',
  border: '1.2px solid #323232',
  borderRadius: 6,
  padding: 2,
  width: '87%'
};

export const titleStyle = {
  fontWeight: 'bold',
  marginBottom: 3,
  fontSize: { xs: 18, sm: 20, md: 24 }
};
export const dividerStyle = {
  marginX: 1
};
export const subtitleGreenStyle = {
  fontWeight: 'bold',
  fontSize: 18,
  color: Colors.info?.main || '#03DAC5',
  textAlign: 'center',
  marginBottom: 1
};
export const cardContainer = {
  borderRadius: 4,
  background: '#1e1e1e',
  padding: 1
};
export const containerTitle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};
