export const container = {
  marginTop: 4,
  marginBottom: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};
export const center = {
  marginTop: 2,
  marginBottom: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
};
export const title = {
  fontWeight: 'bold'
};
export const description = {
  marginTop: 2,
  marginBottom: 3,
  maxWidth: 400
};
export const textDesc = {
  maxWidth: 270,
  textAlign: 'center'
};
