import { Box, CircularProgress, Typography } from '@mui/material';
import { FinancialData } from '../../interfaces/financial-data';
import { bodyContainer, purplePrice, tip, totalContainer } from '../../styles';

interface Props {
  financialInfos: FinancialData | undefined;
  isLoading: boolean;
}

export function RenewalHeader({ financialInfos, isLoading }: Props) {
  if (!financialInfos) return null;

  return (
    <Box sx={bodyContainer}>
      <Box sx={totalContainer}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="60%">
          <Typography variant="subtitle2">Encargos financeiros</Typography>
          <Typography variant="subtitle1">
            {isLoading ? <CircularProgress size={20} /> : `R$${financialInfos.financial_charges}`}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="60%">
          <Typography variant="subtitle2" sx={purplePrice}>
            Valor total
          </Typography>
          <Typography variant="subtitle1" sx={purplePrice}>
            {isLoading ? <CircularProgress size={24} /> : `${financialInfos.installments}x R$${financialInfos.total}`}
          </Typography>
        </Box>
        <Typography variant="subtitle2" sx={tip}>
          (Valor a ser pago mensalmente no cartão de crédito)
        </Typography>
      </Box>
    </Box>
  );
}
