export const headerContainer = {
  display: 'flex',
  paddingLeft: 2,
  paddingRight: 2,
  alignItems: 'flex-start'
};
export const descriptionContainer = {
  display: 'flex',
  marginTop: 2,
  alignItems: 'flex-start'
};
export const iconGoback = {
  marginRight: 1
};
export const container = {
  marginTop: 4,
  marginBottom: 4
};
export const description = {
  textAlign: 'center',
  color: '#A547FF',
  fontWeight: 'bold'
};
export const subTitle = {
  cursor: 'pointer',
  marginTop: 1,
  textAlign: 'center',
  color: '#03DAC5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
export const helpIcon = {
  marginRight: 1
};
export const containerButton = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 2
};
export const containerAmount = {
  marginTop: 2
};
export const amountStyle = {
  color: '#A547FF',
  fontWeight: 'bold'
};
export const totalStyle = {
  marginTop: 1,
  fontWeight: 'bold'
};
export const descritionTitle = {
  fontSize: 16,
  textAlign: 'center',
  marginBottom: 1
};
export const butttonStyle = {
  marginTop: 2
};
export const containerForm = {
  width: '100%'
};
