import { useState, useEffect } from 'react';
import SidebarMesages from '~/components/PersonalCoverage/SidebarMesages';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  InputAdornment
} from '@mui/material';
import * as styles from './styles';
import Input from '~/components/Input';
import InputSelect from '~/components/InputSelect';
import IOSSwitch from '~/components/IOSSwitch';
import { useNavigate } from 'react-router-dom';
import schema from './validation';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStore, { storeState } from '~/store';
import { ContractPostEquipment, Manufacturer } from '~/services/modules/contract/types';
import { LoadingButton } from '@mui/lab';
import ModalHelperSerieNumber from '~/modals/ModalHelpSerieNumber';
import searchNameEquipment from '~/utils/searchNameEquipment';
import { NumberFormatCustom } from '~/components/Chat/AccessoriesValue';

const InternationalCoverageItems = [
  {
    value: '1',
    label: 'Não contratada'
  },
  {
    value: '2',
    label: 'América do Sul e América Central'
  },
  {
    value: '3',
    label: 'América do Norte e Europa'
  },
  {
    value: '4',
    label: 'África, Ásia, Oceania e Antártida'
  },
  {
    value: '5',
    label: 'Qualquer Continente'
  }
];

const MaterialsItems = [
  {
    value: '1',
    label: 'Aço ou alumínio'
  },
  {
    value: '2',
    label: 'Carbono'
  }
];

export default function HelpZipcodePage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    manufacturers,
    contractAsyncGetManufacturer,
    contractAsyncPostEquipment,
    contract_id,
    cameFromEmail,
    quote,
    insured_item
  } = useStore((store) => ({
    contractAsyncGetManufacturer: store.contractAsyncGetManufacturer,
    contractAsyncPostEquipment: store.contractAsyncPutEquipment,
    manufacturers: store.manufacturers,
    contract_id: store.contract_id,
    cameFromEmail: store.cameFromEmail,
    quote: store.quote,
    insured_item: store.insured_item
  }));

  const [defaultValues, setDefaultValues] = useState({
    typeEquipment: searchNameEquipment(quote.equipment),
    value: quote.equipmentValue,
    accessoriesValue: quote.accessoriesValue,
    isNewEquipment: quote.isNewEquipment,
    hasInvoice: quote.hasInvoice,
    yearOfManufacture: String(quote.yearOfManufacture),
    equipmentMaterial: String(quote.equipmentMaterial),
    internationalCoverage: String(quote.continents),
    participatesCompetitions: quote.participatesInCompetitions
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm<ContractPostEquipment>({
    resolver: yupResolver(schema)
  });
  const formatItemSelect = (items: Array<Manufacturer>) => {
    const newItems = items?.map((item: Manufacturer) => ({
      value: item.id,
      label: item.name
    }));
    return newItems;
  };
  const formatItemYears = () => {
    const year = new Date().getFullYear();
    const newItems = [];
    for (let index = year; index > year - 10; index--) {
      newItems.push({
        value: index.toString(),
        label: index.toString()
      });
    }
    return newItems;
  };

  const onSubmit: SubmitHandler<ContractPostEquipment> = async (data) => {
    if (contract_id) {
      setLoading(true);
      try {
        await contractAsyncPostEquipment(contract_id, {
          ...data,
          manufacturer_id: String(data.manufacturer_id),
          year_of_manufacture: cameFromEmail ? insured_item.year_of_manufacture : quote.yearOfManufacture,
          is_new: cameFromEmail ? insured_item.is_new : quote.isNewEquipment
        });
        setLoading(false);
        navigate('/contract/file');
      } catch (error: any) {
        setLoading(false);
        let errorMessage =
          'Não foi possível prosseguir a sua assinatura no momento. Por favor, tente novamente mais tarde.';
        if (error.isAxiosError) {
          const notifications = error.response?.data?.notifications;
          if (notifications) errorMessage = notifications[0]?.message;
        }
        storeState.addToast({
          title: 'Error',
          message: errorMessage,
          type: 'error'
        });
      }
    } else {
      storeState.addToast({
        title: 'Não foi possível recuperar o contrato.',
        message: '',
        type: 'warning'
      });
    }
  };

  useEffect(() => {
    contractAsyncGetManufacturer();
  }, []);

  useEffect(() => {
    if (cameFromEmail) {
      setDefaultValues({
        typeEquipment: insured_item.type_insured_item,
        value: insured_item.insured_amount,
        accessoriesValue: insured_item.total_value_accessories,
        isNewEquipment: insured_item.is_new,
        hasInvoice: insured_item.has_invoice,
        yearOfManufacture: String(insured_item.year_of_manufacture),
        equipmentMaterial: String(insured_item.equipment_material_type),
        internationalCoverage: String(insured_item.international_coverage),
        participatesCompetitions: insured_item.participates_competition
      });
    }
  }, [cameFromEmail]);

  return (
    <Container maxWidth="lg" sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent>
              <Box sx={styles.headerContainer}>
                <Typography variant="h6" align="center">
                  Dados para contrato
                </Typography>
              </Box>
              <Divider variant="middle" />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={styles.containerForm}>
                  <Typography sx={{ fontSize: { xs: 16, sm: 20 } }} variant="h6" fontWeight="bold">
                    3 - Detalhes do Equipamento
                  </Typography>
                  <Input
                    name="typeEquipment"
                    label="Tipo de Equipamento"
                    placeholder="Patinete Elétrico"
                    value={defaultValues.typeEquipment}
                    disabled
                  />
                  <Input
                    name="value"
                    label="Valor"
                    placeholder="R$ 3.000,00"
                    value={defaultValues.value}
                    startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                    inputComponent={NumberFormatCustom as any}
                    disabled
                  />
                  <Input
                    name="accessoriesValue"
                    label="Valor total do(s) acessório(s)"
                    value={defaultValues.accessoriesValue}
                    startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                    inputComponent={NumberFormatCustom as any}
                    disabled
                  />
                  <FormGroup sx={styles.switchStyle}>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} onChange={() => {}} />}
                      checked={defaultValues.isNewEquipment}
                      disabled
                      label="É um equipamento novo (máximo 90 dias da data da compra)."
                    />
                  </FormGroup>
                  <FormGroup sx={styles.switchStyle}>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} onChange={() => {}} />}
                      checked={defaultValues.hasInvoice}
                      disabled
                      label="Eu tenho a nota fiscal."
                    />
                  </FormGroup>
                  <InputSelect
                    name="yearOfManufacture"
                    label="Ano de Fabricação"
                    items={formatItemYears()}
                    setValue={() => {}}
                    defaultValue={defaultValues.yearOfManufacture}
                    disabled
                  />
                  <InputSelect
                    name="brand"
                    label="Marca"
                    placeholder="Escolha a marca"
                    items={formatItemSelect(manufacturers)}
                    setValue={(value) => {
                      setValue('manufacturer_id', value as string);
                    }}
                    error={errors?.manufacturer_id ? true : false}
                    helperText={errors?.manufacturer_id?.message}
                  />
                  <Controller
                    name="model"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Modelo"
                          placeholder="MR255"
                          error={errors?.model ? true : false}
                          isDirty={dirtyFields.model}
                          helperText={errors?.model?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="serial_number"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Número de Série"
                          placeholder="XXXXXXXXXX"
                          error={errors?.serial_number ? true : false}
                          isDirty={dirtyFields.serial_number}
                          helperText={errors?.serial_number?.message}
                          IconHelper={ModalHelperSerieNumber}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <InputSelect
                    name="equipmentMaterial"
                    label="Material do Quadro"
                    items={MaterialsItems}
                    setValue={() => {}}
                    defaultValue={defaultValues.equipmentMaterial}
                    disabled
                  />
                  <InputSelect
                    name="internationalCoverage"
                    label="Cobertura Internacional"
                    items={InternationalCoverageItems}
                    setValue={() => {}}
                    defaultValue={defaultValues.internationalCoverage}
                    disabled
                  />
                  <FormGroup sx={styles.switchStyle}>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} onChange={() => {}} />}
                      checked={defaultValues.participatesCompetitions}
                      disabled
                      label="Participa de competição?"
                    />
                  </FormGroup>
                </Box>
                <Box sx={styles.containerButton}>
                  <LoadingButton sx={{ fontSize: { xs: 16, sm: 20 } }} loading={loading} type="submit">
                    salvar e sair
                  </LoadingButton>
                  <LoadingButton
                    sx={{ fontSize: { xs: 16, sm: 20 } }}
                    loading={loading}
                    variant="contained"
                    type="submit"
                  >
                    Próximo passo
                  </LoadingButton>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            display: {
              xs: 'none',
              lg: 'block'
            }
          }}
        >
          <Card>
            <CardContent>
              <SidebarMesages showProduct={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
