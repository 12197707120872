import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Routes from './routes';
import Theme from './styles/theme';
import { ToastContainer } from './components';
import 'moment/locale/pt-br';
import './assets/fonts/Poppins/Poppins-Regular.ttf';
import { Provider } from '@rollbar/react';

function App() {
  const rollbarConfig = {
    accessToken: '14a86f600e4344399af1bcb3276882d2',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.NODE_ENV || 'development',
    payload: {
      client: {
        javascript: {
          code_version: '1.0.0',
          source_map_enabled: true
        }
      }
    }
  };

  return (
    <Provider config={rollbarConfig}>
        <BrowserRouter>
          <Theme>
            <CssBaseline />
            <Routes />
            <ToastContainer autoClose={5000} position="bottom-center" />
          </Theme>
        </BrowserRouter>
    </Provider>
  );
}

export default App;
