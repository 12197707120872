import React, { forwardRef, useEffect, useState } from 'react';
import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  FormControl,
  OutlinedInputProps,
  InputBaseComponentProps
} from '@mui/material';
import MaskedInput from 'react-text-mask';
import { labelStyle, container, inputStyle, helperStyle } from './styles';
import { InputProps, TextMaskCustomProps } from './types';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import { regexCardNumber, expiredDate, cvvRegex, CEPRegex, regexCPF, regexPhone } from './regex';

interface CustomProps {
  onChange?: (event: { target: { name: string; value: string } }) => void;
  name?: string;
  mask: InputProps['mask'];
  error?: boolean;
}

const TextMaskCustom = forwardRef(function TextMaskCustom({ mask, ...props }: CustomProps, ref) {
  const selectMask = () => {
    switch (mask) {
      case 'cpf':
        return regexCPF;
      case 'phone':
        return regexPhone;
      case 'zipcode':
        return CEPRegex;
      case 'cardNumber':
        return regexCardNumber;
      case 'expiredDate':
        return expiredDate;
      case 'cvv':
        return cvvRegex;
      default:
        return regexCPF;
    }
  };
  return (
    <MaskedInput
      {...props}
      mask={selectMask()}
      placeholderChar={'\u2000'} // showMask
    />
  );
});

const ErrorIcon: React.FC = () => (
  <>
    <InputAdornment position="end">
      <ErrorSharpIcon color="error" />
    </InputAdornment>
  </>
);
const SuccessIcon: React.FC = () => (
  <>
    <InputAdornment position="end">
      <CheckCircleSharpIcon color="success" />
    </InputAdornment>
  </>
);
const NoIcon: React.FC = () => (
  <>
    <InputAdornment position="end"></InputAdornment>
  </>
);

const CustomizedInputs = forwardRef(function CustomizedInputs(
  { name, label, helperText, isDirty, IconHelper, ...props }: InputProps,
  ref
) {
  const inputProps: Partial<OutlinedInputProps> = props;
  if (props.mask) {
    inputProps.inputComponent = TextMaskCustom as any;
    inputProps.inputProps = {
      mask: props.mask,
      error: props.error
    };
  }
  const [IconComponent, setIconComponent] = useState<React.FC>(NoIcon);
  useEffect(() => {
    if (!isDirty) {
      setIconComponent(NoIcon);
    } else if (helperText && props.error) {
      setIconComponent(ErrorIcon);
    } else {
      setIconComponent(SuccessIcon);
    }
  }, [isDirty, props.error, props.value]);
  return (
    <FormControl variant="standard" sx={container}>
      <InputLabel
        shrink
        htmlFor={`input-${name}`}
        sx={{ ...labelStyle, whiteSpace: 'break-spaces', position: 'static' }}
      >
        {label}
        {IconHelper && <IconHelper />}
      </InputLabel>
      <>
        <OutlinedInput fullWidth sx={inputStyle} endAdornment={IconComponent} {...inputProps} ref={ref} />
        {helperText && props.error && (
          <>
            <FormHelperText sx={helperStyle}>{helperText}</FormHelperText>
          </>
        )}
      </>
    </FormControl>
  );
});
export default CustomizedInputs;
