export const containerMessage = {
  backgroundColor: '#1E1E1E',
  border: '1px solid #323232',
  borderRadius: '16px 16px 16px 0px',
  color: '#FFFFFF',
  padding: 1,
  marginTop: 1,
  minWidth: 150,
  maxWidth: '90%',
  borderBottomLeftRadius: 0,
  position: 'relative'
};
export const messageAfter = {
  position: 'absolute',
  clip: 'rect(auto, 40px, auto, 20px)',
  transform: 'rotate(-130deg)',
  bottom: -30,
  left: -12,
  width: '32px',
  height: '32px',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-15px',
    bottom: '-40px',
    left: '-15px',
    right: '3px',
    background: '#1E1E1E',
    transform: 'rotate(-45deg)',
    border: '1px solid #323232'
  }
};
export const messageStyle = (bold: boolean) => ({
  fontWeight: bold ? 'bold' : 'normal',
  display: 'flex',
  alignItems: 'center'
});
