import * as yup from 'yup';

export const equipmentSchema = yup
  .object({
    equipment_value: yup
      .number()
      .moreThan(1000, 'O preço deve ser maior que R$1.000,00')
      .required('Preço é obrigatório'),
    value_of_accessories: yup.string()
  })
  .required();

export const cardSchema = yup
  .object({
    card_number: yup
      .string()
      .required('Número do cartão é obrigatório')
      .test(`test-card-number-length`, 'Digite o número completo, este parece incompleto.', (value) => {
        if (value) {
          const newValue = value.replace(/\s/g, '');
          if (newValue.length > 15) return true;
        }
        return false;
      }),
    card_cvv: yup.string().min(3, '3 a 5 dígitos').required('Digite um CVV válido (3 a 5 dígitos).'),
    card_expiration_date: yup
      .string()
      .required('Data de expiração do cartão é obrigatório')
      .test(`test-expiry-length`, 'Digite uma data válida (mês/ano).', (value) => {
        if (value) {
          const date = new Date().getFullYear().toString().substring(-2);
          const month = parseInt(value.substring(0, 2));
          const year = parseInt(value.substring(3, 5));
          if (month > 12) {
            return false;
          }
          const newYear = parseInt(date) - 2000;
          if (year < newYear) {
            return false;
          }
        }
        return true;
      }),
    card_holder_name: yup
      .string()
      .matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/, 'Insira apenas letras')
      .required('Nome no cartão é obrigatório')
      .test(`test-name-complete-length`, 'Digite o nome completo, este parece incompleto.', (value) => {
        if (value && value.split(' ').length > 1) return true;
        return false;
      })
  })
  .required();

export const addressSchema = yup.object({
  street: yup.string().required('Nome da rua é obrigatório'),
  number: yup.string().required('Número é obrigatório'),
  additional_details: yup.string(),
  neighborhood: yup.string().required('Bairro é obrigatório'),
  city: yup.string().required('Nome da cidade é obrigatório'),
  state: yup.string().required('Nome do estado é obrigatório'),
  zipcode: yup.string().required('CEP é obrigatório')
});
