import { Container, Grid, Card, CardContent, Typography, Divider, Box } from '@mui/material';
import SidebarMesages from '~/components/PersonalCoverage/SidebarMesages';
import * as styles from '../styles';

export function VoucherError() {
  return (
    <Container maxWidth="lg" sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent>
              <Box sx={styles.headerContainer}>
                <Typography variant="h6" align="center" fontWeight="bold">
                  Fotos para o contrato
                </Typography>
              </Box>
              <Divider variant="middle" />
              <Box sx={styles.containerForm}>
                <Typography variant="h6" fontWeight="bold">
                  4 - Fotos do Equipamento
                </Typography>
                <Typography sx={styles.descritionTitle}>
                  *Atenção! É muito importante observar a qualidade das fotografias e a nitidez dos documentos
                  digitalizados. A baixa qualidade dos documentos anexados poderá implicar na recusa por parte da
                  seguradora da aceitação do seu seguro. Não deixe que isso aconteça
                </Typography>
                <Typography>
                  <Typography sx={styles.warningText} component={'span'}>
                    IMPORTANTE:{' '}
                  </Typography>
                  É necessário enviar um voucher junto com a foto da visão geral, escrito em um papel fixado no
                  equipamento. Porém, o servidor falhou em processar o valor do seu voucher. Por favor, tente novamente
                  mais tarde.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card>
            <CardContent>
              <SidebarMesages showProduct={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
