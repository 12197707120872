import { useEffect, useState } from 'react';
import { CircularProgress, Container, Card, Grid, Box, CardContent, Typography, Divider } from '@mui/material';
import { headerContainer, container, containerForm } from './styles';
import useStore from '~/store';
import { Step } from '~/store/slices/contract/types';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function HelpZipcodePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const { contractAsyncGetContext, contractAsyncGetContractById, step, setContractId, setCameFromEmail } = useStore(
    (store) => ({
      contractAsyncGetContext: store.contractAsyncGetContext,
      contractAsyncGetContractById: store.contractAsyncGetContractById,
      setContractId: store.setContractId,
      setCameFromEmail: store.setCameFromEmail,
      step: store.step
    })
  );

  useEffect(() => {
    const fetchData = async () => {
      const contract_id = searchParams.get('cid');
      if (contract_id) {
        setContractId(+contract_id);
        try {
          await contractAsyncGetContractById(+contract_id);
          await contractAsyncGetContext(+contract_id);
        } catch (err: any) {
          let errorMessage = 'Não foi possível recuperar os dados do contrato';
          if (err.isAxiosError) {
            const notifications = err.response?.data?.notifications;
            if (notifications) errorMessage = notifications[0]?.message;
          }
          setError(errorMessage);
        }
      } else {
        setError('Não foi possível recuperar os dados do contrato');
      }

      const source = searchParams.get('source');
      if (source === 'email') {
        setCameFromEmail(true);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (step && step !== 'none') {
      switch (step) {
        // case 'INSURED_INFORMATION':
        //   navigate('/contract/file')
        // break
        case 'INSURED_ADDRESS':
          navigate('/contract/address');
          break;
        case 'INSURED_ITEM':
          navigate('/contract/equipment');
          break;
        case 'INSURED_DOCUMENT':
          navigate('/contract/file');
          break;
        case 'INSURED_EMERGENCY_DATA':
          navigate('/contract/emergency');
          break;
        default:
          navigate('/contract/user');
          break;
      }
    }
  }, [step]);
  return (
    <Container maxWidth="lg" sx={container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent>
              <Box sx={headerContainer}>
                <Typography variant="h6" align="center">
                  Dados para contrato
                </Typography>
              </Box>
              <Divider variant="middle" />
              <Box sx={containerForm}>
                {!error && (
                  <>
                    <CircularProgress />
                  </>
                )}
                {error && (
                  <>
                    <Typography>{error}</Typography>
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
