export type TermOfContractData = {
  titulo: {
    title: string;
    data: string[];
  };
  'termos de uso': {
    title: string;
    data: string[];
    disclaimer: {
      title: string;
      data: string[];
    };
  };
  'disposições gerais': {
    title: string;
    data: string[];
  };
  'politica de preços': {
    title: string;
    data: string[];
  };
  'uso do website': {
    title: string;
    data: string[];
  };
  'politica de privacidade': {
    title: string;
    data: string[];
    'atualizacao desses termos': {
      title: string;
      data: string[];
    };
  };
  footer: {
    data: string[];
  };
};

export const termOfContractData: TermOfContractData = {
  titulo: {
    title: 'Seja bem-vindo(a) ao site da ARIDE. ',
    data: [
      'Esse espaço foi pensado para trazer soluções que facilitem a sua vida no mundo da mobilidade urbana.',
      'Como acontece no trânsito, precisamos de algumas regras e condições, para que tudo funcione corretamente, da forma mais eficiente e clara possível.',
      'Essas regras serão utilizadas para definir os limites do nosso atendimento; das nossas e das suas responsabilidades; quem são os nossos parceiros comerciais; como funciona a nossa política de preços e como tudo isso se encaixa quando você decide utilizar os nossos serviços.',
      'Esse conjunto de normas se chama:'
    ]
  },
  'termos de uso': {
    title: 'Termos de uso',
    data: [
      'A <strong>ARIDE</strong> condiciona a navegação, consulta e utilização deste website aos Termos e Condições abaixo, que estabelecem a forma de utilização do sítio e serviços ofertados.',
      'Ao adquirir os serviços oferecidos pela <strong>ARIDE</strong>, operados por nossos parceiros ou utilizar o website aride.com.br, você concorda na íntegra com os seguintes termos e condições:',
      'Em primeiro lugar, a <strong>ARIDE SOLUCOES EM MOBILIDADE E SERVICOS LTDA</strong>, é pessoa jurídica de direito privado regularmente inscrita no <strong>CNPJ</strong> sob n.º <strong>22.228.185/0001-29</strong>, com a sua sede localizada na RUA DESEMBARGADOR AIRES ITABAIANA, nº 116, Vital Brazil, Niterói/RJ.',
      'A <strong>ARIDE</strong> é proprietária e operadora deste website (<a>www.aride.com.br</a>) e através desse ambiente.',
      'A <strong>ARIDE</strong> é uma plataforma digital que agrupa, intermedia e comercializa serviços e benefícios, atua como estipulante de seguros e distribui aos usuários/proprietários de equipamentos voltados à mobilidade urbana.'
    ],
    disclaimer: {
      title: 'IMPORTANTE: A ARIDE NÃO É UMA SEGURADORA!',
      data: [
        'A Aride fornece e comercializa apenas pacotes de serviço, benefícios e seguros exclusivos e que podem ser customizados de acordo com o interesse dos clientes.',
        'Cada serviço, benefício ou seguro, bem como taxas administrativas, custos financeiros e encargos de cobrança compõem o valor da sua assinatura mensal e são discriminados no seu certificado ARIDE'
      ]
    }
  },
  'disposições gerais': {
    title: 'Disposições gerais',
    data: [
      'Este Termo de Uso é regido pelas leis do país onde o serviço foi contratado.',
      'Quaisquer litígios decorrentes deste contrato serão submetidos à jurisdição exclusiva dos tribunais competentes.',
      '<strong>A ARIDE, seus parceiros e fornecedores</strong> reservam-se o direito de modificar este Termo de Uso, mediante aviso prévio aos segurados e usuários.',
      'Ao adquirir o pacote de serviços (Ex: seguro bike + Assistência + central de atendimento) oferecido pela <strong>ARIDE</strong> em parceria com a Seguradora Akad ou utilizar o website aride.com.br, você reconhece que leu, compreendeu e concorda em ficar vinculado com os termos e condições estabelecidos neste documento.'
    ]
  },
  'politica de preços': {
    title: 'Política de preços',
    data: [
      'Os preços praticados pela ARIDE pelos serviços / produtos estão listados no website. A ARIDE se reserva o direito de alterar os preços exibidos a qualquer momento, corrigir erros de precificação que possam ocorrer inadvertidamente, incluir ou excluir quaisquer tipos de desconto, bem como conceder condições especiais a determinados indivíduos ou grupos de indivíduos, de acordo com os seus critérios discricionários de conveniência e oportunidade.',
      'A ARIDE poderá, sem aviso prévio, alterar os serviços; deixar de fornecer os serviços ou quaisquer recursos dos serviços; ou criar limites para os serviços. A ARIDE poderá rescindir ou suspender, permanentemente ou temporariamente, o acesso aos serviços sem aviso prévio e responsabilidade por qualquer motivo ou sem motivo.',
      'A taxa pelos serviços e quaisquer outros encargos que você possa incorrer em conexão com o uso do serviço, bem como os impostos e possíveis taxas de transação, serão cobrados mensalmente através da forma de pagamento disponível.'
    ]
  },
  'uso do website': {
    title: 'USO DO WEBSITE ARIDE.COM.BR',
    data: [
      'O usuário do website aride.com.br concorda em fornecer informações verdadeiras e atualizadas durante o preenchimento do formulário online ou por meio dos canais de contato disponibilizados.',
      'O usuário concorda em receber mensagens promocionais, materiais por correio, e-mail, SMS, ou qualquer outro formato existente (incluindo seu número de telefone para chamadas ou mensagens de texto). Se você não deseja receber esses materiais promocionais ou avisos - por favor, basta nos notificar a qualquer momento.',
      'A aride.com.br atua como intermediária entre o Usuário e as Seguradoras/Prestadores de Serviço, transmitindo as informações fornecidas pelo Usuário para cálculos de propostas e apólices.',
      'É dever do Usuário agir com boa fé, fornecendo informações verídicas para a cotação do seguro, bem como tomar decisões de aceitação ou recusa da proposta com base nessas informações.',
      'A aride.com.br disponibiliza um Corretor de Seguros habilitado pela Susep para auxiliar o usuário, tirar dúvidas e explicar as coberturas da cotação durante o horário de funcionamento.'
    ]
  },
  'politica de privacidade': {
    title: 'Política de privacidade',
    data: [
      'Prestar nosso Serviço exige a coleta e o uso das suas informações. A <strong>Política de Privacidade</strong> da <strong>ARIDE</strong> explica como coletamos, usamos e compartilhamos informações. Você deve concordar com a nossa Política de Privacidade para usar o <strong>ARIDE</strong>.'
    ],
    'atualizacao desses termos': {
      title: 'Atualização desses termos',
      data: [
        'A ARIDE pode alterar o seu serviço e as suas políticas. Caso seja necessário, a ARIDE poderá alterar estes Termos para que eles reflitam precisamente a descrição dos seus serviços e de suas políticas. Portanto, você deve revisar essas páginas periodicamente. Salvo quando a lei estabelecer o contrário, você será notificado (por exemplo, por meio dos canais de comunicação da ARIDE) antes de quaisquer alterações destes termos e terá a oportunidade de analisá-los antes que entrem em vigor. Por isso, se você continuar usando o Serviço da ARIDE, estará vinculado aos Termos atualizados.',
        'A ARIDE não garante que o conteúdo, os instrumentos e os materiais contidos, utilizados e oferecidos neste website estejam precisamente atualizados ou completos, e não se responsabiliza por danos causados por eventuais erros de conteúdo ou falhas de equipamento.',
        'A ARIDE não se responsabiliza, expressa ou tacitamente, pelo uso indevido das informações, dos instrumentos, dos materiais disponibilizados e/ou dos equipamentos utilizados por este website, para quaisquer que sejam os fins, feito por qualquer usuário, sendo de inteira responsabilidade desse as eventuais lesões a direito próprio ou de terceiros, causadas ou não por esse uso inadequado.',
        'Em nenhuma circunstância, a ARIDE, seus diretores ou funcionários serão responsáveis por quaisquer danos diretos ou indiretos, especiais, incidentais ou de consequências, perdas ou despesas oriundos da conexão com este website ou uso da sua parte ou incapacidade de uso por qualquer parte, ou com relação a qualquer falha de desempenho, erro, omissão, interrupção, defeito ou demora na operação ou transmissão, vírus de computador ou falha da linha ou do sistema, mesmo se a ARIDE ou seus representantes estejam avisados da possibilidade de tais danos, perdas ou despesas.',
        'O adequado provimento de todos os recursos da Internet, sem exceção, é de inteira responsabilidade do usuário do website.',
        'A ARIDE não se responsabiliza pelo conteúdo de outros websites (a) cujos endereços estejam disponíveis nas páginas deste website, ou (b) cujo endereço deste website esteja neles disponível. A ARIDE não garante o ressarcimento de quaisquer danos causados pelos websites nesse item referidos.',
        'Antes de tomar uma decisão de contratação, os potenciais membros devem considerar cuidadosamente estes termos e condições.'
      ]
    }
  },
  footer: {
    data: [
      'Como informado anteriormente, ao contratar nossos serviços, você concorda com os Termos de Uso estabelecidos pela ARIDE. Essas regras são essenciais para garantir um atendimento eficiente e transparente. Agradecemos pela sua confiança e estamos à disposição para ajudá-lo. '
    ]
  }
};
