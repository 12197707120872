import { SignInResponse, UserDetailsResponse } from '../modules/auth/types';
import { LOCAL_KEY, LOCAL_USERDATA_KEY } from '../variables';
import api from '../api';

interface AuthDataResponse {
  authData: SignInResponse | undefined;
  userData: UserDetailsResponse | undefined;
}
class TokenService {
  setAuthData(authData: SignInResponse, userData: UserDetailsResponse) {
    localStorage.setItem(LOCAL_KEY, JSON.stringify(authData));
    localStorage.setItem(LOCAL_USERDATA_KEY, JSON.stringify(userData));
  }

  getAuthData(): AuthDataResponse {
    const authDataString = localStorage.getItem(LOCAL_KEY);
    const authData = authDataString && JSON.parse(authDataString);
    const userDataString = localStorage.getItem(LOCAL_USERDATA_KEY);
    const userData = userDataString && JSON.parse(userDataString);

    return { authData, userData };
  }
  getClientCredencialtoken(): string | null {
    const clientCredential = localStorage.getItem(LOCAL_KEY);
    console.log('getClientCredencialtoken > clientCredential', clientCredential);
    return clientCredential;
  }

  async getClientCredencial(): Promise<string> {
    // const { data } = await api.post<string>('/Authorize/client-credential', {
    //   clientCredential: 'C86A78E9-7F98-4870-95D8-BC661D87D3E8'
    // })
    // localStorage.setItem(LOCAL_KEY, data)
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('clientCredential', process.env.REACT_APP_CLIENT_CREDENTIAL!);
      const response = await api.post('/Authorize/client-credential', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const data = response.data as string;
      console.log('getClientCredencial > data', data);
      // ts-ignore
      localStorage.setItem(LOCAL_KEY, data as string);
      /**ts-igonre */
      return data as string;
    } catch (error: any) {
      console.log(error?.message);
    }
    return 'ok';
  }

  removeAuthData() {
    localStorage.removeItem(LOCAL_KEY);
    localStorage.removeItem(LOCAL_USERDATA_KEY);
  }
}

export default new TokenService();
