import { useEffect, useState } from 'react';
import { InputLabel, Select, FormHelperText, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import { labelStyle, container, inputStyle, helperStyle } from './styles';
import { SelectCustomProps } from './types';

export default function CustomizedInputs({
  name,
  label,
  helperText,
  isDirty,
  value,
  setValue,
  items,
  placeholder,
  error,
  defaultValue,
  disabled
}: SelectCustomProps) {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <FormControl variant="outlined" sx={container}>
      <InputLabel shrink htmlFor={`input-${name}`} sx={labelStyle}>
        {label}
      </InputLabel>
      <>
        <Select
          value={selected}
          sx={inputStyle}
          placeholder="Select a value..."
          onChange={(event: SelectChangeEvent<typeof selected>) => {
            const {
              target: { value: v }
            } = event;
            setSelected(v);
            setValue(v);
          }}
          displayEmpty
          renderValue={(selected) => {
            if (selected === '') {
              return <em>{placeholder}</em>;
            }
            for (let i = 0; i < items.length; i++) {
              if (selected === items[i].value) {
                return items[i].label;
              }
            }
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          disabled={!!disabled}
        >
          {placeholder && (
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {items.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && error && (
          <>
            <FormHelperText sx={helperStyle}>{helperText}</FormHelperText>
          </>
        )}
      </>
    </FormControl>
  );
}
