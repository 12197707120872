const searchNameEquipment = (name: string): string => {
  switch (name) {
    case 'bike':
      return 'Bicicleta';
    case 'ebike':
      return 'Bicicleta elétrica';
    case 'escooter':
      return 'Autopropelido';
    case 'scooter':
      return 'Ciclomotor e Moto Elétrica';
    default:
      return 'Não escolhido';
  }
};

export const searchNameEquipmentId = (name: string): number => {
  switch (name) {
    case 'bike':
      return 1;
    case 'ebike':
      return 2;
    case 'escooter':
      return 3;
    case 'scooter':
      return 4;
    default:
      return 1;
  }
};

export const searchEquipmentNameById = (id: number): string => {
  switch (id) {
    case 1:
      return 'bike';
    case 2:
      return 'ebike';
    case 3:
      return 'escooter';
    case 4:
      return 'scooter';
    default:
      return 'bike';
  }
};

export default searchNameEquipment;
