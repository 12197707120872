import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import Colors from '~/styles/colors';

export const headerContainer = {
  padding: 2,
  alignItems: 'flex-start'
};
export const container = {
  marginTop: 4,
  marginBottom: 4
};
export const labelStyle = {
  color: Colors.primary?.main,
  fontWeight: 'bold',
  fontSize: 18,
  marginBottom: 1
};
export const imageMore = {
  cursor: 'pointer',
  width: '100%',
  backgroundColor: '#1E1E1E',
  borderColor: '#323232',
  borderWidth: 2,
  borderStyle: 'dashed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 1
};
export const containerButton = {
  marginY: 2,
  marginRight: { xs: 0, sm: 2 },
  display: 'flex',
  justifyContent: { xs: 'center', sm: 'flex-end' },
  gap: 2
};
export const descritionTitle = {
  fontSize: 16,
  marginY: 1,
  color: '#03DAC5'
};
export const moreIcon = {
  fontSize: 70,
  marginTop: 1,
  marginBottom: 1,
  color: '#A6A6A6'
};
export const containerForm = {
  width: '100%',
  paddingX: { xs: 1, sm: 2 },
  paddingY: 2
};
export const warningText = {
  color: '#03DAC5'
};
export const voucherStyle = {
  color: '#A547FF',
  fontWeight: 'bold'
};

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    color: '#1e1e1e',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#1e1e1e',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#39393D  ' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));
