import { useState, ChangeEvent } from 'react';
import { Message } from '../types';
import { Box, FormControl, TextField } from '@mui/material';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import { containerMessage, containerInput, inputStyle } from './styles';
import useStore from '~/store';
import schema from './validation';
import { ValidationError } from 'yup';
import { CpfMask } from './input-mask';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  Component?: Message['Component'];
  handleValidateInputs: (key: 'email' | 'name' | 'cpf', isValid: boolean) => void;
}

export const InputCpf = ({ handleValidateInputs }: Props) => {
  const { quote, setCpf } = useStore((store) => ({
    quote: store.quote,
    setCpf: store.SetCpf
  }));
  const { cpf } = quote;

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const validateCpfValue = async (value: string) => {
    try {
      await schema.validate({
        cpf: value
      });
      setHelperText('');
      setError(false);
      handleValidateInputs('cpf', true);
    } catch (err) {
      if (err instanceof ValidationError) {
        setError(true);
        handleValidateInputs('cpf', false);
        setHelperText(err.message);
      }
    }
  };

  const handleChangeCpf = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newCpfValue = event.target.value;

    setCpf(newCpfValue);
    validateCpfValue(newCpfValue);
  };

  return (
    <Box>
      <Box sx={() => containerMessage(error)}>
        <FormControl variant="outlined" sx={containerInput}>
          <TextField
            value={cpf}
            onChange={handleChangeCpf}
            error={error}
            helperText={helperText}
            type="text"
            variant="outlined"
            placeholder="Informe seu cpf"
            sx={inputStyle}
            InputProps={{
              endAdornment: error && <ErrorSharpIcon color="error" />,
              inputComponent: CpfMask as any
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
};
