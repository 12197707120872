import { Container } from '@mui/material';
import ModalCalcSimplifiedCoverage from '~/modals/CalcSimplifiedCoverage';
import { useGetStepPlans } from '~/modals/CalcSimplifiedCoverage/hooks/use-get-plans';
import ErrorPage from '../Error';

export default function BasicModal() {
  const { data: steps, loading, error } = useGetStepPlans();

  if (loading) {
    return <div> Loading </div>;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Container maxWidth="sm">
      <ModalCalcSimplifiedCoverage steps={steps} />
    </Container>
  );
}
