import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup
    .string()
    .min(4, 'Insira no mínimo 4 caracteres')
    .matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/, 'Insira apenas letras')
    .matches(/^\S.*\S$/, 'Insira um nome válido')
    .required()
    .test(`test-name-complete-length`, 'Digite o nome completo.', (value) => {
      if (value && value.split(' ').length > 1) return true;
      return false;
    })
});

export default schema;
