import * as yup from 'yup';

export default yup
  .object({
    name: yup.string().min(7, 'Digite o nome completo, este parece incompleto.').required('Nome é obrigatório'),
    mobile_phone: yup.string().required('Telefone é obrigatório'),
    security_question: yup.string().required('Pergunta de seguranca é obrigatório'),
    call_the_police: yup.boolean(),
    security_answer: yup.string().required('Resposta de seguranca é obrigatório')
  })
  .required();
