import { SwitchProps } from '@mui/material/Switch';
import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

export const headerContainer = {
  padding: 2,
  alignItems: 'flex-start'
};
export const container = {
  marginTop: 4,
  marginBottom: 4
};
export const buttonLeft = {
  marginRight: 2
};
export const switchStyle = {
  marginTop: 2,
  marginBottom: 2
};
export const containerButton = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 2
};
export const descritionTitle = {
  fontSize: 16,
  textAlign: 'center',
  marginBottom: 1
};
export const containerForm = {
  width: '100%',
  padding: 2
};
