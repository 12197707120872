import { MotionStyle } from 'framer-motion';

export const messageBubbleContainer = (type: string): MotionStyle => ({
  margin: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: type !== 'us' ? 'flex-end' : 'flex-start'
});
export const descritionTitle = {
  fontSize: 16,
  textAlign: 'center',
  marginBottom: 1,
  marginTop: 1
};
export const divider = {
  marginBottom: 2,
  marginTop: 2
};
export const amountDivider = {
  marginTop: 2,
  marginBottom: 1,
  borderColor: '#323232'
};

export const clubDivider = {
  marginTop: 1,
  marginBottom: 1,
  borderColor: '#323232'
};

export const containerAmount = {
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: 4,
  alignItems: 'center'
};
export const totalStyle = {
  fontWeight: 'bold'
};

export const amountStyle = {
  color: '#A547FF',
  fontWeight: 'bold'
};

export const containerMessage = {
  backgroundColor: '#1E1E1E',
  border: '1px solid #A547FF',
  borderRadius: 4,
  color: '#FFFFFF',
  padding: 1,
  marginTop: 1,
  minWidth: 180,
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
};
export const productName = {
  fontSize: 16
};

export const FinancialCharges = {
  display: 'flex',
  alignItems: 'center',
  gap: 0.2
};
