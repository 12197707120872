import { Message } from '../types';
import { Box, Grid, Radio, RadioGroup, FormControl } from '@mui/material';
import { Bike, EBike, EScooter, Scooter } from '~/assets/icons';
import { containerMessage, messageAfter } from './styles';
import RadioGroupCustom from './RadioComponentCustom';
import { IEQUIPMENT } from './types';
import useStore from '~/store';
import { handleNextStep } from '~/modals/CalcSimplifiedCoverage/utils/handleSteps';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  Component?: Message['Component'];
  steps?: number[];
}

const EQUIPMENTS: IEQUIPMENT[] = [
  {
    label: 'Bicicleta',
    value: 'bike',
    description: '* Nova (máx. 30 dias) ou até 10 anos',
    Icon: () => <Bike />
  },
  {
    label: 'Bicicleta Elétrica',
    value: 'ebike',
    description: '* Nova (máx. 30 dias) ou até 10 anos',
    Icon: () => <EBike />
  },
  {
    label: 'Autopropelido',
    value: 'escooter',
    description: '* Novo (máx. 60 dias)',
    Icon: () => <EScooter />
  },
  {
    label: 'Ciclomotor e Moto Elétrica',
    value: 'scooter',
    description: '* Novo(a) (máx. 60 dias)',
    Icon: () => <Scooter />
  }
];

export const TypesOfEquipment = ({ steps }: Props) => {
  const { stepChat, equipment, setEquipment, setStepChat } = useStore((store) => ({
    stepChat: store.quote.stepChat,
    equipment: store.quote.equipment,
    setEquipment: store.setEquipment,
    setStepChat: store.setStepChat
  }));

  const handleChange = (event: string | React.ChangeEvent<HTMLInputElement>) => {
    if (typeof event === 'string') {
      setEquipment(event);

      return;
    } else {
      setEquipment((event.target as HTMLInputElement).value);
    }
  };
  return (
    <Box sx={() => containerMessage()}>
      <FormControl>
        <RadioGroup aria-labelledby="radio-button-type-equipment" value={equipment}>
          <Grid container spacing={1}>
            {EQUIPMENTS.map((item: IEQUIPMENT) => (
              <Grid item xs={6} key={item.value}>
                <RadioGroupCustom
                  equipment={equipment}
                  control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
                  handleChange={handleChange}
                  {...item}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box sx={messageAfter} />
    </Box>
  );
};
