import { Box, Typography } from '@mui/material';
import arideSuccess from '../../../../assets/images/aride-success.png';
import { purpleText } from '../styles';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '~/services/api';
import { storeState } from '~/store';

export function RenewalSuccess() {
  const { contractId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      const { data } = await api.get(`/Contracts/${contractId}/download`);

      const downloadLink = window.document.createElement('a');
      downloadLink.href = `data:application/pdf;base64,${data.data}`;
      downloadLink.download = 'contrato.pdf';

      downloadLink.click();
      downloadLink.remove();

      storeState.addToast({ type: 'success', title: 'Sucesso!', message: 'Download finalizado com sucesso!' });
    } catch (e) {
      storeState.addToast({
        type: 'error',
        title: 'Algo deu errado!',
        message: 'Não foi possível fazer o download do contrato, tente novamente mais tarde!'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        paddingTop: '8rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 520
      }}
    >
      <img width={200} src={arideSuccess} />
      <Typography variant="h3" fontSize={24} fontWeight="bold" paddingTop="1rem" textAlign="center">
        Pagamento aprovado!
      </Typography>
      <Typography
        variant="h1"
        sx={{
          ...purpleText,
          fontSize: 36,
          fontWeight: 500,
          padding: 2
        }}
      >
        Renovado
      </Typography>
      <Typography variant="subtitle2" fontSize={20} textAlign="center" fontWeight="300">
        Seus dados foram aprovados! Estamos muito felizes por continuar sendo nosso cliente!
      </Typography>

      <LoadingButton
        variant="contained"
        onClick={handleDownload}
        disabled={isLoading}
        sx={{ minWidth: '60%', marginTop: 2 }}
        loading={isLoading}
      >
        Baixar contrato
      </LoadingButton>
    </Box>
  );
}
