import { ModalHeaderProps } from './types';
import { Box, Typography, IconButton, Divider } from '@mui/material';
import * as styles from './styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  visibleGoBack,
  disableGoBack = false,
  onClickClose,
  onClickBack
}) => (
  <>
    <Box sx={styles.container}>
      {visibleGoBack && (
        <IconButton
          disabled={disableGoBack}
          color="default"
          aria-label="Go back to previous step"
          onClick={() => onClickBack()}
        >
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
      )}
      {!visibleGoBack && <div />}

      <Typography variant="h6" component="h2">
        {title}
      </Typography>
      <IconButton color="default" aria-label="Close modal" component="span" onClick={() => onClickClose()}>
        <CloseIcon />
      </IconButton>
    </Box>
    <Divider sx={styles.divider} />
  </>
);

export default ModalHeader;
