/* eslint-disable @typescript-eslint/ban-ts-comment */
import { render } from 'react-dom';

/* eslint-disable prefer-const */
export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject): string => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    return render.toString();
  });

export const base64ToFile = (dataurl: string, filename: string) => {
  let arr = dataurl.split(','),
    //@ts-ignore
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
