import { useState, useEffect } from 'react';
import baseUrl from '~/services/api/baseUrl';
import { AxiosResponse } from 'axios';
import { Contract } from '../types/contratResponse';

const useFetchContract = (contractHashId: string) => {
  const [contract, setContract] = useState<Contract | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchContract = async () => {
    try {
      setLoading(true);
      const {
        data: { data }
      }: { data: AxiosResponse<Contract> } = await baseUrl.get(`/v2/Contracts/${contractHashId}`);
      setContract(data);
    } catch (error) {
      console.error('Failed to fetch contract:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContract();
  }, [contractHashId]);

  return { contract, loading };
};

export default useFetchContract;
