import { Message } from '../types';
import { Box, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { containerMessage, container, messageAfter } from './styles';
import useStore from '~/store';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  questionComponent?: Message['questionComponent'];
  Component?: Message['Component'];
}

const STORE = [
  {
    value: 2,
    label: 'Profissional (local especializado em guarda de bicicletas)'
  },
  {
    value: 3,
    label: 'Usual (Hotel, Pousada, Hostel, Casa e etc)'
  }
];

export const PlaceStore = ({ content }: Props) => {
  const { setPlaceStore, quote } = useStore((store) => ({
    quote: store.quote,
    setPlaceStore: store.setPlaceStore
  }));

  const { placeStore } = quote;

  const handleStoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlaceStore(Number(event.target.value));
  };

  return (
    <Box sx={container}>
      <Box sx={() => containerMessage()}>
        <FormControl>
          <RadioGroup aria-labelledby="radio-button-place-store" value={placeStore} onChange={handleStoreChange}>
            {STORE.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={
                  <Radio inputProps={{ 'aria-label': label }} checkedIcon={<CheckCircleIcon color="success" />} />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Box sx={messageAfter} />
      </Box>
    </Box>
  );
};
