import { IMessage } from './handleSteps';
import ModalHelpValueAccessories from '../../ModalHelpValueAccessories';
import { Typography } from '@mui/material';
import searchNameEquipment from '~/utils/searchNameEquipment';

export const nameMessages = ({ MESSAGES, setMessages, setDisableStepHandler }: IMessage) => {
  setDisableStepHandler(true);
  setTimeout(() => {
    setMessages([MESSAGES[0]]);
  }, 1000);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[1]]);
  }, 3000);

  setTimeout(() => {
    setMessages([MESSAGES[2]]);
  }, 7500);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[3]]);
    setDisableStepHandler(false);
  }, 8000);
};

export const emailMessages = (
  { MESSAGES, setMessages, setDisableStepHandler }: IMessage,
  removeMessage: (index: number) => void,
  name: string
) => {
  setDisableStepHandler(true);

  removeMessage(0);
  const message = {
    ...MESSAGES[4],
    content: MESSAGES[4].content?.replace('{1}', name)
  };
  setTimeout(() => {
    setMessages(() => [message]);
  }, 500);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[5]]);
    setDisableStepHandler(false);
  }, 1000);
};

export const cpfMessages = (
  { MESSAGES, setMessages, setDisableStepHandler }: IMessage,
  removeMessage: (index: number) => void
) => {
  setDisableStepHandler(true);

  removeMessage(0);
  removeMessage(1);

  setTimeout(() => {
    setMessages(() => [MESSAGES[6]]);
  }, 500);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[7]]);
    setDisableStepHandler(false);
  }, 1000);
};

export const genderMessages = (
  { MESSAGES, setMessages, setDisableStepHandler }: IMessage,
  removeMessage: (index: number) => void
) => {
  setDisableStepHandler(true);
  removeMessage(0);
  removeMessage(1);

  setTimeout(() => {
    setMessages(() => [MESSAGES[8]]);
  }, 500);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[9]]);
    setDisableStepHandler(false);
  }, 1000);
};

export const equipmentMessages = (
  { MESSAGES, setMessages, setDisableStepHandler }: IMessage,
  removeMessage: (index: number) => void
) => {
  setDisableStepHandler(true);

  removeMessage(0);
  removeMessage(1);

  setTimeout(() => {
    setMessages(() => [MESSAGES[10]]);
  }, 500);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[11]]);
  }, 1000);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[12]]);
    setDisableStepHandler(false);
  }, 1500);
};

export const nextEquipmentValueMessages = (
  { MESSAGES, setMessages, setDisableStepHandler }: IMessage,
  equipment: string
) => {
  setDisableStepHandler(true);
  setTimeout(() => {
    setMessages([
      {
        id: 10,
        Component: () => (
          <>
            <Typography>
              {`Me fale um pouco sobre o(a) seu(sua): `}
              <span style={{ fontWeight: 'bold' }}>{searchNameEquipment(equipment)}</span>
            </Typography>
          </>
        ),
        type: 'equipmentView',
        equipment: searchNameEquipment(equipment),
        author: 'us'
      }
    ]);
  }, 200);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[16]]);
  }, 1000);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[17]]);
    setDisableStepHandler(false);
  }, 1500);
};

export const nextAcessoriesValueMessages = (
  { MESSAGES, setMessages, setDisableStepHandler }: IMessage,
  equipment: string
) => {
  setDisableStepHandler(true);

  setTimeout(() => {
    setMessages([
      {
        id: 10,
        content: (
          <div>
            A sua <span style={{ fontWeight: 'bold' }}>{searchNameEquipment(equipment)}</span> possui algum acessório
            que você queira incluir no seguro?
          </div>
        ),
        equipment: searchNameEquipment(equipment),
        author: 'us',
        QuestionComponent: () => <ModalHelpValueAccessories />
      }
    ]);
  }, 500);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[21]]);
    setDisableStepHandler(false);
  }, 1000);
};

export const nextEquipmentMaterialMessages = ({ MESSAGES, setMessages, setDisableStepHandler }: IMessage) => {
  setDisableStepHandler(true);

  setTimeout(() => {
    setMessages([MESSAGES[22]]);
  }, 500);

  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[23]]);
    setDisableStepHandler(false);
  }, 1000);

  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[30]]);
  }, 1500);

  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[31]]);
    setDisableStepHandler(false);
  }, 2000);
};

export const nextCompetionsMessages = ({ MESSAGES, setMessages, setDisableStepHandler }: IMessage, name: string) => {
  setDisableStepHandler(true);

  const message = {
    ...MESSAGES[24],
    content: MESSAGES[24].content?.replace('{name}', name)
  };
  setTimeout(() => {
    setMessages(() => [message]);
  }, 500);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[25]]);
    setDisableStepHandler(false);
  }, 1000);
};

export const nextInternationalCoverageMessages = (
  { MESSAGES, setMessages, setDisableStepHandler }: IMessage,
  name: string
) => {
  setDisableStepHandler(true);

  const message = {
    ...MESSAGES[26],
    content: MESSAGES[26].content?.replace('{name}', name)
  };
  setTimeout(() => {
    setMessages(() => [message]);
  }, 500);
  setTimeout(() => {
    setMessages((messages: any) => [...messages, MESSAGES[27]]);
    setDisableStepHandler(false);
  }, 1000);
};
