import Colors from '~/styles/colors';
export const container = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: 8
};
export const title = {
  color: Colors.primary?.main || '',
  textAlign: 'center'
};
export const description = {
  marginTop: 4,
  textAlign: 'center'
};
export const buttonDownload = {
  marginTop: 4,
  marginX: 'auto',
  width: 'fit-content'
};
export const imgContainer = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 2
};
export const imgStyle = {
  width: 120
};
