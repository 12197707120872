import { Message } from '../types';
import { Box, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { containerMessage, container, messageAfter } from './styles';
import useStore from '~/store';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  questionComponent?: Message['questionComponent'];
  Component?: Message['Component'];
}

const CONDITIONS = [
  {
    value: 1,
    label: 'Original'
  },
  {
    value: 2,
    label: 'Personalizado'
  }
];

export const EquipmentConditions = ({ content }: Props) => {
  const { setEquipmentConditions, quote } = useStore((store) => ({
    quote: store.quote,
    setEquipmentConditions: 1
  }));

  const { equipmentCondition } = quote;

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setEquipmentConditions(Number(event.target.value));
  // };

  //const [error, setError] = useState(false);


  return (
    <Box sx={container}>
      {/* <Box sx={() => containerMessage()}>
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-button-equipment-conditions"
            value={equipmentCondition}
            onChange={handleChange}
          >
            {CONDITIONS.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                label={label}
                control={
                  <Radio inputProps={{ 'aria-label': label }} checkedIcon={<CheckCircleIcon color="success" />} />
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Box sx={messageAfter} />
      </Box> */}
    </Box>
  );
};
