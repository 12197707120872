import { forwardRef, ChangeEvent } from 'react';
import { Message } from '../types';
import { Box, TextField, InputAdornment } from '@mui/material';
import NumberFormat from 'react-number-format';
import { containerMessage, inputStyle, container, messageAfter } from './styles';
import useStore from '~/store';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  questionComponent?: Message['questionComponent'];
  Component?: Message['Component'];
}
interface Event {
  target: {
    name: string;
    value: string;
  };
}
interface CustomProps {
  onChange: (event: Event) => void;
  name: string;
}
interface NumberFormatProps {
  onChange: (event: Event) => void;
  name: string;
}

export const NumberFormatCustom = forwardRef<NumberFormatProps, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      prefix=""
    />
  );
});

export const AccessoriesValue = ({ content, questionComponent }: Props) => {
  const { setAccessoriesValue, quote } = useStore((store) => ({
    quote: store.quote,
    setAccessoriesValue: store.setAccessoriesValue
  }));

  const { accessoriesValue, equipmentValue } = quote;

  const handleChangeAcessoriesValue = (event: ChangeEvent<HTMLInputElement>) => {
    const accessoriesValue = Number(event.target.value);
    setAccessoriesValue(accessoriesValue);
  };

  const hasError = Number(accessoriesValue) > Number(equipmentValue) * 0.1;

  return (
    <Box sx={container}>
      <Box sx={() => containerMessage()}>
        <TextField
          value={accessoriesValue.toString()}
          onChange={handleChangeAcessoriesValue}
          name="accessoriesValue"
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            startAdornment: (
              <>
                <InputAdornment position="start">R$</InputAdornment>
              </>
            ),
            endAdornment: hasError && <ErrorIcon color="error" />
          }}
          error={hasError}
          variant="outlined"
          helperText="*Valor máximo deve ser equivalente a 10% do valor do equipamento."
          sx={inputStyle}
        />
        <Box sx={messageAfter} />
      </Box>
    </Box>
  );
};
