import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ContractService from '~/services/modules/contract';
import useStore from '~/store';
import { parseParamToString } from '~/utils/parseParamToString';
export interface StepsResponse {
  steps: number[];
}

export const useGetStepPlans = () => {
  const [data, setData] = useState<StepsResponse | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { plan_id } = useStore((store) => ({
    plan_id: store.quote.plan_id
  }));

  const fetchData = async () => {
    try {
      const responseData = await ContractService.getStepPlans(searchParams.get('plan') ?? parseParamToString(plan_id));

      setData(responseData.data.steps);
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return { data, loading, error };
};
