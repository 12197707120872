import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import TruckIcon from './Truck';

export function InternalIcon(props: FontAwesomeIconProps) {
  const { icon } = props;

  console.log(props);

  if (icon.toString() === 'in-truck') return <TruckIcon size={24} />;

  return null;
}
