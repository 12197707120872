import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

export const container: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#121212',
  border: '1.2px solid #323232',
  borderRadius: 6,
  padding: 2,
  maxWidth: 600,
  width: '80%',
  height: 'auto',

  '@media (max-width: 768px)': {
    position: 'fixed',
    width: '100%',
    top: 'auto',
    bottom: 0,
    left: 0,
    height: 'auto',
    transform: 'none'
  }
};

export const containerMessageList = {
  width: '100%',
  minHeight: 300,
  maxWidth: 'calc(100vh - 300px)',
  paddingRight: '.5rem'
};
