import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import api from '~/services/api';
import { storeState } from '~/store';
import { container, headerContainer } from '../styles';
import { CreateTokenCardResponse } from './interfaces/card-token-response';
import { Contract, Product } from './interfaces/contract';
import { FinancialData } from './interfaces/financial-data';
import { AddressCard, AddressInputs } from './partials/address';
import { ContractCard } from './partials/contract';
import { EquipmentCard } from './partials/equipment';
import { RenewalHeader } from './partials/header';
import { CardData, PaymentCard } from './partials/payment';
import { UpsellCard } from './partials/upsell';
import { cardSchema } from './validations';

const PAGARME_URL = process.env.REACT_APP_PAGARME_API_URL;
const PAGARME_APP_ID = process.env.REACT_APP_PAGARME_APP_ID;

export default function ContractRenewal() {
  const [contractData, setContractData] = useState<Contract | null>(null);
  const [products, setProducts] = useState<number[]>([]);
  const [financialInfos, setFinancialInfos] = useState<FinancialData>();
  const [updateDataLoading, setUpdateDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitFormState, setSubmitFormState] = useState({ loading: false, success: false });

  const { contractId } = useParams();

  const navigate = useNavigate();

  const {
    control: cardControl,
    handleSubmit,
    formState: { errors }
  } = useForm<CardData>({
    resolver: yupResolver(cardSchema)
  });

  const updateAddress = async (data: AddressInputs) => {
    await api.put(`Contracts/${contractId}/insured_items/address`, data);
  };

  const fetchData = () => {
    setLoading(true);

    api
      .get(`Contracts/${contractId}/quotes`)
      .then(({ data: { data } }) => {
        const products = [...data.contract_item, ...data.upsell];

        setContractData(data);
        setProducts(
          products.filter((product: Product) => product.is_checked).map((product: Product) => product.product_id)
        );
        setFinancialInfos({
          financial_charges: data.contract_details.financial_charges,
          subtotal: data.contract_details.subtotal,
          total: data.contract_details.total,
          installments: data.contract_details.installments
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateQuoteData = async ({
    prods = products,
    equipament_price = contractData?.insured_item[0].equipment_value,
    value_of_acessories = contractData?.insured_item[0].value_of_accessories
  }) => {
    const oldProducts = [...products];
    const oldFinancialInfos = financialInfos;

    try {
      setUpdateDataLoading(true);

      const {
        data: { data }
      } = await api.put(`/Contracts/quotes/${contractData?.contract_details.quote_id}`, {
        quote_renew_equipaments: [
          {
            insured_equipament_id: contractData?.insured_item[0].insured_equipament_id,
            value_of_acessories,
            equipament_price
          }
        ],
        products: prods.map((product_id) => ({ product_id }))
      });

      setFinancialInfos({
        financial_charges: data.financial_charges,
        subtotal: data.subtotal,
        total: data.total,
        installments: data.installments
      });
    } catch (e: any) {
      setProducts(oldProducts);
      setFinancialInfos(oldFinancialInfos);
      storeState.addToast({
        type: 'error',
        title: 'Algo deu errado!',
        message: 'Não foi possível atualizar a sua cotação. Tente novamente mais tarde.'
      });
    } finally {
      setUpdateDataLoading(false);
    }
  };

  const handleProductChange = async (productId: number) => {
    const oldProducts = [...products];

    let newProducts;

    if (products.includes(productId)) {
      newProducts = products.filter((product) => product !== productId);
    } else {
      newProducts = [...products, productId];
    }

    try {
      setProducts(newProducts);

      if (!newProducts.length) return;

      const data = await updateQuoteData({ prods: newProducts });
    } catch (e) {
      setProducts(oldProducts);
    }
  };

  const handleSubmitForm = async (cardData: CardData) => {
    setSubmitFormState((prev) => ({ ...prev, loading: true }));

    if (Object.keys(errors).length > 0) {
      const element = document.getElementById('payment-card');

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }

      return;
    }

    const expiryMonth = cardData.card_expiration_date.split('/')[0];
    const expiryYear = cardData.card_expiration_date.split('/')[1];

    const createTokenCardPayload = {
      type: 'card',
      card: {
        number: cardData.card_number.replaceAll(' ', ''),
        exp_month: parseInt(expiryMonth),
        exp_year: parseInt(expiryYear),
        holder_name: cardData.card_holder_name,
        cvv: cardData.card_cvv.trimEnd()
      }
    };

    try {
      const { data: createCardTokenResponse } = await axios.post<CreateTokenCardResponse>(
        `${PAGARME_URL}/tokens?appId=${PAGARME_APP_ID}`,
        {
          ...createTokenCardPayload
        }
      );

      await api.put(`/Contracts/quotes/${contractData?.contract_details.quote_id}/renew`, {
        hash_card: createCardTokenResponse.id
      });

      setSubmitFormState((prev) => ({ ...prev, success: true }));
    } catch (e) {
      storeState.addToast({
        title: 'Erro ao renovar contrato',
        message: 'Não foi possível processar os seus dados no momento. Por favor, tente novamente mais tarde.',
        type: 'error'
      });
    } finally {
      setSubmitFormState((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (submitFormState.success) {
      navigate(`/contract/renewal/success/${contractId}`);
    }
  }, [submitFormState.success]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const element = document.getElementById('payment-card');

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [errors]);

  if (loading)
    return (
      <CircularProgress
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      />
    );

  return (
    <Container maxWidth="lg" sx={container}>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent sx={{ padding: { xs: '0.5rem', sm: '1rem' } }}>
              <Box sx={headerContainer}>
                <Typography variant="h6" align="left">
                  Detalhes do contrato para renovação
                </Typography>
              </Box>
              <Divider variant="middle" color="#a547ff" sx={{ height: '3px' }} />
              <RenewalHeader financialInfos={financialInfos} isLoading={updateDataLoading} />
              <EquipmentCard updateQuoteData={updateQuoteData} insured_item={contractData?.insured_item} />
              <AddressCard address={contractData?.address} updateAddress={updateAddress} />
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <PaymentCard control={cardControl} errors={errors} />
                {contractData?.upsell !== undefined && contractData?.upsell.length > 0 && (
                  <UpsellCard
                    products={products}
                    upsell={contractData?.upsell}
                    handleProductChange={handleProductChange}
                  />
                )}
                <ContractCard
                  financialData={financialInfos}
                  isLoading={updateDataLoading}
                  products={products}
                  handleProductChange={handleProductChange}
                  contract_item={contractData?.contract_item}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    disabled={!products.length || loading || updateDataLoading || submitFormState.loading}
                    type="submit"
                    sx={{ minWidth: '60%' }}
                  >
                    {submitFormState.loading ? <CircularProgress size={24} /> : 'Quero renovar meu contrato'}
                  </LoadingButton>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
