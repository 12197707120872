import { useState, useEffect } from 'react';
import { Message } from '../../../Chat/types';
import { Box, Typography, Grid, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { Bike, EBike, EScooter, Scooter } from '~/assets/icons';
import { containerMessage } from './styles';
import RadioGroupCustom from './RadioComponentCustom';
import { IEQUIPMENT } from './types';
import useStore from '~/store';
import { searchEquipmentNameById } from '~/utils/searchNameEquipment';

interface Props {
  author?: Message['author'];
}

const equipments = [
  {
    label: 'Bicicleta elétrica',
    value: 'ebike',
    description: '*Nova (máx. 30 dias) ou até 10 anos',
    Icon: () => <EBike />
  },
  {
    label: 'Autopropelido',
    value: 'escooter',
    description: '*Novo (máx. 60 dias)',
    Icon: () => <EScooter />
  },
  {
    label: 'Ciclomotor e Moto Elétrica',
    value: 'scooter',
    description: '*Novo(a) (máx. 60 dias)',
    Icon: () => <Scooter />
  }
];

export const TypesOfEquipment = () => {
  const { equipment } = useStore((store) => {
    let equipment = store.quote.equipment;

    if (!equipment || equipment === 'false') {
      equipment = searchEquipmentNameById(store.insured_item.equipment_type_id);
    }

    console.table({ equipment: store.quote.equipment, equipment_type_id: store.insured_item.equipment_type_id });

    return {
      equipment
    };
  });

  const equipmentObj = equipments.find((each) => each.value === equipment) || {
    label: 'Bicicleta',
    value: 'bike',
    description: '*Nova (máx. 30 dias) ou até 10 anos',
    Icon: () => <Bike />
  };

  return (
    <Box sx={() => containerMessage()}>
      <FormControl>
        <RadioGroup aria-labelledby="radio-button-type-equipment" value={equipment} defaultChecked>
          <RadioGroupCustom
            equipment={equipmentObj.value}
            control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
            description={equipmentObj.description}
            Icon={equipmentObj.Icon}
            label={equipmentObj.label}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
