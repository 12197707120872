import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography, IconButton, Grid, Card, CardContent, Divider, Fade, Backdrop } from '@mui/material';
import HelpRounded from '@mui/icons-material/HelpRounded';
import Close from '@mui/icons-material/Close';
import { container, cardContainer, containerTitle, titleStyle, text, subtitleStyle } from './styles';
import useStore from '~/store';
import searchNameEquipment from '~/utils/searchNameEquipment';

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { quote } = useStore((store) => ({
    quote: store.quote
  }));

  const { equipment, name } = quote;

  const equipmentName = searchNameEquipment(equipment);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpRounded color="info" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={container}>
            <Box sx={containerTitle}>
              <Typography variant="h5" color="primary" sx={titleStyle}>
                Ficou com alguma dúvida sobre como saber o valor do seu equipamento?
              </Typography>
              <IconButton color="default" aria-label="upload picture" component="span" onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Typography variant="h6" sx={subtitleStyle}>
              Vamos lá a explicação:
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={6} xs={12}>
                <Card sx={cardContainer}>
                  <CardContent>
                    {/* <Typography variant="h6" sx={subtitleGreenStyle}>
                      Valor do meu equipamento
                    </Typography>
                    <Divider />
                    <Typography variant="body1" sx={text}>
                      <span style={{ fontWeight: 'bold' }}>
                        Patinete e Scooter Elétricos:
                      </span>
                      como só aceitamos equipamentos novos, a sugestão é você
                      utilizar o valor que está na nota fiscal.
                    </Typography> */}
                    <Typography variant="body1" sx={text}>
                      <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{name}: </span>
                      Considere somente o valor da {equipmentName}, pois existem alguns acessórios que não são cobertos.
                      Se for nova (até 30 dias da NF), você pode utilizar o valor da NF (sem considerar os acessórios
                      cobrados que não tenham cobertura), caso a {equipmentName} tenha mais de 30 dias ou o valor da NF
                      esteja defasado em relação ao atual valor de mercado, a sugestão é aplicar o valor de mercado e
                      deixar a que seguradora avalie mais precisamente.
                      {/* somente o valor da bicicleta. Se for nova, utilize o valor 100%, se estiver
                      com mais de 1 ano de uso a sugestão é aplicar o valor
                      de mercado e deixar a seguradora irá avaliar mais precisamente. */}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
