import { SvgIcon } from '@mui/material';

function EScooterIcon() {
  return (
    <SvgIcon
      width="48px"
      height="32px"
      viewBox="0 0 48 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fontSize: '3rem' }}
    >
      <path
        d="M13.9719 35.9958H31.2019V33.5961C31.2019 28.2927 35.4974 23.9972 40.8008 23.9972H42.5766L38.0172 3.74357C37.774 2.6831 37.1793 1.73606 36.3297 1.0564C35.4802 0.376742 34.4257 0.00443352 33.3377 0H24.0028V4.79944H33.3377L36.6973 19.7977H36.6733C34.102 20.5727 31.7983 22.0504 30.0218 24.0644C28.2453 26.0784 27.0665 28.5485 26.6185 31.1964H13.9719C13.3953 29.5612 12.2471 28.1895 10.739 27.334C9.23081 26.4785 7.4643 26.1968 5.76489 26.5409C2.93322 27.0929 0.653481 29.4206 0.125542 32.2523C-0.0714059 33.2934 -0.0362729 34.3651 0.228437 35.3911C0.493146 36.417 0.980915 37.372 1.65698 38.1878C2.33304 39.0037 3.18074 39.6604 4.13968 40.1111C5.09861 40.5618 6.14516 40.7954 7.20472 40.7953C10.3244 40.7953 12.9641 38.7795 13.9719 35.9958ZM7.20472 35.9958C5.88488 35.9958 4.805 34.916 4.805 33.5961C4.805 32.2763 5.88488 31.1964 7.20472 31.1964C8.52457 31.1964 9.60445 32.2763 9.60445 33.5961C9.60445 34.916 8.52457 35.9958 7.20472 35.9958ZM40.8008 26.3969C36.8173 26.3969 33.6017 29.6126 33.6017 33.5961C33.6017 37.5796 36.8173 40.7953 40.8008 40.7953C44.7844 40.7953 48 37.5796 48 33.5961C48 29.6126 44.7844 26.3969 40.8008 26.3969ZM40.8008 35.9958C39.481 35.9958 38.4011 34.916 38.4011 33.5961C38.4011 32.2763 39.481 31.1964 40.8008 31.1964C42.1207 31.1964 43.2006 32.2763 43.2006 33.5961C43.2006 34.916 42.1207 35.9958 40.8008 35.9958ZM21.6031 45.5947H12.0042L26.4025 52.7939V47.9944H36.0014L21.6031 40.7953V45.5947Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default EScooterIcon;
