import { SvgIcon } from '@mui/material';

function FileIcon() {
  return (
    <SvgIcon style={{ width: 50, height: 50 }} viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9266 20.8641L15.2797 25.5141L13.3531 23.0578C13.318 23.013 13.2732 22.9767 13.2221 22.9518C13.1709 22.9269 13.1147 22.9139 13.0578 22.9139C13.0009 22.9139 12.9447 22.9269 12.8936 22.9518C12.8424 22.9767 12.7976 23.013 12.7625 23.0578L8.08437 29.0203C8.04109 29.0756 8.01424 29.1419 8.00688 29.2117C7.99952 29.2815 8.01196 29.352 8.04276 29.4151C8.07357 29.4782 8.1215 29.5313 8.18108 29.5684C8.24066 29.6055 8.30949 29.6252 8.37969 29.625H25.625C25.9391 29.625 26.1125 29.2641 25.9203 29.0203L19.5219 20.8641C19.4863 20.8192 19.4411 20.7829 19.3895 20.758C19.338 20.733 19.2815 20.7201 19.2242 20.7201C19.167 20.7201 19.1104 20.733 19.0589 20.758C19.0073 20.7829 18.9621 20.8192 18.9266 20.8641V20.8641ZM9.875 17.7188C9.875 18.216 10.0725 18.6929 10.4242 19.0446C10.7758 19.3962 11.2527 19.5938 11.75 19.5938C12.2473 19.5938 12.7242 19.3962 13.0758 19.0446C13.4275 18.6929 13.625 18.216 13.625 17.7188C13.625 17.2215 13.4275 16.7446 13.0758 16.3929C12.7242 16.0413 12.2473 15.8438 11.75 15.8438C11.2527 15.8438 10.7758 16.0413 10.4242 16.3929C10.0725 16.7446 9.875 17.2215 9.875 17.7188ZM33.0594 10.5281L22.9719 0.440625C22.6906 0.159375 22.3109 0 21.9125 0H2C1.17031 0 0.5 0.670312 0.5 1.5V40.5C0.5 41.3297 1.17031 42 2 42H32C32.8297 42 33.5 41.3297 33.5 40.5V11.5922C33.5 11.1937 33.3406 10.8094 33.0594 10.5281ZM30.0406 12.2812H21.2188V3.45938L30.0406 12.2812ZM30.125 38.625H3.875V3.375H18.0312V13.5C18.0312 14.0221 18.2387 14.5229 18.6079 14.8921C18.9771 15.2613 19.4779 15.4688 20 15.4688H30.125V38.625Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default FileIcon;
