import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography, IconButton } from '@mui/material';
import SERINUMBERIMG from '~/assets/images/serieNumber.png';
import HelpRounded from '@mui/icons-material/HelpRounded';
import Close from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { container, containerTitle, titleStyle, descriontionStyle, containerCards } from './styles';

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpRounded color="info" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box>
            <Box sx={container}>
              <Box sx={containerTitle}>
                <Typography variant="h5" color="primary" sx={titleStyle}>
                  Na bicicleta
                </Typography>
                <IconButton color="default" aria-label="upload picture" component="span" onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
              <Typography variant="body1" sx={descriontionStyle}>
                O número de série pode ser encontrado na sua bike em um dos seguintes locais:
              </Typography>
              <Box sx={containerCards}>
                <img src={SERINUMBERIMG} alt="imagem modal de ajuda" />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
