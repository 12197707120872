import { Container, Box, Typography, Button } from '@mui/material';
import { container, center, title, description, textDesc } from './styles';
import { AriOkIcon } from '~/assets/icons';
import { useNavigate } from 'react-router-dom';
import useStore from '~/store';

export default function HelpZipcodePage() {
  const navigate = useNavigate();
  const { contractResponse } = useStore((store) => ({
    contractResponse: store.contractResponse
  }));
  return (
    <Container maxWidth="sm" sx={container}>
      <Box sx={[center]}>
        <AriOkIcon />
        <Typography variant="h5" sx={center} fontWeight="bold">
          Pagamento aprovado!
        </Typography>
      </Box>
      <Box>
        <Typography color="primary" variant="h4" sx={title}>
          Falta pouco!
        </Typography>
      </Box>
      <Box>
        <Typography sx={description} textAlign="center">
          Para o seu seguro começar a valer, precisamos de mais alguns dados!
        </Typography>
      </Box>
      <Box sx={[center]} onClick={() => navigate(`/contract/?cid=${contractResponse.contract_id}`)}>
        <Button variant="contained">Preencher dados agora</Button>
        <Box>
          <Typography color="primary" fontWeight="bold" sx={[center, textDesc]}>
            Caso não consiga preencher agora, enviaremos um e-mail com instruções para finalizar assim que possível.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
