import { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

const regexCPF: Array<string | RegExp> = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];

export const CpfMask = forwardRef((props, ref) => {
  return (
    <MaskedInput
      {...props}
      mask={regexCPF}
      placeholderChar={'\u2000'} // showMask
    />
  );
});
