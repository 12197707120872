import { GetState, SetState } from 'zustand';
import * as uuid from 'uuid';
import { ToastProps, ToastStore } from './types';

const createToastSlice = (set: SetState<ToastStore>, get: GetState<ToastStore>) => ({
  toasts: [],
  addToast: (toast: Omit<ToastProps, 'id'>) => {
    set({
      toasts: [
        {
          ...toast,
          id: uuid.v4()
        }
      ]
    });
  },
  removeToast: (toastId: string) => {
    const oldToasts = get().toasts.filter((toast) => toast.id !== toastId);
    set({ toasts: oldToasts });
  }
});

export default createToastSlice;
