import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography, IconButton, Grid, Card, CardContent } from '@mui/material';
import { Container } from '@mui/material';
import HelpRounded from '@mui/icons-material/HelpRounded';
import Close from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import useStore from '~/store';
import searchNameEquipment from '~/utils/searchNameEquipment';
import { useNavigate } from 'react-router-dom';
import { BikeGreen, EScooterGreen, ScooterGreen, EBikeGreen } from '~/assets/icons';
import {
  container,
  cardContainer,
  containerMessageList,
  containerTitle,
  titleStyle,
  subtitleStyle,
  descriontionStyle,
  containerIcon,
  containerIcons,
  subtitleGreenStyle,
  containerCards,
  paragraphStyle,
  StyledLink
} from './styles';
import { Link } from 'react-router-dom';

const CONTRAN_URL = 'https://www.in.gov.br/en/web/dou/-/resolucao-contran-n-996-de-15-de-junho-de-2023-491553860';

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpRounded color="info" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={container}>
            <Box sx={containerTitle}>
              <Typography variant="h5" color="primary" sx={titleStyle}>
                Ficou com alguma dúvida sobre qual é o seu equipamento?
              </Typography>
              <IconButton color="default" aria-label="upload picture" component="span" onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Typography variant="h6" sx={descriontionStyle}>
              É Só ler as especificações abaixo:
            </Typography>
            <Box sx={containerCards}>
              <Grid container spacing={2} sx={containerCards}>
                <Grid item xs={12}>
                  <Card sx={cardContainer}>
                    <CardContent>
                      <Box sx={containerIcons}>
                        <Box sx={containerIcon}>
                          <BikeGreen />
                        </Box>
                        <Box sx={containerIcon}>
                          <EBikeGreen />
                        </Box>
                        <Box sx={containerIcon}>
                          <EScooterGreen />
                        </Box>
                        <Box sx={containerIcon}>
                          <ScooterGreen />
                        </Box>
                      </Box>
                      <Typography variant="body1" sx={subtitleGreenStyle}>
                        Bicicleta x Bicicleta Elétrica x Autopropelido x Ciclomotor e Moto Elétrica
                      </Typography>
                      <Typography variant="body1" sx={paragraphStyle}>
                        <Typography fontWeight="bold" variant="body1">
                          Considera-se Bicicleta:
                        </Typography>
                        Veículo de propulsão humana, dotado de duas rodas, não sendo, para efeito do Código de Trânsito Brasileiro (CTB), similar à motocicleta, motoneta e ciclomotor; II - equipamento de mobilidade individual.
                      </Typography>
                      <Typography variant="body1" sx={paragraphStyle}>
                        <Typography fontWeight="bold" variant="body1">
                          Considera-se Bicicleta elétrica:
                        </Typography>
                        veículo de propulsão humana, com duas rodas, com as seguintes características: a) provido de motor auxiliar de propulsão, com potência nominal máxima de até 1.000W (mil watts); b) provido de sistema que garanta o funcionamento do motor somente quando o condutor pedalar (pedal assistido); c) não dispor de acelerador ou de qualquer outro dispositivo de variação manual de potência; velocidade máxima de propulsão do motor auxiliar não superior a 32 km/h (trinta e dois quilômetros por hora).
                      </Typography>
                      <Typography variant="body1" sx={paragraphStyle}>
                        <Typography fontWeight="bold" variant="body1">
                          Considera-se Bicicleta motorizada:
                        </Typography>
                        Utiliza motor com combustível, ou melhor, uma mistura de gasolina e óleo.
                      </Typography>
                      <Typography variant="body1" sx={paragraphStyle}>
                        <Typography fontWeight="bold" variant="body1">
                          Considera-se Autopropelido:
                        </Typography>
                        Equipamento com as seguintes características: a) dotado de uma ou mais rodas; b) dotado ou não de sistema de auto equilíbrio que estabiliza dinamicamente o equipamento inerentemente instável por meio de sistema de controle auxiliar composto por giroscópio e acelerômetro; c) provido de motor de propulsão com potência nominal máxima de até 1.000W (mil watts); d) velocidade máxima de fabricação não superior a 32 km/h (trinta e dois quilômetros por hora); e) largura não superior a 70 cm (setenta centímetros) e distância entre eixos de até 130 cm (cento e trinta centímetros).
                      </Typography>
                      <Typography variant="body1" sx={paragraphStyle}>
                        <Typography fontWeight="bold" variant="body1">
                          Considera-se Ciclomotor:
                        </Typography>
                        Veículo de 2 (duas) ou 3 (três) rodas, provido de motor de combustão interna cuja cilindrada não exceda a 50 cm³  (cinquenta centímetros cúbicos), equivalente a 3,05 pol³  (três polegadas cúbicas e cinco centésimos), ou de motor de propulsão elétrica com potência máxima de 4 kW (quatro quilowatts), e cuja velocidade máxima de fabricação não exceda a 50 km/h (cinquenta quilômetros por hora).
                      </Typography>
                      <Typography variant="body1" sx={paragraphStyle}>
                        <Typography fontWeight="bold" variant="body1">
                          Considera-se Motoneta Elétrica:
                        </Typography>
                        Automotor de duas rodas, dirigido por condutor em posição sentada.
                      </Typography>
                      <Typography variant="body1" sx={paragraphStyle}>
                        <Typography fontWeight="bold" variant="body1">
                          Considera-se Motocicleta Elétrica:
                        </Typography>
                        Veículo automotor de duas rodas, com ou sem side-car, dirigido por condutor em posição montada.
                      </Typography>
                      <Typography variant="body1" color="error" fontWeight="bold" sx={paragraphStyle}>
                        Atenção: Não fazemos seguros para bicicletas motorizadas!
                      </Typography>
                      <StyledLink to={CONTRAN_URL} target="_blank">
                        Clique aqui p/ saber como o CONTRAN classifica.
                      </StyledLink>
                      <Typography variant="body2" sx={{ marginTop: 0.5 }}>
                        Caso você ainda permaneça com dúvida, entre em contato com o nosso time de atendimento que
                        iremos te ajudar
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* <Grid item md={4} xs={12}>
                  <Card sx={cardContainer}>
                    <CardContent>
                      <Box sx={containerIcons}>
                        <Box sx={containerIcon}>
                          <ScooterGreen />
                        </Box>
                      </Box>
                      <Typography variant="body1" sx={subtitleGreenStyle}>
                        Patinete Elétrico
                      </Typography>
                      <Typography variant="body1">
                        <Typography fontWeight="bold" variant="body1">
                          Patinete elétrico:
                        </Typography>
                        também contam com uma bateria, que deve ser carregada na
                        tomada antes do uso. Alguns modelos contam com bancos,
                        que podem ser removidos, mas na maioria dos modelos você
                        fica em pé durante o deslocamento.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card sx={cardContainer}>
                    <CardContent>
                      <Box sx={containerIcons}>
                        <Box sx={containerIcon}>
                          <EScooterGreen />
                        </Box>
                      </Box>
                      <Typography variant="body1" sx={subtitleGreenStyle}>
                        Scooter Elétrica
                      </Typography>
                      <Typography variant="body1">
                        são veículos de duas ou três rodas, movidas à energia
                        elétrica por meio de uma bateria recarregável. Estes
                        veículos não utilizam nenhum tipo de combustível fóssil
                        (gasolina, etanol ou diesel) no motor, ou seja, são
                        veículos não-poluentes.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="error"
                        fontWeight="bold"
                      >
                        Atenção: Não trabalhamos scooters motorizadas!
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
